/* eslint-disable react/prop-types */
import React, {
  useState,
  useMemo,
  useCallback,
} from 'react';

import {
  Waiting,
  DatePickerInput,
  Table,
  Notification,
} from '@xbotvn/react-ui/components';
import {
  Dialog,
  DialogContent,
  DialogTitle,
  DialogActions,
  Button,
  TextField,
  Grid,
  Checkbox,
  FormControlLabel,
  Icon,
  Typography,
  Chip,
} from '@xbotvn/react-ui/core';
import {
  cloneDeep,
  set,
  range,
} from '@xbotvn/utils/collection';
import {
  format,
} from '@xbotvn/utils/date';
import { saveAs } from 'file-saver';
import PropTypes from 'prop-types';
import Dropzone from 'react-dropzone';

import { callFileAPI, uploadFiles } from '../../libs/backend';
import { extractFileName } from '../../libs/utils';
import * as Styles from './styles';

function Cancels({
  cert,
  data,
  onSubmit,
  onClose,
}) {
  const [dataInitial, setDataInitial] = useState(data);
  const [certInitial, setCertInitial] = useState({
    ...cert,
    serialTo: data.to,
    date: new Date().getTime(),
  });
  const [cancelAll, setCancelAll] = useState(false);
  const [waiting, setWaiting] = useState(false);
  const [selectedFile, setSelectedFile] = useState();

  const download = useCallback(({ file, path }) => {
    const { name } = extractFileName(file);
    callFileAPI('certificate/download', {
      file: name,
      path,
    }, true).then((result) => {
      setWaiting(false);
      saveAs(new Blob([result]), file);
    }).catch(({ message }) => {
      setWaiting(false);
      Notification.warn(`Tải file không thành công: ${message}`);
    });
  }, []);

  const columns = useMemo(() => [
    {
      Header: 'STT',
      accessor: 'stt',
      disableFilters: true,
      width: 70,
    },
    {
      Header: 'Số hiệu phôi bằng',
      accessor: 'cert',
      width: 190,
    },
    {
      Header: 'Lý do huỷ',
      accessor: 'note',
      width: 170,
    },
    {
      Header: 'Thời gian huỷ',
      accessor: 'date',
      type: 'date',
      width: 150,
    },
    {
      Header: 'Biên bản huỷ',
      accessor: 'file',
      disableFilters: true,
      width: 170,
      Cell: ({ cell: { value }, row: { original } }) => {
        const { information } = original;
        return (value ? (
          <Chip
            label={value}
            onClick={() => {
              download({
                file: value,
                path: `certificates/${information.unit}/${information.year}/${information.id}`,
              });
            }}
          />
        ) : null);
      },
    },
    {
      Header: 'Khôi phục',
      accessor: 'restore',
      disableFilters: true,
      Cell: ({ cell: { value }, row: { original } }) => (
        <>
          <Styles.StyleButton
            variant="text"
            startIcon={<Icon>restore</Icon>}
            onClick={() => {
              const newData = cloneDeep(dataInitial);
              const filtered = (newData?.cancels ?? []).filter(({ serial }) => serial !== value);
              set(newData, 'cancels', filtered);
              setDataInitial(newData);
              onSubmit(newData, `Khôi phục phôi bằng ${original.cert} thành công.`);
            }}
          >
            Khôi phục
          </Styles.StyleButton>
        </>
      ),
      width: 140,
    },
  ], [dataInitial]);

  const rows = useMemo(() => {
    const {
      cancels,
      suffix,
      prefix,
      year,
      unit,
      id,
    } = dataInitial;
    let count = 1;
    return (cancels || []).map((elm) => {
      const {
        serial,
        date,
        note,
        file,
      } = elm;
      const row = {
        stt: count,
        restore: serial,
        cert: `${prefix || ''}${serial}${suffix || ''}`,
        date,
        note,
        file,
        information: {
          year,
          unit,
          id,
        },
      };
      count += 1;
      return row;
    });
  }, [dataInitial]);

  return (
    <>
      {waiting ? <Waiting fullscreen /> : null}
      <Dialog
        open
        fullWidth
        maxWidth="md"
        onClose={() => onClose()}
      >
        <DialogTitle
          onClose={() => onClose()}
          title={`Phôi huỷ - ${data.unitName} - Năm ${data.year}`}
        />
        <DialogContent dividers>
          <Styles.GroupSearch>
            <Grid>
              <FormControlLabel
                label="Huỷ dãy phôi bằng"
                control={(
                  <Checkbox
                    checked={cancelAll}
                    onChange={() => setCancelAll((prev) => !prev)}
                  />
                )}
              />
            </Grid>
            <Grid container spacing={2}>
              <Grid item xs={6}>
                <TextField
                  disabled
                  fullWidth
                  label="Tiền tố:"
                  value={data?.prefix ?? ''}
                />
              </Grid>
              <Grid item xs={6}>
                <TextField
                  fullWidth
                  disabled
                  label="Hậu tố:"
                  value={data?.suffix ?? ''}
                />
              </Grid>
            </Grid>
            <Grid container spacing={2}>
              <Grid item xs={6}>
                <TextField
                  required
                  fullWidth
                  label="Số phôi bằng:"
                  type="number"
                  value={certInitial?.serial}
                  onChange={(e) => setCertInitial((prev) => ({
                    ...prev,
                    serial: e.target.value,
                  }))}
                  inputProps={{
                    allowNegative: false,
                    decimalSeparator: false,
                  }}
                />
              </Grid>
              <Grid item xs={6}>
                <TextField
                  fullWidth
                  required
                  label="Số phôi bằng cuối:"
                  type="number"
                  disabled={!cancelAll}
                  value={certInitial?.serialTo}
                  onChange={(e) => setCertInitial((prev) => ({
                    ...prev,
                    serialTo: e.target.value,
                  }))}
                  inputProps={{
                    allowNegative: false,
                    decimalSeparator: false,
                  }}
                />
              </Grid>
            </Grid>
            <Grid container spacing={2}>
              <Grid item xs={6}>
                <TextField
                  disabled
                  fullWidth
                  label="Bậc học:"
                  value={data?.schoolLevel ?? ''}
                />
              </Grid>
              <Grid item xs={6}>
                <TextField
                  disabled
                  fullWidth
                  label="Đơn vị:"
                  value={data?.unitName ?? ''}
                />
              </Grid>
            </Grid>
            <Grid container spacing={4}>
              <Styles.GroupDatePicker item zIdx={7} xs={6}>
                <DatePickerInput
                  value={format(certInitial?.date)}
                  onDayChange={(value) => {
                    if (value) {
                      setCertInitial((prev) => ({
                        ...prev,
                        date: value.getTime(),
                      }));
                    }
                  }}
                  inputProps={{
                    required: true,
                    fullWidth: true,
                    label: 'Thời gian huỷ:',
                  }}
                />
              </Styles.GroupDatePicker>
              <Grid item xs={6}>
                <Typography variant="subtitle2">Biên bản:</Typography>
                {selectedFile ? (
                  <Chip
                    style={{ marginBottom: 10 }}
                    label={certInitial?.file ?? ''}
                    onDelete={() => {
                      setSelectedFile();
                      setCertInitial((prev) => ({
                        ...prev,
                        file: '',
                      }));
                    }}
                  />
                ) : null}
                <Dropzone
                  onDrop={(files) => {
                    if (files.length === 0) return;
                    const file = files[0];
                    setSelectedFile(files);
                    setCertInitial((prev) => ({
                      ...prev,
                      file: (file?.name ?? '').replace(/\s/g, ''),
                    }));
                  }}
                >
                  {({ getRootProps, getInputProps }) => (
                    <div {...getRootProps()}>
                      <input {...getInputProps()} />
                      <Button
                        color="secondary"
                        startIcon={<Icon>cloud_upload</Icon>}
                        onClick={() => { }}
                      >
                        Tải File
                      </Button>
                    </div>
                  )}
                </Dropzone>
              </Grid>
            </Grid>
            <Grid container spacing={2}>
              <Grid item xs={6}>
                <TextField
                  fullWidth
                  label="Lý do huỷ phôi:"
                  value={certInitial?.note ?? ''}
                  onChange={(e) => setCertInitial((prev) => ({
                    ...prev,
                    note: e.target.value,
                  }))}
                />
              </Grid>
              <Grid item xs={6}>
                <Grid
                  container
                  justifyContent="flex-end"
                  alignItems="center"
                >
                  <Grid item xs={3}>
                    <Button
                      style={{ marginTop: 30 }}
                      startIcon={<Icon>delete</Icon>}
                      color="primary"
                      onClick={() => {
                        const newCancels = dataInitial?.cancels ?? [];
                        const {
                          serial,
                          serialTo,
                          date,
                          note,
                          file,
                        } = certInitial;
                        if (!serial) {
                          Notification.warn('Bắt đầu phải lớn hơn 0.');
                          return;
                        }
                        if (cancelAll) {
                          if (!serialTo || serialTo < serial) {
                            Notification.warn('Kết thúc phải lớn hơn hoặc bằng bắt đầu.');
                            return;
                          }
                          if (serialTo > data.to) {
                            Notification.warn('Dãy phôi bằng không tồn tại.');
                            return;
                          }
                        }
                        if ((data.to < serial || serial < data.from)) {
                          Notification.warn(`Số phôi bằng phải thuộc dãy từ ${dataInitial.from} - ${dataInitial.to}`);
                          return;
                        }
                        if (newCancels.find(({ serial: cSerial }) => cSerial === serial)) {
                          Notification.warn('Số phôi này đã huỷ.');
                          return;
                        }
                        if (!date) {
                          Notification.warn('Chưa chọn thời gian huỷ phôi bằng.');
                          return;
                        }
                        if (cancelAll) {
                          range(
                            serial || data.from,
                            (serialTo || data.to) + 1,
                          ).forEach((idx) => {
                            if (!newCancels.find(({ serial: cSerial }) => cSerial === idx)) {
                              newCancels.push({
                                date,
                                serial: idx,
                                note: note || '',
                                file: file || '',
                              });
                            }
                          });
                        } else if (serial) {
                          newCancels.push({
                            date,
                            serial,
                            note: note || '',
                            file: file || '',
                          });
                        }
                        if (selectedFile) {
                          uploadFiles(
                            'certificate/upload',
                            {
                              unitId: data.unit,
                              year: data.year,
                              certsId: data.id,
                              file: extractFileName(file)?.name ?? '',
                            },
                            selectedFile,
                          );
                        }
                        set(dataInitial, 'cancels', newCancels);
                        setDataInitial({ ...dataInitial });
                        onSubmit(dataInitial, 'Huỷ phôi thành công.');
                      }}
                    >
                      Huỷ
                    </Button>
                  </Grid>
                </Grid>
              </Grid>
            </Grid>
          </Styles.GroupSearch>
          <Grid
            container
            style={{ marginTop: 10 }}
            spacing={2}
            justifyContent="flex-end"
            alignItems="center"
          >
            <Grid item xs={2}>
              <TextField
                fullWidth
                disabled
                label="Tổng số phôi:"
                type="number"
                value={dataInitial?.total}
              />
            </Grid>
            <Grid item xs={2}>
              <TextField
                fullWidth
                disabled
                label="Tổng số phôi huỷ:"
                type="number"
                value={(dataInitial?.cancels ?? []).length}
              />
            </Grid>
          </Grid>
          <Grid>
            <Table
              disableGlobalFilter
              usePagination
              getRowId={(row) => row.cert}
              columns={columns}
              data={rows}
            />
          </Grid>
        </DialogContent>
        <DialogActions>
          <Button
            color="secondary"
            onClick={onClose}
          >
            Đóng
          </Button>
        </DialogActions>
      </Dialog>
    </>
  );
}

Cancels.propTypes = {
  data: PropTypes.object.isRequired,
  onClose: PropTypes.func.isRequired,
  onSubmit: PropTypes.func.isRequired,
  cert: PropTypes.object,
};

Cancels.defaultProps = {
  cert: {},
};

export default Cancels;
