import { Box, Toolbar, Button } from '@xbotvn/react-ui/core';
import { colors } from '@xbotvn/react-ui/styles';
import styled from 'styled-components';

export const GroupedItems = styled(Box).attrs({
  display: 'flex',
  alignItems: 'center',
})``;

export const StyledToolbar = styled(Toolbar)`
  display: flex;
  align-items: center;
  && {
    min-height: 40px;
  }
`;

export const MenuItem = styled(Button).attrs({
  variant: 'text',
})`
  color: ${colors.blue[500]} !important;
  && {
    margin-right: 10px;
  }
`;

export const Logo = styled.div`
  padding-right: 10px;
  font-weight: bold;
  color: dimgrey;
  font-size: 20;
`;
