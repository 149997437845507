import React, { useState, useMemo } from 'react';

import { Panel, Waiting } from '@xbotvn/react-ui/components';
import {
  TextField,
  MenuItem,
  Button,
  Box,
  Grid,
  Icon,
} from '@xbotvn/react-ui/core';
import { intersection } from '@xbotvn/utils/collection';
import PropTypes from 'prop-types';
import { useSelector, useDispatch } from 'react-redux';
import { useHistory } from 'react-router-dom';

import { handleUserUpdate, handleUserSignOut, handleUpdate } from '../../../redux/actions/user';
import * as Styles from './styles';

function ProfileForm({ onClose }) {
  const history = useHistory();
  const dispatch = useDispatch();
  const {
    email,
    displayName: name,
    phoneNumber: phone,
    male,
    company: activeCompany,
    handling,
  } = useSelector(({ user }) => user);
  const [displayName, setDisplayName] = useState(name || '');
  const [phoneNumber, setPhoneNumber] = useState(phone || '');
  const [gender, setGender] = useState(male ? 'M' : 'F');

  const genderOptions = useMemo(
    () => [
      <MenuItem key="M" value="M">
        Nam
      </MenuItem>,
      <MenuItem key="F" value="F">
        Nữ
      </MenuItem>,
    ],
    [],
  );

  return (
    <Panel
      anchor="right"
      open
      onClose={onClose}
      title="Thông tin tài khoản"
      actions={(
        <Box display="flex" justifyContent="flex-end" width={1}>
          <Button
            style={{ marginRight: 10 }}
            color="secondary"
            onClick={() => {
              dispatch(handleUserSignOut());
              history.push('/');
              onClose();
            }}
          >
            Đăng Xuất
          </Button>
          <Button
            color="primary"
            onClick={() => {
              dispatch(
                handleUserUpdate(
                  {
                    displayName,
                    phoneNumber,
                    male: gender === 'M',
                  },
                  (err) => {
                    if (!err) onClose();
                  },
                ),
              );
            }}
            startIcon={<Icon>save</Icon>}
          >
            Cập nhật
          </Button>
        </Box>
      )}
    >
      {handling ? <Waiting fullscreen /> : null}
      <Styles.CustomInput>
        <Box width={500} m={2}>
          <Grid container spacing={2}>
            <Grid item xs={12}>
              <TextField
                label="Email"
                fullWidth
                defaultValue={email}
                disabled
                variant="outlined"
              />
            </Grid>
            <Grid item xs={12}>
              <TextField
                label="Họ và tên"
                required
                fullWidth
                value={displayName}
                onChange={(e) => setDisplayName(e.target.value)}
                variant="outlined"
              />
            </Grid>
            <Grid item xs={12}>
              <Grid container spacing={2}>
                <Grid item xs={7}>
                  <TextField
                    label="Số điện thoại"
                    fullWidth
                    value={phoneNumber}
                    onChange={(e) => setPhoneNumber(e.target.value)}
                    variant="outlined"
                  />
                </Grid>
                <Grid item xs={5}>
                  <TextField
                    label="Giới tính"
                    fullWidth
                    value={gender}
                    onChange={(e) => setGender(e.target.value)}
                    select
                    SelectProps={{}}
                    variant="outlined"
                  >
                    {genderOptions}
                  </TextField>
                </Grid>
              </Grid>
            </Grid>
            {intersection(window.location.hostname.split('.'), ['localhost', 'qa', 'demo'])
              ? (
                <Grid item xs={12}>
                  <Grid item xs={3}>
                    <TextField
                      label="Company"
                      fullWidth
                      value={activeCompany}
                      onChange={(e) => dispatch(handleUpdate({ company: e.target.value }))}
                      select
                      SelectProps={{}}
                      variant="outlined"
                    >
                      {[
                        <MenuItem key="XBOT" value="XBOT">
                          XBOT
                        </MenuItem>,
                        <MenuItem key="ESOFT" value="ESOFT">
                          ESOFT
                        </MenuItem>,
                        <MenuItem key="STEDU" value="STEDU">
                          STEDU
                        </MenuItem>,
                      ]}
                    </TextField>
                  </Grid>
                </Grid>
              ) : null}
          </Grid>
        </Box>
      </Styles.CustomInput>
    </Panel>
  );
}

ProfileForm.propTypes = {
  onClose: PropTypes.func.isRequired,
};

export default ProfileForm;
