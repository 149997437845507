import React, { Component } from 'react';

import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { Route, Redirect, withRouter } from 'react-router-dom';

class AuthorizedRouter extends Component {
  render() {
    const { user, path } = this.props;

    if (user?.account?.xbot?.support
      || (user?.unit?.staffs ?? []).indexOf(user.email) !== -1
      || Object.values(user?.unit?.groups ?? {}).some(({ staffs }) => staffs.includes(user.email))
    ) {
      return (
        <Route
          exact
          path={path}
          render={(props) => (<this.props.component {...props} />)}
        />
      );
    }
    return (
      <Route
        exact
        path={path}
        render={(props) => (<Redirect to={{ pathname: '/', state: { from: props.location } }} />)}
      />
    );
  }
}

AuthorizedRouter.propTypes = {
  handleUserSignOut: PropTypes.func.isRequired,
  user: PropTypes.shape({
    email: PropTypes.string,
    unit: PropTypes.shape({
      id: PropTypes.string.isRequired,
      staffs: PropTypes.arrayOf(PropTypes.string),
    }),
    account: PropTypes.shape({
      xbot: PropTypes.shape({
        support: PropTypes.bool,
      }),
    }),
  }).isRequired,
  path: PropTypes.string.isRequired,
};

const mapStateToProps = ({ user, catalogs }) => ({
  user,
  departments: catalogs?.app?.departments?.data ?? [],
});

export default withRouter(connect(mapStateToProps)(AuthorizedRouter));
