import {
  ListItemText,
  Typography,
} from '@xbotvn/react-ui/core';
import styled from 'styled-components';

export const ItemText = styled(ListItemText)`
  display: flex;
  flex-direction: column;
  align-self: flex-start;
  width: ${({ width }) => `${width}px`};
`;

export const Realloc = styled(Typography)`
  cursor: pointer;
  text-decoration: underline;
`;

export const Title = styled(Typography)`
  font-weight: bold !important;
`;

export const FullName = styled(Title)`
  color: rgb(206, 102, 12);
`;
