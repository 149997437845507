/* eslint-disable react/prop-types */
import React, {
  useMemo,
  useState,
  useCallback,
  useEffect,
} from 'react';

import {
  DatePickerInput,
  AutoComplete,
  Table,
  Waiting,
  Notification,
} from '@xbotvn/react-ui/components';
import {
  Dialog,
  DialogContent,
  DialogTitle,
  DialogActions,
  Button,
  Icon,
  Grid,
  Box,
  TextField,
  Checkbox,
  FormControlLabel,
  Chip,
} from '@xbotvn/react-ui/core';
import {
  range,
  set,
  cloneDeep,
} from '@xbotvn/utils/collection';
import {
  format,
} from '@xbotvn/utils/date';
import { saveAs } from 'file-saver';
import PropTypes from 'prop-types';
import { useSelector } from 'react-redux';

import { graphQLCaller, callFileAPI } from '../../libs/backend';
import { extractFileName } from '../../libs/utils';
import Cancels from './Cancels';
import * as Styles from './styles';

function Search({
  data,
  search,
  advanced,
  onUpdate,
  onClose,
}) {
  const {
    unitActive,
    pgds,
  } = useSelector(({ user }) => ({
    pgds: user?.unit?.pgds ?? [],
    unitActive: user?.unit ?? {},
  }));

  const [filterBy, setFilterBy] = useState('all');
  const [advancedSearch, setAdvancedSearch] = useState(advanced);
  const [searchInitial, setSearchInitial] = useState(search);
  const [dataInitial, setDataInitial] = useState([]);
  const [waiting, setWaiting] = useState(false);
  const [cancelCert, setCancelCert] = useState();

  useEffect(() => {
    setDataInitial(data);
  }, [data]);

  const download = useCallback(({ file, path }) => {
    setWaiting(true);
    const { name } = extractFileName(file);
    callFileAPI('certificate/download', {
      file: name,
      path,
    }, true).then((result) => {
      setWaiting(false);
      saveAs(new Blob([result]), file);
    }).catch(({ message }) => {
      setWaiting(false);
      Notification.warn(`Tải file không thành công: ${message}`);
    });
  }, []);

  const options = useMemo(() => {
    switch (advancedSearch?.schoolLevel) {
      case 'thpt': return [unitActive];
      case 'thcs': return pgds;
      default: return [];
    }
  }, [advancedSearch]);

  const fetchData = async () => {
    setWaiting(true);
    try {
      const { getCertificates } = await graphQLCaller(
        'certificates',
        `{
            getCertificates(
              unitId: "${unitActive.id}",
              year: ${advancedSearch.year},
              unit: "${advancedSearch?.unit ?? ''}",
              schoolLevel: "${advancedSearch?.schoolLevel ?? ''}",
              prefix: "${advancedSearch?.prefix ?? ''}",
              suffix: "${advancedSearch?.suffix ?? ''}",
              dateFrom: ${advancedSearch?.dateFrom ?? 0},
              dateTo: ${advancedSearch?.dateTo ?? 0},
            ) {
              id
              unit
              year
              schoolLevel
              createDate
              from
              to
              prefix
              suffix
              cancels {
                date
                serial
                note
                file
              }
            }
          }`,
      );
      setDataInitial(getCertificates);
      setWaiting(false);
    } catch ({ message }) {
      setWaiting(false);
      Notification.warn(message);
    }
  };

  const columns = useMemo(() => [
    {
      Header: 'STT',
      accessor: 'stt',
      sticky: 'left',
      disableFilters: true,
      width: 80,
      Cell: ({ row: { index } }) => index + 1,
    },
    {
      Header: 'Số hiệu phôi bằng',
      accessor: 'cert',
      sticky: 'left',
      disableFilters: true,
      width: 200,
    },
    {
      Header: 'Đơn vị',
      accessor: 'unitName',
      disableFilters: true,
      width: 200,
    },
    {
      Header: 'Bậc học',
      accessor: 'schoolLevel',
      disableFilters: true,
      width: 170,
    },
    {
      Header: 'Ngày cấp',
      accessor: 'createDate',
      type: 'date',
      disableFilters: true,
      width: 150,
    },
    {
      Header: 'thời gian huỷ',
      accessor: 'cancelDate',
      type: 'date',
      disableFilters: true,
      width: 150,
    },
    {
      Header: 'Lý do huỷ',
      accessor: 'note',
      disableFilters: true,
      width: 200,
    },
    {
      Header: 'Biên bản huỷ',
      accessor: 'file',
      disableFilters: true,
      width: 120,
      Cell: ({ cell: { value }, row: { original } }) => (
        <>
          {value ? (
            <Chip
              label={value}
              onClick={() => {
                download({
                  file: value,
                  path: `certificate/${original.unit}/${original.year}/${original.id}`,
                });
              }}
            />
          ) : null}
        </>
      ),
    },
    {
      Header: 'Hành động',
      accessor: 'cancel',
      sticky: 'right',
      disableFilters: true,
      width: 150,
      Cell: ({ cell: { value }, row: { original } }) => (
        <>
          {value ? (
            <Styles.StyleButton
              variant="text"
              startIcon={<Icon>restore</Icon>}
              onClick={() => {
                const { parent, serial, cert } = original;
                const cloned = cloneDeep(parent);
                const newCancels = (cloned?.cancels ?? []).filter(({ serial: cSerial }) => cSerial !== serial);
                set(cloned, 'cancels', newCancels);
                onUpdate(cloned, `Khôi phục phôi bằng ${cert} thành công.`);
              }}
            >
              Khôi phục
            </Styles.StyleButton>
          ) : (
            <Styles.StyleButton
              variant="text"
              startIcon={<Icon>delete</Icon>}
              onClick={() => setCancelCert(original)}
            >
              Huỷ
            </Styles.StyleButton>
          )}
        </>
      ),
    },
  ], []);

  const renderAdvancedSearch = useMemo(() => (
    <>
      <Grid container spacing={2}>
        <Grid item xs={3}>
          <TextField
            fullWidth
            label="Tiền tố:"
            value={advancedSearch?.prefix ?? ''}
            onChange={(e) => setAdvancedSearch((prev) => ({
              ...prev,
              prefix: e.target.value,
            }))}
          />
        </Grid>
        <Grid item xs={3}>
          <TextField
            fullWidth
            label="Hậu tố:"
            value={advancedSearch?.suffix ?? ''}
            onChange={(e) => setAdvancedSearch((prev) => ({
              ...prev,
              suffix: e.target.value,
            }))}
          />
        </Grid>
      </Grid>
      <Grid container spacing={2}>
        <Grid item xs={3}>
          <TextField
            fullWidth
            type="number"
            label="Bắt đầu:"
            value={advancedSearch?.from ?? 0}
            onChange={(e) => setAdvancedSearch((prev) => ({
              ...prev,
              from: e.target.value,
            }))}
            inputProps={{
              allowNegative: false,
              decimalSeparator: false,
            }}
          />
        </Grid>
        <Grid item xs={3}>
          <TextField
            fullWidth
            type="number"
            label="Kết thúc:"
            value={advancedSearch?.to ?? 0}
            onChange={(e) => setAdvancedSearch((prev) => ({
              ...prev,
              to: e.target.value,
            }))}
            inputProps={{
              allowNegative: false,
              decimalSeparator: false,
            }}
          />
        </Grid>
      </Grid>
      <Grid container spacing={2}>
        <Grid item xs={3}>
          <AutoComplete
            fullWidth
            value={advancedSearch?.year ?? new Date().getFullYear()}
            options={range(new Date().getFullYear() - 30, new Date().getFullYear() + 1)}
            getOptionLabel={(val) => `${val}`}
            inputProps={{
              label: 'Chọn năm',
            }}
            onChange={(e, value) => setAdvancedSearch((prev) => ({
              ...prev,
              year: value,
            }))}
          />
        </Grid>
        <Grid item xs={6}>
          <Grid container spacing={2}>
            <Styles.GroupDatePicker item zIdx={7} xs={3}>
              <DatePickerInput
                value={format(advancedSearch?.dateFrom)}
                inputProps={{
                  fullWidth: true,
                  label: 'Từ ngày:',
                }}
                onDayChange={(value) => {
                  if (value) {
                    setAdvancedSearch((prev) => ({
                      ...prev,
                      dateFrom: value.getTime(),
                    }));
                  }
                }}
              />
            </Styles.GroupDatePicker>
            <Styles.GroupDatePicker item zIdx={7} xs={3}>
              <DatePickerInput
                value={format(advancedSearch?.dateTo)}
                inputProps={{
                  fullWidth: true,
                  label: 'Đến ngày:',
                }}
                onDayChange={(value) => {
                  if (value) {
                    setAdvancedSearch((prev) => ({
                      ...prev,
                      dateTo: value.getTime(),
                    }));
                  }
                }}
              />
            </Styles.GroupDatePicker>
          </Grid>
        </Grid>
      </Grid>
      <Grid container spacing={2}>
        <Grid item xs={3}>
          <AutoComplete
            fullWidth
            value={advancedSearch?.schoolLevel ?? ''}
            options={['thcs', 'thpt']}
            getOptionLabel={(val) => `${val}`}
            inputProps={{
              label: 'Bậc học:',
            }}
            onChange={(e, value) => setAdvancedSearch((prev) => ({
              ...prev,
              schoolLevel: value,
              unit: value === 'thpt' ? unitActive.id : '',
            }))}
          />
        </Grid>
        <Grid item xs={3}>
          <AutoComplete
            fullWidth
            disabled={!advancedSearch?.schoolLevel}
            value={advancedSearch?.unit ?? ''}
            options={options.map(({ id }) => id)}
            getOptionLabel={(val) => [unitActive, ...pgds].find(({ id }) => id === val)?.name ?? val}
            inputProps={{
              label: 'Đơn vị:',
            }}
            onChange={(e, value) => setAdvancedSearch((prev) => ({
              ...prev,
              unit: value,
            }))}
          />
        </Grid>
      </Grid>
      <Grid container spacing={2}>
        <Grid item xs={3}>
          <FormControlLabel
            label="Tất cả"
            value="all"
            style={{ marginRight: 10 }}
            control={(
              <Checkbox
                checked={filterBy === 'all'}
                onChange={(e) => setFilterBy(e.target.value)}
              />
            )}
          />
          <FormControlLabel
            label="Chưa huỷ"
            value="cancel"
            style={{ marginRight: 10 }}
            control={(
              <Checkbox
                checked={filterBy === 'cancel'}
                onChange={(e) => {
                  setFilterBy(e.target.value);
                }}
              />
            )}
          />
          <FormControlLabel
            label="Đã huỷ"
            value="restore"
            style={{ marginRight: 10 }}
            control={(
              <Checkbox
                checked={filterBy === 'restore'}
                onChange={(e) => setFilterBy(e.target.value)}
              />
            )}
          />
        </Grid>
        <Grid item xs={3}>
          <Button
            color="primary"
            onClick={() => {
              fetchData();
            }}
          >
            Tra cứu nâng cao
          </Button>
        </Grid>
      </Grid>
    </>
  ), [advancedSearch, filterBy]);

  const renderData = useMemo(() => {
    const records = [];
    dataInitial.forEach((parent) => {
      const {
        cancels,
        from,
        to,
        prefix,
        suffix,
        unit,
        schoolLevel,
        createDate,
      } = parent;
      range(from, to + 1).forEach((idx) => {
        const findCancel = (cancels || []).find(({ serial }) => serial === idx);
        const certInfo = {
          unit,
          schoolLevel,
        };
        if (findCancel) {
          set(certInfo, 'cancel', true);
          set(certInfo, 'note', findCancel?.note ?? '');
          set(certInfo, 'file', findCancel?.file ?? '');
          set(certInfo, 'cancelDate', findCancel?.date ?? '');
        }
        set(certInfo, 'unitName', [...pgds, unitActive].find(({ id }) => id === unit)?.name ?? unit);
        set(certInfo, 'cert', `${prefix || ''}${idx}${suffix || ''}`);
        set(certInfo, 'createDate', createDate);
        set(certInfo, 'serial', idx);
        set(certInfo, 'parent', parent);
        records.push(certInfo);
      });
    });
    return records;
  }, [dataInitial]);

  const filtered = useMemo(() => {
    let cloned = cloneDeep(renderData);
    if (advancedSearch) {
      const { from, to } = advancedSearch;
      if ((from && !to) || (!from && to)) {
        cloned = cloned.filter(({ cert }) => cert.includes(`${from || to}`));
      }
      if (from && to) {
        cloned = cloned.filter(({ serial }) => serial >= from && serial <= to);
      }
    }
    return cloned;
  }, [renderData]);

  const rows = useMemo(() => {
    let cloned = cloneDeep(filtered);
    if (searchInitial) {
      cloned = cloned.filter(({ cert }) => cert.toLowerCase().includes(searchInitial.toLowerCase()));
    }
    switch (filterBy) {
      case 'restore': cloned = cloned.filter(({ cancel }) => cancel);
        break;
      case 'cancel': cloned = cloned.filter(({ cancel }) => !cancel);
        break;
      default: break;
    }
    return cloned;
  }, [searchInitial, filterBy, filtered]);

  if (waiting) return <Waiting fullscreen />;

  return (
    <>
      {
        cancelCert ? (
          <Cancels
            cert={cancelCert}
            data={cancelCert.parent}
            onClose={() => setCancelCert()}
            onSubmit={(result, msg) => {
              onUpdate(result, msg);
            }}
          />
        ) : null
      }
      <Dialog
        open
        fullWidth
        maxWidth="lg"
        onClose={onClose}
      >
        <DialogTitle
          onClose={onClose}
          title="Tra cứu"
        />
        <DialogContent dividers>
          <Styles.GroupSearch>
            {advancedSearch ? renderAdvancedSearch : (
              <Grid container spacing={2}>
                <Grid item xs={2}>
                  <TextField
                    fullWidth
                    label="Số hiệu phôi bằng"
                    value={searchInitial}
                    onChange={(e) => setSearchInitial(e.target.value)}
                    InputProps={{
                      endAdornment: <Styles.SearchIcon color="primary">search</Styles.SearchIcon>,
                    }}
                  />
                </Grid>
                <Grid item xs={4}>
                  <Box mt={3}>
                    <Styles.StyleButton
                      variant="outlined"
                      onClick={() => {
                        setSearchInitial('');
                        setAdvancedSearch({ year: new Date().getFullYear() });
                      }}
                    >
                      Tra cứu nâng cao
                    </Styles.StyleButton>
                  </Box>
                </Grid>
              </Grid>
            )}
          </Styles.GroupSearch>
          <Grid>
            <Table
              disableGlobalFilter
              getRowId={(row) => row.cert}
              columns={columns}
              data={rows}
            />
          </Grid>
        </DialogContent>
        <DialogActions>
          <Button
            color="secondary"
            onClick={onClose}
          >
            Đóng
          </Button>
        </DialogActions>
      </Dialog>
    </>
  );
}

Search.propTypes = {
  onUpdate: PropTypes.func.isRequired,
  onClose: PropTypes.func.isRequired,
  data: PropTypes.array.isRequired,
  advanced: PropTypes.object.isRequired,
  search: PropTypes.string,
};

Search.defaultProps = {
  search: '',
};

export default Search;
