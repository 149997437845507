import React from 'react';

import { AutoComplete } from '@xbotvn/react-ui/components';
import {
  Typography,
  Box,
  TextField,
  Grid,
  FormControlLabel,
  Checkbox,
  Icon,
  Paper,
  Button,
  Divider,
} from '@xbotvn/react-ui/core';
import PropTypes from 'prop-types';
import Dropzone from 'react-dropzone';
import { useSelector } from 'react-redux';

function SteduSearch({
  query,
  school,
  rangeYears,
  year,
  category,
  download,
  handleSearch,
  setUpdated,
  importFile,
  setCategory,
  setYear,
  setRangeYears,
  setSchool,
  setQuery,
}) {
  const {
    categories,
    isStaff,
  } = useSelector(({ catalogs, user }) => ({
    categories: catalogs?.system?.categories?.data ?? [],
    isStaff: (user?.unit?.staffs ?? []).includes(user.email) || user?.account?.xbot?.support,
  }));

  return (
    <Box
      mt={3}
      width={600}
      ml="auto"
      mr="auto"
    >
      <Paper elevation={3} style={{ padding: 10 }}>
        <Grid container spacing={1}>
          <Grid item xs={12}>
            <Typography variant="h6" style={{ fontWeight: 'bold' }}>
              Để tra cứu vui lòng nhập khoá thi và các thông tin sau
            </Typography>
            <Divider />
          </Grid>
          {categories.length ? (
            <Grid item xs={6}>
              <AutoComplete
                value={category}
                options={categories.map(({ value }) => value)}
                getOptionLabel={(opt) => categories.find(({ value }) => value === opt)?.text ?? ''}
                onChange={(e, value) => setCategory(value)}
                inputProps={{
                  label: 'Văn bằng/Chứng chỉ',
                  required: true,
                  variant: 'outlined',
                }}
              />
            </Grid>
          ) : null}
          <Grid item xs={2}>
            <TextField
              label="Khóa thi"
              required
              fullWidth
              value={year}
              onChange={(e) => setYear(e.target.value)}
              variant="outlined"
              type="number"
              inputProps={{
                thousandSeparator: false,
                onKeyPress: (event) => {
                  if (event.key === 'Enter') {
                    setUpdated();
                  }
                },
              }}
            />
          </Grid>
          <Grid item xs={4} style={{ display: 'flex' }}>
            {isStaff ? (
              <FormControlLabel
                label="Các năm khác"
                labelPlacement="end"
                control={(
                  <Checkbox
                    checked={rangeYears}
                    onChange={setRangeYears}
                  />
                )}
              />
            ) : null}
          </Grid>
          <Grid item xs={12}>
            <TextField
              fullWidth
              label="Trường"
              variant="outlined"
              value={school}
              onChange={(e) => setSchool(e.target.value)}
              inputProps={{
                onKeyPress: (event) => {
                  if (event.key === 'Enter') {
                    setUpdated();
                  }
                },
              }}
            />
          </Grid>
          <Grid item xs={12}>
            <TextField
              label="Họ tên/Số bằng"
              required
              fullWidth
              value={query}
              onChange={(e) => setQuery(e.target.value)}
              variant="outlined"
              inputProps={{
                onKeyPress: (event) => {
                  if (event.key === 'Enter') {
                    setUpdated();
                  }
                },
              }}
            />
          </Grid>
        </Grid>
        <Box display="flex" mt={1} alignItems="baseline">
          <Box flexGrow={1} display="flex">
            <Button
              color="secondary"
              size="small"
              startIcon={<Icon>download</Icon>}
              onClick={download}
            >
              Tải File Mẫu
            </Button>
            <Dropzone
              multiple
              onDrop={(selected) => importFile(selected)}
            >
              {({ getRootProps, getInputProps }) => (
                <div {...getRootProps()}>
                  <input {...getInputProps()} />
                  <Button
                    color="secondary"
                    size="small"
                    startIcon={<Icon>receipt_long</Icon>}
                  >
                    Tìm Theo Danh Sách
                  </Button>
                </div>
              )}
            </Dropzone>
          </Box>
          <Button
            color="primary"
            size="small"
            startIcon={<Icon>search</Icon>}
            onClick={handleSearch}
          >
            Tra Cứu
          </Button>
        </Box>
      </Paper>
    </Box>
  );
}

SteduSearch.defaultProps = {
  school: '',
  year: '',
  category: '',
};

SteduSearch.propTypes = {
  query: PropTypes.string.isRequired,
  school: PropTypes.string,
  rangeYears: PropTypes.bool.isRequired,
  year: PropTypes.number,
  category: PropTypes.string,
  download: PropTypes.func.isRequired,
  handleSearch: PropTypes.func.isRequired,
  setUpdated: PropTypes.func.isRequired,
  importFile: PropTypes.func.isRequired,
  setCategory: PropTypes.func.isRequired,
  setYear: PropTypes.func.isRequired,
  setRangeYears: PropTypes.func.isRequired,
  setSchool: PropTypes.func.isRequired,
  setQuery: PropTypes.func.isRequired,
};

export default SteduSearch;
