import React, {
  useEffect,
  useMemo,
  useState,
  useRef,
} from 'react';

import {
  AutoComplete,
  Waiting,
  Table,
  Confirmation,
  Notification,
} from '@xbotvn/react-ui/components';
import {
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  Button,
  Grid,
  Icon,
  TextField,
  IconButton,
} from '@xbotvn/react-ui/core';
import { cloneDeep, getIncreKey, clone } from '@xbotvn/utils/collection';
import PropTypes from 'prop-types';
import { useSelector, useDispatch } from 'react-redux';

import { DPROPS, PROPTYPES } from '../../../config';
import { handleUpdateDocType } from '../../../redux/actions/catalogs';

function Settings({
  onClose,
}) {
  const skipPageResetRef = useRef();
  const dispatch = useDispatch();
  const {
    docTypes,
    categories,
    handling,
    isAdmin,
  } = useSelector(({ catalogs, user }) => ({
    isAdmin: (user?.account?.xbot?.support ?? false)
                  || (user?.unit?.admins ?? []).includes(user.email),
    docTypes: catalogs?.app?.docTypes?.data ?? {},
    categories: catalogs?.system?.categories?.data ?? [],
    handling: catalogs?.system?.categories?.handling || catalogs?.app?.docTypes?.handling,
  }));

  const [remove, setRemove] = useState();
  const [properties, setProperties] = useState([]);
  const [category, setCategory] = useState(null);
  const [name, setName] = useState('');
  const [importRow, setImportRow] = useState('');
  const [importColumn, setImportColumn] = useState('');
  const [docType, setDocType] = useState(null);

  useEffect(() => {
    const data = docTypes?.[docType || ''] ?? {};
    setName(data?.name ?? '');
    setCategory(data?.category ?? categories?.[0]?.value ?? null);
    setImportRow(data?.importRow ?? '');
    setImportColumn(data?.importColumn ?? '');
    if (!['', null].includes(docType)) {
      const initProperties = data?.properties ?? [];
      const missing = DPROPS.filter((prop) => !prop.removable && !initProperties.find(
        ({ code }) => code === prop.code,
      ));
      setProperties([
        ...initProperties,
        ...missing,
      ]);
    }
  }, [docType, docTypes]);

  const columns = useMemo(() => [
    {
      Header: 'STT',
      accessor: 'order',
      type: 'number',
      width: 80,
      sticky: 'left',
      disableEdit: !isAdmin,
    },
    {
      Header: 'Tên hiển thị',
      accessor: 'name',
      sticky: 'left',
      width: 250,
      disableEdit: !isAdmin,
    },
    {
      Header: 'Kiểu dữ liệu',
      accessor: 'type',
      width: 230,
      lookup: PROPTYPES,
      disableEdit: !isAdmin,
    },
    {
      Header: 'Bắt buộc',
      accessor: 'required',
      width: 100,
      type: 'checkbox',
      disableEdit: !isAdmin,
    },
    {
      Header: 'Công khai',
      accessor: 'public',
      width: 100,
      type: 'checkbox',
      disableEdit: !isAdmin,
    },
    {
      Header: 'Cột Excel',
      accessor: 'importColumn',
      width: 120,
      disableEdit: !isAdmin,
    },
    {
      Header: '',
      accessor: 'code',
      // eslint-disable-next-line react/prop-types
      Cell: ({ cell: { value } }) => {
        if (!isAdmin || DPROPS.find(({ code, removable }) => code === value && !removable)) return '';
        return (
          <IconButton
            size="small"
            onClick={() => {
              setProperties((prevProperties) => prevProperties.filter(
                (prop) => prop.code !== value,
              ));
            }}
          >
            <Icon>delete</Icon>
          </IconButton>
        );
      },
      action: true,
      width: 60,
      sticky: 'right',
    },
  ], []);

  useEffect(() => {
    skipPageResetRef.current = false;
  }, [properties]);

  return (
    <>
      {(docType === null) ? (
        <Dialog open maxWidth="sm" fullWidth>
          <DialogTitle title="Chọn cấu hình" onClose={onClose} />
          <DialogContent dividers>
            <AutoComplete
              value={docType}
              onChange={(e, value) => setDocType(value)}
              options={Object.keys(docTypes)}
              groupBy={(opt) => categories.find(({ value }) => docTypes?.[opt]?.category === value)?.text ?? ''}
              getOptionLabel={(value) => docTypes?.[value]?.name ?? value}
              inputProps={{
                label: 'Chọn cấu hình',
                required: true,
              }}
            />
          </DialogContent>
          {isAdmin ? (
            <DialogActions>
              <Button
                color="primary"
                onClick={() => {
                  setProperties(clone(DPROPS));
                  setDocType('');
                }}
              >
                Thêm cấu hình
              </Button>
            </DialogActions>
          ) : null}
        </Dialog>
      ) : (
        <Dialog
          open
          maxWidth="lg"
          fullWidth
          onClose={onClose}
        >
          <DialogTitle onClose={onClose} title="Cấu hình" />
          <DialogContent dividers>
            {handling ? <Waiting fullscreen /> : null}
            {remove ? (
              <Confirmation
                onClose={() => setRemove()}
                severity="warning"
                title="Xóa cấu hình"
                description="Nếu xóa cấu hình, tất cả dữ liệu đã được số hóa
            trước đó ở trong cấu hình này sẽ bị mất, và không thể khôi phục lại.
            Thầy/cô có muốn xóa cấu hình này?"
                primaryAction={() => {
                  setRemove();
                  dispatch(handleUpdateDocType(docType, undefined, onClose));
                }}
              />
            ) : null}
            <Grid container spacing={1} style={{ marginTop: 5 }}>
              <Grid item xs={3}>
                <AutoComplete
                  disabled={!isAdmin}
                  value={category}
                  options={categories.map(({ value }) => value)}
                  getOptionLabel={(opt) => categories.find(({ value }) => value === opt)?.text ?? ''}
                  onChange={(e, value) => setCategory(value)}
                  inputProps={{
                    label: 'Loại VBCC',
                    required: true,
                  }}
                />
              </Grid>
              <Grid item xs={5}>
                <TextField
                  disabled={!isAdmin}
                  label="Tên cấu hình"
                  required
                  fullWidth
                  value={name}
                  onChange={(e) => setName(e.target.value)}
                  variant="outlined"
                />
              </Grid>
              <Grid item xs={2}>
                <TextField
                  disabled={!isAdmin}
                  label="Hàng bắt đầu"
                  required
                  fullWidth
                  value={importRow}
                  onChange={(e) => setImportRow(e.target.value)}
                  variant="outlined"
                  type="number"
                />
              </Grid>
              <Grid item xs={2}>
                <TextField
                  disabled={!isAdmin}
                  label="Cột minh chứng"
                  fullWidth
                  value={importColumn}
                  onChange={(e) => setImportColumn(e.target.value)}
                  variant="outlined"
                />
              </Grid>
            </Grid>
            <Table
              getRowId={(row) => row.code}
              columns={columns}
              data={properties}
              updateHandler={(rowId, column, newValue) => {
                skipPageResetRef.current = true;
                setProperties((prevProperties) => {
                  const row = prevProperties.find(({ code }) => code === rowId);
                  if (row) row[column] = newValue;
                  return cloneDeep(prevProperties);
                });
              }}
              disableGroupBy
              disableGlobalFilter
              autoResetPage={!skipPageResetRef.current}
              autoResetExpanded={!skipPageResetRef.current}
              autoResetGroupBy={!skipPageResetRef.current}
              autoResetSelectedRows={!skipPageResetRef.current}
              autoResetSortBy={!skipPageResetRef.current}
              autoResetFilters={!skipPageResetRef.current}
              autoResetRowState={!skipPageResetRef.current}
              height={window.innerHeight - 400}
              usePagination={false}
              size="auto"
            />
          </DialogContent>
          <DialogActions>
            {(docType !== null) ? (
              <Button
                color="secondary"
                onClick={() => setProperties((prevProperties) => [
                  {
                    code: getIncreKey(prevProperties.map((p) => p.code), 'c'),
                    name: '',
                    type: 'string',
                    required: true,
                    public: true,
                    order: prevProperties.length + 1,
                  },
                  ...prevProperties])}
              >
                Thêm thuộc tính
              </Button>
            ) : null}
            {docType ? (
              <Button
                color="secondary"
                onClick={() => setRemove(true)}
              >
                Xóa cấu hình
              </Button>
            ) : null}
            <Button
              color="primary"
              startIcon={<Icon>save</Icon>}
              onClick={() => {
                if (!name) {
                  Notification.warn('Tên cấu hình không được bỏ trống.');
                  return;
                }
                if (!category) {
                  Notification.warn('Chưa chọn loại cấu hình văn bằng, chứng chỉ.');
                  return;
                }
                if (!importRow) {
                  Notification.warn('Hàng bắt đầu không được bỏ trống.');
                  return;
                }

                const cleanProps = [];
                properties.forEach(({ removable, ...rest }) => {
                  cleanProps.push(rest);
                });
                dispatch(handleUpdateDocType(docType, {
                  properties: cleanProps,
                  category,
                  name,
                  importRow,
                  importColumn,
                }, (error) => {
                  if (!error) onClose();
                }));
              }}
            >
              Cập nhật
            </Button>
          </DialogActions>
        </Dialog>
      )}
    </>
  );
}

Settings.propTypes = {
  onClose: PropTypes.func.isRequired,
};

export default Settings;
