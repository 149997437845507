import React, { useState, useMemo } from 'react';

import { Confirmation, Waiting, AutoComplete } from '@xbotvn/react-ui/components';
import {
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  Grid,
  Button,

  Divider,
  Icon,
} from '@xbotvn/react-ui/core';
import { uniq, unset, cloneDeep } from '@xbotvn/utils/collection';
import PropTypes from 'prop-types';
import { useSelector } from 'react-redux';

function AutoApproval({
  onClose,
  perform,
  rows,
  properties,
}) {
  const handling = useSelector(({ proofs }) => proofs?.handling ?? false);

  const [filters, setFilters] = useState({});
  const [confirm, setConfirm] = useState();

  const cachedFilters = useMemo(() => {
    const results = {};
    properties.forEach(({ code }) => {
      const options = rows.map((obj) => obj?.[code] ?? '');
      results[code] = uniq(options.filter((opt) => opt));
    });
    return results;
  }, [rows, properties]);

  return (
    <Dialog
      open
      onClose={onClose}
      maxWidth="sm"
      fullWidth
    >
      <DialogTitle
        onClose={onClose}
        title="Xét Duyệt"
        icon={<Icon>done</Icon>}
      />
      <DialogContent dividers>
        {confirm ? (
          <Confirmation
            onClose={() => setConfirm()}
            title="Xác nhận"
            description="Thực hiện xét đậu/rớt dựa theo các tiêu chí này."
            primaryAction={() => perform(
              rows.filter((row) => Object.entries(filters).every(
                ([code, values]) => values.includes(row?.[code]),
              )),
            )}
          />
        ) : null}
        {handling ? <Waiting fullscreen /> : null}
        <Grid container spacing={2}>
          <Grid item xs={12}>
            <AutoComplete
              value={Object.keys(filters)}
              multiple
              fullWidth
              onChange={(e, value) => setFilters((prevFilters) => {
                const cloned = cloneDeep(prevFilters);
                value.forEach((c) => {
                  if (!cloned[c]) {
                    cloned[c] = [];
                  }
                });
                Object.keys(prevFilters).forEach((c) => {
                  if (!value.includes(c)) {
                    unset(cloned, c);
                  }
                });
                return cloned;
              })}
              options={properties.map(({ code }) => code)}
              getOptionLabel={(opt) => properties.find(({ code }) => code === opt)?.name ?? opt}
              inputProps={{
                label: 'Điều kiện xét đậu/rớt',
              }}
            />
          </Grid>
          {Object.keys(filters).length
            ? Object.entries(filters).map(([code, selected]) => (
              <Grid key={code} item xs={12}>
                <AutoComplete
                  value={selected}
                  multiple
                  fullWidth
                  onChange={(e, value) => setFilters((prevFilters) => {
                    const cloned = cloneDeep(prevFilters);
                    cloned[code] = value;
                    return cloned;
                  })}
                  options={cachedFilters?.[code] ?? []}
                  inputProps={{
                    label: properties.find(({ code: pcode }) => pcode === code)?.name ?? code,
                  }}
                />
                <Divider />
              </Grid>
            )) : null}
        </Grid>
      </DialogContent>
      <DialogActions>
        <Button
          color="primary"
          onClick={() => setConfirm(true)}
        >
          Thực hiện
        </Button>
      </DialogActions>
    </Dialog>
  );
}

AutoApproval.propTypes = {
  onClose: PropTypes.func.isRequired,
  perform: PropTypes.func.isRequired,
  rows: PropTypes.arrayOf(PropTypes.object).isRequired,
  properties: PropTypes.arrayOf(PropTypes.object).isRequired,
};

export default AutoApproval;
