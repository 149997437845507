import {
  unset,

  cloneDeep,
} from '@xbotvn/utils/collection';

import { PROOFS, SIGN_OUT } from '../actions/constants';

const initialState = {
  data: {},
  handling: false,
};

export default function proofs(state = initialState, action) {
  const {
    type,
    data,
    id,
    year,
    docType,
  } = action;

  switch (type) {
    case PROOFS.handlers.get:
      return {
        ...state,
        year,
        docType,
        handling: true,
      };
    case PROOFS.handlers.update:
    case PROOFS.handlers.removeFile:
    case PROOFS.handlers.upload:
      return {
        ...state,
        handling: true,
      };
    case PROOFS.reload:
      return {
        ...state,
        data,
        handling: false,
      };
    case PROOFS.update:
      return {
        ...state,
        data: {
          ...(state?.data ?? {}),
          [id]: {
            ...(state?.data?.[id] ?? {}),
            ...data,
          },
        },
        handling: false,
      };
    case PROOFS.delete:
      const oldData = cloneDeep(state?.data ?? {});
      unset(oldData, id);
      return {
        ...state,
        data: oldData,
        handling: false,
      };
    case SIGN_OUT:
      return initialState;
    default:
      return state;
  }
}
