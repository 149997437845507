import React, { useMemo, useState } from 'react';

import {
  ListItem,
  Typography,
  Box,
} from '@xbotvn/react-ui/core';
import { format } from '@xbotvn/utils/date';
import PropTypes from 'prop-types';
import { useSelector } from 'react-redux';
import { Link } from 'react-router-dom';

import {
  FileTag,
} from '../../components';
import { DPROPS } from '../../config';
import Realloc from '../Proofs/Realloc';
import * as Styles from './styles';

function Record({ id, authorized, record }) {
  const {
    docTypes,
    isStaff,
    groups,
    email,
    proofGroups,
  } = useSelector(({
    catalogs, user,
    proofs,
    records: recordsStore,
  }) => ({
    docTypes: catalogs?.app?.docTypes?.data ?? {},
    groups: user?.unit?.groups ?? {},
    email: user.email,
    proofGroups: proofs?.data?.[recordsStore?.proof ?? '']?.groups ?? [],
    isStaff: (user?.unit?.staffs ?? []).includes(user.email) || user?.account?.xbot?.support,
  }));

  const [showRealloc, setShowRealloc] = useState();

  const { proof, docType } = record;

  const checkType = (value) => {
    let newVal = value;
    if (!value) return '';
    if (typeof value === 'number') newVal = format(value, 'dd/MM/yyyy');
    return newVal;
  };

  const isPermission = useMemo(() => {
    if (isStaff) return true;
    return (proofGroups?.groups ?? []).filter((g) => g.permissions.includes('import')).some(({ id: idGroup }) => (groups?.[idGroup]?.staffs ?? []).includes(email));
  }, [isStaff, groups, proofGroups]);

  const renderModalRealloc = useMemo(() => {
    if (!showRealloc) return null;
    return <Realloc id={id} record={record} onClose={() => setShowRealloc()} />;
  }, showRealloc);

  return (
    <>
      {renderModalRealloc}
      <ListItem key={id}>
        <Styles.ItemText width={200}>
          <Styles.FullName>{record?.fullName ?? ''}</Styles.FullName>
          <Box display="flex">
            <Styles.Title>Ngày sinh:&nbsp;</Styles.Title>
            <Typography variant="body2">{checkType(record.birthDay)}</Typography>
          </Box>
          <Box display="flex">
            <Styles.Title>Giới tính:&nbsp;</Styles.Title>
            <Typography variant="body2">{record?.gender ?? ''}</Typography>
          </Box>
          <Box display="flex">
            <Styles.Title>Nơi sinh:&nbsp;</Styles.Title>
            <Typography variant="body2">{record?.birthPlace ?? ''}</Typography>
          </Box>
          <Box display="flex">
            <Styles.Title>Dân tộc:&nbsp;</Styles.Title>
            <Typography variant="body2">{record?.ethnic ?? ''}</Typography>
          </Box>
          {(authorized && proof) ? (
            <Link
              target="_blank"
              to={`/proofs/${proof}`}
            >
              Bộ minh chứng
            </Link>
          ) : null}
          {isPermission
            ? (
              <Box display="flex">
                <Styles.Realloc
                  onClick={() => setShowRealloc(true)}
                  variant="button"
                >
                  Cấp phát bản sao
                </Styles.Realloc>
              </Box>
            ) : null}
        </Styles.ItemText>
        <Styles.ItemText width={150}>
          <Box display="flex">
            <Styles.Title>Số bằng:&nbsp;</Styles.Title>
            <Typography variant="body2">{record?.docNo}</Typography>
          </Box>
          <Box display="flex">
            <Styles.Title>Số vào sổ:&nbsp;</Styles.Title>
            <Typography variant="body2">{record?.inputNo ?? ''}</Typography>
          </Box>
          {record?.rate ? (
            <Box display="flex">
              <Styles.Title>Xếp loại:&nbsp;</Styles.Title>
              <Typography variant="body2">{record.rate}</Typography>
            </Box>
          ) : null}
        </Styles.ItemText>
        <Styles.ItemText width={200}>
          {record.school ? (
            <Box display="flex">
              <Styles.Title>Trường:&nbsp;</Styles.Title>
              <Typography variant="body2">{record.school}</Typography>
            </Box>
          ) : null}
          {record.examBoard ? (
            <Box display="flex">
              <Styles.Title>Hội đồng thi:&nbsp;</Styles.Title>
              <Typography variant="body2">{record.examBoard}</Typography>
            </Box>
          ) : null}
          {(docTypes?.[docType]?.properties ?? []).map((propSetting) => {
            if (DPROPS.find(({ code }) => code === propSetting.code)) return null;
            if (propSetting.public || authorized) {
              return (
                <Box key={propSetting.code} display="flex">
                  <Styles.Title>
                    {propSetting.name}
                    :
                    &nbsp;
                  </Styles.Title>
                  <Typography variant="body2">
                    {(propSetting.type === 'date') ? format(record?.[propSetting.code]) : record?.[propSetting.code]}
                  </Typography>
                </Box>
              );
            }
            return null;
          })}
        </Styles.ItemText>
        <Styles.ItemText width={150}>
          {(record?.note?.files ?? []).map((file) => (
            <FileTag
              name={file}
              key={file}
              source="default"
              path={`proofs/${proof}/records/${id}`}
            />
          ))}
          {record?.note?.content ?? ''}
        </Styles.ItemText>
        {authorized ? (
          <>
            <Styles.ItemText width={150}>
              {record.file ? (
                <FileTag
                  name={record.file}
                  key={record.file}
                  path={`proofs/${proof}`}
                />
              ) : null}
            </Styles.ItemText>
            <Styles.ItemText width={150}>
              {(record?.allocFiles ?? []).map((allocFile) => (
                <FileTag
                  name={allocFile}
                  key={allocFile}
                  path={['proofs', proof, 'allocation'].join('/')}
                />
              ))}
            </Styles.ItemText>
          </>
        ) : null}
      </ListItem>
    </>
  );
}

Record.propTypes = {
  id: PropTypes.string.isRequired,
  authorized: PropTypes.bool,
  record: PropTypes.shape({
    proof: PropTypes.string.isRequired,
    docType: PropTypes.string.isRequired,
    fullName: PropTypes.string,
    birthDay: PropTypes.number,
    gender: PropTypes.string,
    birthPlace: PropTypes.string,
    ethnic: PropTypes.string,
    docNo: PropTypes.string,
    inputNo: PropTypes.string,
    rate: PropTypes.string,
    school: PropTypes.string,
    examBoard: PropTypes.string,
    file: PropTypes.string,
    note: PropTypes.shape({
      files: PropTypes.arrayOf(PropTypes.string),
      content: PropTypes.string,
    }),
    allocFiles: PropTypes.arrayOf(PropTypes.string),
  }).isRequired,
};

Record.defaultProps = {
  authorized: false,
};

export default Record;
