/* eslint-disable react/prop-types */
import React, {
  useState,
  useMemo,
  useRef,
  useEffect,
} from 'react';

import { Table, Confirmation } from '@xbotvn/react-ui/components';
import {
  IconButton,
  Icon,
  Box,
  Checkbox,
} from '@xbotvn/react-ui/core';
import PropTypes from 'prop-types';
import { useSelector } from 'react-redux';

function Staffs({ staffs, admins, onChange }) {
  const skipPageResetRef = useRef();
  const {
    userEmail,
  } = useSelector(({ user }) => ({
    userEmail: user?.email,
  }));

  const [isOpen, setIsOpen] = useState();

  const columns = useMemo(() => [
    {
      Header: 'Email',
      accessor: 'email',
      disableEdit: true,
      width: 300,
    },
    {
      Header: 'Quản Trị',
      accessor: 'admin',
      width: 100,
      Cell: ({ cell: { value }, row: { original } }) => {
        const { id } = original;
        return (
          <Checkbox
            disabled={id === userEmail}
            checked={value}
            onChange={() => {
              skipPageResetRef.current = true;
              onChange(id, !value);
            }}
          />
        );
      },
    },
    {
      Header: ' ',
      accessor: 'id',
      Cell: ({ cell: { value } }) => (
        value !== userEmail ? (
          <IconButton size="small" onClick={() => setIsOpen(value)}>
            <Icon>delete</Icon>
          </IconButton>
        ) : null
      ),
      action: true,
      width: 50,
      sticky: 'right',
    },
  ], []);

  const rows = useMemo(() => staffs.map((email) => ({
    id: email,
    email,
    admin: admins.includes(email),
  })), [staffs, admins]);

  useEffect(() => {
    skipPageResetRef.current = false;
  }, [rows]);

  return (
    <>
      {isOpen ? (
        <Confirmation
          onClose={() => setIsOpen()}
          primaryAction={() => {
            onChange(isOpen);
            setIsOpen();
          }}
          severity="warning"
          description="Bạn có muốn xoá nhân sự này?"
        />
      ) : null}
      <Box
        mt={2}
        width={500}
        ml="auto"
        mr="auto"
      >
        <Table
          columns={columns}
          data={rows}
          updateHandler={(rowId, column, newValue) => {
            skipPageResetRef.current = true;
            onChange(rowId, newValue);
          }}
          disableGlobalFilter
          disableGroupBy
          autoResetPage={!skipPageResetRef.current}
          autoResetExpanded={!skipPageResetRef.current}
          autoResetGroupBy={!skipPageResetRef.current}
          autoResetSelectedRows={!skipPageResetRef.current}
          autoResetSortBy={!skipPageResetRef.current}
          autoResetFilters={!skipPageResetRef.current}
          autoResetRowState={!skipPageResetRef.current}
          usePagination={false}
          height={window.innerHeight - 260}
        />
      </Box>
    </>
  );
}

Staffs.propTypes = {
  onChange: PropTypes.func.isRequired,
  staffs: PropTypes.arrayOf(PropTypes.string),
  admins: PropTypes.arrayOf(PropTypes.string),
};

Staffs.defaultProps = {
  staffs: [],
  admins: [],
};

export default Staffs;
