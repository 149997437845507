import React, {
  useState,
  useMemo,
  useCallback,
} from 'react';

import { Notification } from '@xbotvn/react-ui/components';
import {
  AppBar as UAppBar,
  Icon,
  IconButton,
  Avatar,
  Box,
  Button,
  Divider,
} from '@xbotvn/react-ui/core';
import { useSelector } from 'react-redux';
import { useHistory } from 'react-router-dom';

import { RESOURCES } from '../../config';
import {
  app,
  googleProvider,
} from '../../libs/firebase';
import Profile from './Profile';
import Settings from './Settings';
import * as Styles from './styles';
import Unit from './Unit';
import Units from './Units';

function AppBar() {
  const history = useHistory();
  const {
    unit,
    email,
    account,
    photoURL,
    displayName,
    company,
  } = useSelector(({ user }) => user);

  const [showDialog, setShowDialog] = useState();

  const dialog = useMemo(() => {
    const props = {
      onClose: () => setShowDialog(),
    };
    switch (showDialog) {
      case 'profile':
        return <Profile {...props} />;
      case 'unit':
        return <Unit {...props} />;
      case 'settings':
        return <Settings {...props} />;
      case 'units':
        return <Units {...props} />;
      default:
        return null;
    }
  }, [showDialog]);

  const {
    isSupport,
    isStaff,
    isAdmin,
    isGroup,
  } = useMemo(() => ({
    isSupport: account?.xbot?.support ?? false,
    isStaff: (unit?.staffs ?? []).includes(email),
    isAdmin: (unit?.admins ?? []).includes(email),
    isGroup: Object.values(unit?.groups ?? {}).some(({ staffs }) => staffs.includes(email)),
  }), [email, unit, account]);

  const errorHandler = (error) => {
    if (!error) return;
    if (error.code === 'auth/account-exists-with-different-credential') {
      Notification.warn(
        'Địa chỉ email này đã được đăng nhập bằng hình thức đăng nhập khác.',
      );
    } else {
      Notification.warn(`Lỗi kết nối: ${error}`);
    }
  };

  const authWithGoogle = useCallback(() => {
    app
      .auth()
      .signInWithPopup(googleProvider)
      .catch((error) => {
        errorHandler(error);
      });
  }, []);

  return (
    <UAppBar position="static" style={{ background: 'white', padding: 10 }}>
      {dialog}
      <Styles.StyledToolbar>
        <Box display="flex" flexGrow={1}>
          <Box display="flex" alignItems="center" mr={2}>
            <img
              style={{ width: 40, height: 'auto' }}
              src={`${RESOURCES}/${company.toLowerCase()}.png`}
              alt="logo"
            />
            <Divider style={{ marginLeft: 10 }} orientation="vertical" />
          </Box>
          {(isAdmin || isSupport || isStaff || isGroup) ? (
            <>
              <Styles.MenuItem
                onClick={() => history.push('/')}
                startIcon={<Icon>home</Icon>}
              >
                Trang Chủ
              </Styles.MenuItem>
              <Styles.MenuItem
                onClick={() => setShowDialog('settings')}
                startIcon={<Icon>settings</Icon>}
              >
                Cấu Hình
              </Styles.MenuItem>
              <Styles.MenuItem
                onClick={() => history.push('/proofs')}
                startIcon={<Icon>source</Icon>}
              >
                Số Hóa
              </Styles.MenuItem>
            </>
          ) : null}
          {(isAdmin || isSupport) ? (
            <>
              <Styles.MenuItem
                onClick={() => setShowDialog('unit')}
                startIcon={<Icon>group</Icon>}
              >
                Nhân Sự
              </Styles.MenuItem>
              <Styles.MenuItem
                onClick={() => history.push('/realloc')}
                startIcon={<Icon>recent_actors</Icon>}
              >
                Quản lý cấp phát bản sao
              </Styles.MenuItem>
            </>
          ) : null}
          {(isSupport || (isAdmin && ['sgd'].includes(unit?.type)))
            ? (
              <>
                <Styles.MenuItem
                  onClick={() => {
                    history.push('/statisticAlloc');
                  }}
                  startIcon={<Icon>ballot</Icon>}
                >
                  Theo dõi cấp phát bằng
                </Styles.MenuItem>
                <Styles.MenuItem
                  onClick={() => {
                    history.push('/certificates');
                  }}
                  startIcon={<Icon>assignment</Icon>}
                >
                  Quản lý phôi bằng
                </Styles.MenuItem>
              </>
            )
            : null}
        </Box>
        {isSupport ? (
          <IconButton onClick={() => setShowDialog('units')}>
            <Icon color="error">admin_panel_settings</Icon>
          </IconButton>
        ) : null}
        {email ? (
          <Avatar
            alt={displayName || email} src={photoURL || ''}
            onClick={() => setShowDialog('profile')}
            style={{ cursor: 'pointer', marginLeft: 8, marginRight: 10 }}
          />
        ) : (
          <Button onClick={authWithGoogle}>
            Đăng nhập
          </Button>
        )}
      </Styles.StyledToolbar>
    </UAppBar>
  );
}

export default AppBar;
