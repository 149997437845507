import { HOSTED } from '../../config';
import { USER, SIGN_OUT } from '../actions/constants';

const domains = window.location.hostname.split('.');

const initialState = {
  company: HOSTED[domains?.[0]]?.company ?? 'XBOT',
  authorized: false,
};

export default function user(state = initialState, action) {
  const {
    type,
    admins,
    staffs,
    groups,
    ...rest
  } = action;

  switch (type) {
    case USER.handlers.login:
    case USER.handlers.update:
    case USER.handlers.groups:
      return {
        ...state,
        handling: true,
      };
    case USER.update:
      return {
        ...state,
        ...rest,
        handling: false,
      };
    case USER.unit:
      return {
        ...state,
        unit: {
          ...(state?.unit ?? {}),
          admins,
          staffs,
          groups,
        },
        handling: false,
      };
    case SIGN_OUT:
      return initialState;
    default:
      return state;
  }
}
