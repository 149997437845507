import { Notification } from '@xbotvn/react-ui/components';
import {
  takeLatest,
  all,
  select,
  put,
} from 'redux-saga/effects';

import { graphQLCaller, uploadFiles } from '../../libs/backend';
import { flattenProperties } from '../../libs/utils';
import { REALLOCS } from './constants';

function* update(year, data = []) {
  const docTypes = (yield select())?.catalogs?.app?.docTypes?.data ?? {};
  const selectDocTypes = data.map(({ docType }) => docType);
  let props = {};
  Object.entries(docTypes).forEach(([key, obj]) => {
    if (selectDocTypes.includes(key)) {
      props = {
        ...props,
        [key]: [
          {
            code: 'year',
            name: 'Khóa thi',
            type: 'number',
          },
          ...obj?.properties,
        ],
      };
    }
  });
  yield put({
    type: REALLOCS.update,
    year,
    data: data.map(({ properties, docType, ...rest }) => ({
      docType,
      ...rest,
      ...flattenProperties(properties, (props?.[docType] ?? [])),
    })),
  });
}

function* getReallocs({ year }) {
  const unitID = (yield select())?.user?.unit?.id;
  try {
    const { query: reallocs } = yield graphQLCaller(
      'reallocs',
      `{
        query(unitID: "${unitID}", year: ${year}) {
          id
          properties {
            code
            value
          }
          docType
          proof
          attachment
          note
          issuer
          issueDate
          issueNo
          record
        }
      }`,
    );
    yield* update(year, reallocs);
  } catch ({ message }) {
    Notification.warn(message);
  }
}

function* insertRealloc({ data, attachment, onComplete }) {
  const year = new Date(data?.issueDate).getFullYear();
  const {
    proof,
  } = (yield select())?.records ?? {};
  const reallocs = (yield select())?.[year] ?? [];
  const unitID = (yield select())?.user?.unit?.id ?? '';
  try {
    const { insertRealloc: allocID } = yield graphQLCaller('reallocs', `
      mutation insertRealloc($unitID: String!, $proof: String!, $year: Int!, $data: ReallocInput!) {
        insertRealloc(unitID: $unitID, proof: $proof, year: $year, data: $data)
      }
    `, {
      unitID,
      proof: proof || data.properties.find((prop) => prop.code === 'proof').value,
      year,
      data,
    });

    if (attachment && allocID) {
      yield uploadFiles(
        'reallocs/upload',
        {
          unitID,
          year,
          fileName: allocID,
        },
        attachment,
      );
      yield* update(
        year,
        [
          ...reallocs,
          {
            id: allocID,
            ...data,
            proof,
          },
        ],
      );
    }
    Notification.success('Thêm mới thành công.', { action: onComplete });
  } catch ({ message }) {
    Notification.warn('Thêm mới không thành công.', { action: () => onComplete(message) });
  }
}

export const handleGetReallocs = (
  year,
) => ({
  type: REALLOCS.handlers.get,
  year,
});

export const handleInsertRealloc = (
  data,
  attachment,
  onComplete,
) => ({
  type: REALLOCS.handlers.insert,
  data,
  attachment,
  onComplete,
});

export default function* saga() {
  yield all([
    yield takeLatest(REALLOCS.handlers.get, getReallocs),
    yield takeLatest(REALLOCS.handlers.insert, insertRealloc),
  ]);
}
