import {
  Button,
  ListItemText,
} from '@xbotvn/react-ui/core';
import { colors } from '@xbotvn/react-ui/styles';
import styled from 'styled-components';

export const UploadButton = styled(Button).attrs({
  color: 'primary',
  size: 'small',
})`
  margin-left: 10px !important;
`;

export const BtnBack = styled(Button).attrs({
  variant: 'text',
})`
  color: ${colors.blue[500]} !important;
  && {
    margin-right: 10px;
  }
`;

export const Paper = styled.div`
  text-align: center;
  padding: 12px 0px;
`;

export const ItemText = styled(ListItemText)`
  width: ${({ width }) => `${width}%`};
`;

export const RemoveBtn = styled(Button)`
  && {
    margin-left: 5px;
    background-color: #ff0000d2;
    color: white;

    :hover {
      background-color: #ff0000;
    }
  }
`;

export const GroupRecords = styled.div`
  margin-bottom: 50px;
`;
