import React, { useMemo, useState } from 'react';

import { Table } from '@xbotvn/react-ui/components';
import {
  IconButton,
  Chip,
  Box,
  Icon,
} from '@xbotvn/react-ui/core';
import { uniq } from '@xbotvn/utils/collection';
import PropTypes from 'prop-types';

import Group from './Group';

function Groups({ groups, onChange }) {
  const [group, setGroup] = useState();
  const columns = useMemo(() => [
    {
      Header: 'STT',
      accessor: 'stt',
      width: 50,
      type: 'number',
    },
    {
      Header: 'Tên Nhóm',
      accessor: 'name',
      width: 300,
    },
    {
      Header: 'Nhân Sự',
      accessor: 'staffs',
      width: 100,
      // eslint-disable-next-line react/prop-types
      Cell: ({ cell: { value } }) => {
        const total = (value || []).length;
        if (total === 0) return '';
        return (
          <Chip
            label={total}
            color="primary"
          />
        );
      },
    },
    {
      Header: ' ',
      accessor: 'id',
      // eslint-disable-next-line react/prop-types
      Cell: ({ cell: { value } }) => (
        <>
          <IconButton size="small" onClick={() => setGroup(value)}>
            <Icon>edit</Icon>
          </IconButton>
          <IconButton size="small" onClick={() => onChange(value)}>
            <Icon>delete</Icon>
          </IconButton>
        </>
      ),
      action: true,
      width: 80,
    },
  ], []);

  const rows = useMemo(() => Object.entries(groups).map(([id, values], index) => ({
    stt: index + 1,
    id,
    ...values,
  })), [groups]);

  return (
    <>
      {group ? (
        <Group
          onClose={(name, emails = '') => {
            if (name) onChange(group, name, uniq(emails.split(', ').map((e) => e.replace(/ /g, '')).filter((e) => e)));
            setGroup();
          }}
          id={group}
          name={groups?.[group]?.name ?? ''}
          members={(groups?.[group]?.staffs ?? []).join(', ')}
        />
      ) : null}
      <Box
        mt={2}
        width={550}
        ml="auto"
        mr="auto"
      >
        <Table
          columns={columns}
          data={rows}
          disableFilters
          disableGlobalFilter
          disableGroupBy
          usePagination={false}
          height={window.innerHeight - 260}
        />
      </Box>
    </>
  );
}

Groups.propTypes = {
  onChange: PropTypes.func.isRequired,
  groups: PropTypes.object,
};

Groups.defaultProps = {
  groups: {},
};

export default Groups;
