import { Notification } from '@xbotvn/react-ui/components';
import { clone, unset } from '@xbotvn/utils/collection';
import {
  put,
  takeLatest,
  all,
  select,
  takeEvery,
} from 'redux-saga/effects';

import { graphQLCaller, uploadFiles, callFileAPI } from '../../libs/backend';
import { PROOFS } from './constants';

function* update(id, data = {}) {
  yield put({
    type: PROOFS.update,
    id,
    data,
  });
}

function* reload(data = []) {
  const converted = {};
  data.forEach((draft) => {
    const { id, ...rest } = draft;
    converted[id] = rest;
  });
  yield put({
    type: PROOFS.reload,
    data: converted,
  });
}

function* getProof({ id }) {
  const unitID = (yield select())?.user?.unit?.id ?? '';
  try {
    const { proof } = yield graphQLCaller(
      'proofs',
      `
        query proof($unitID: String!, $id: ID!) {
          proof(unitID: $unitID, id: $id) {
            year
            docType
          }
        }
      `,
      {
        unitID,
        id,
      },
    );
    const { year, docType } = proof || {};
    if (year && docType) {
      yield put({
        type: PROOFS.handlers.get,
        year,
        docType,
      });
    }
  } catch ({ message }) {
    Notification.error(message);
  }
}

function* getProofs({ year, docType }) {
  const unitID = (yield select())?.user?.unit?.id ?? '';
  try {
    const { proofs } = yield graphQLCaller(
      'proofs',
      `
        query proofs($unitID: String!, $docType: String!, $year: Int!) {
          proofs(unitID: $unitID, docType: $docType, year: $year) {
            id
            name
            note
            unit
            files {
              name
              note
            }
            allocFiles
            groups {
              id
              permissions
            }
            approved {
              received
              total
              passed
            }
            latest {
              user
              time
            }
          }
        }
      `,
      {
        unitID,
        docType,
        year,
      },
    );
    yield* reload(proofs || []);
  } catch ({ message }) {
    Notification.error(message);
    yield* reload();
  }
}

function* updateProofs({ listProof, onComplete }) {
  const unitID = (yield select())?.user?.unit?.id ?? '';
  const { year, docType, data: proofs } = (yield select())?.proofs ?? {};
  let reloadProofs = {};
  yield* listProof.map(
    function* updateProof(obj) {
      const { id, data, newYear } = obj;
      const proof = proofs?.[id] ?? {};
      try {
        let actualID = id;
        const updateContent = clone(data);
        unset(updateContent, 'latest');
        if (!id || newYear) {
          const { insertProof } = yield graphQLCaller('proofs', `
            mutation insertProof($unitID: String!, $data: ProofInput!) {
              insertProof(unitID: $unitID, data: $data) {
                id
              }
            }
          `, {
            unitID,
            data: {
              docType,
              year: newYear || year,
              ...updateContent,
            },
          });
          actualID = insertProof?.id;
        } else if (!newYear) {
          yield graphQLCaller('proofs', `
            mutation updateProof($unitID: String!, $log: String!, $id: ID!, $data: ProofInput) {
              updateProof(unitID: $unitID, log: $log, id: $id, data: $data)
            }
          `, {
            unitID,
            id,
            log: proof.name,
            data: updateContent ? {
              ...updateContent,
              name: proof.name,
              year,
              docType,
            } : undefined,
          });
        }
        if (data) {
          if (!newYear) {
            yield* update(actualID, updateContent);
          } else {
            reloadProofs = Object.assign(reloadProofs, { [id]: { id, ...data } });
          }
        } else {
          yield put({
            type: PROOFS.delete,
            id,
          });
        }
      } catch ({ message }) {
        yield* update(id);
        Notification.error(message);
      }
    },
  );
  if (Object.keys(reloadProofs).length) {
    yield* reload(Object.values(reloadProofs) || []);
  }
  Notification.success('Cập nhật thành công.', { action: onComplete });
}

function* uploadProofs({ id, files, allocation }) {
  const unitID = (yield select())?.user?.unit?.id ?? '';
  try {
    const content = {
      unitID,
      proof: id,
    };
    if (allocation) content.allocation = allocation;
    const uploaded = yield uploadFiles(
      'proofs/upload',
      content,
      files,
    );

    yield* update(id, allocation ? { allocFiles: uploaded || [] } : { files: uploaded || [] });
    Notification.success('Upload file thành công.');
  } catch ({ message }) {
    yield* update(id);
    Notification.error(message);
  }
}

function* removeFile({ id, file, allocation }) {
  const unitID = (yield select())?.user?.unit?.id ?? '';
  try {
    const content = {
      unitID,
      proof: id,
      file,
    };
    if (allocation) content.allocation = allocation;
    const files = yield callFileAPI(
      'proofs/removeFile',
      content,
    );
    yield* update(id, allocation ? { allocFiles: files } : { files });
    Notification.success('Xoá file thành công.');
  } catch ({ message }) {
    yield* update(id);
    Notification.error(message);
  }
}

export const handleUpdateProof = (
  listProof,
  onComplete,
) => ({
  type: PROOFS.handlers.update,
  listProof,
  onComplete,
});

export const handleUploadFiles = (
  id,
  files,
  allocation,
  onSuccess,
) => ({
  type: PROOFS.handlers.upload,
  id,
  files,
  allocation,
  onSuccess,
});

export const handleGetProofs = (year, docType) => ({
  type: PROOFS.handlers.get,
  year,
  docType,
});

export const handleGetProof = (id) => ({
  type: PROOFS.handlers.getById,
  id,
});

export const handleRemoveFile = (id, file, allocation) => ({
  type: PROOFS.handlers.removeFile,
  id,
  file,
  allocation,
});

export default function* saga() {
  yield all([
    yield takeLatest(PROOFS.handlers.get, getProofs),
    yield takeLatest(PROOFS.handlers.getById, getProof),
    yield takeEvery(PROOFS.handlers.update, updateProofs),
    yield takeEvery(PROOFS.handlers.upload, uploadProofs),
    yield takeEvery(PROOFS.handlers.removeFile, removeFile),
  ]);
}
