import {
  AppBar,
  Icon,
  Button,
  IconButton,
  Grid,
} from '@xbotvn/react-ui/core';
import { colors } from '@xbotvn/react-ui/styles';
import styled from 'styled-components';

export const Header = styled(AppBar)`
  background: #fff !important;
  box-shadow: none !important;
  padding: 1px !important;
`;

export const SearchIcon = styled(Icon)`
  margin-right: 10px;
  cursor: pointer;
`;

export const StyleButton = styled(Button)`
  color: ${colors.blue[500]} !important;

  && {
    margin-top: 7px;
  }
`;

export const Actions = styled(IconButton).attrs({
  size: 'small',
})`
  margin: 0px 5px !important;
`;

export const GroupDatePicker = styled(Grid)`
  .DayPickerInput-OverlayWrapper {
    z-index: ${({ zIdx }) => zIdx || 1};
    position: ${({ position }) => position || 'relative'};
  }
`;

export const GroupSearch = styled(Grid)`
  border-radius: 10px;
  border: solid 1px #0000002e;
  padding: 25px;
`;
