import { Notification } from '@xbotvn/react-ui/components';
import { cloneDeep, uniq } from '@xbotvn/utils/collection';
import { toASCII } from '@xbotvn/utils/string';
import {
  put,
  takeLatest,
  all,
  select,
} from 'redux-saga/effects';

import { graphQLCaller, uploadFiles } from '../../libs/backend';
import { arrToCollection } from '../../libs/utils';
import { RECORDS } from './constants';

function* update(data = {}) {
  yield put({
    type: RECORDS.update,
    data,
  });
}

function* getRecords({ proof, properties }) {
  const { id } = (yield select())?.user?.unit ?? {};
  try {
    const { proofsRecords } = yield graphQLCaller(
      'records',
      `{
        proofsRecords(unitID: "${id}", proof: "${proof}") {
          id
          search
          properties {
            code
            value
          }
          file
          allocFiles
          note {
            content
            files
          }
        }
      }`,
    );
    yield* update(arrToCollection(proofsRecords, properties));
  } catch ({ message }) {
    Notification.warn(message);
    yield* update();
  }
}

function* importRecords({ data, onComplete }) {
  const {
    proof,
    docType,
    year,
    properties,
  } = (yield select())?.records ?? {};
  const category = (yield select())?.catalogs?.app?.docTypes?.data?.[docType]?.category ?? '';
  const unit = (yield select())?.user?.unit?.id ?? '';
  if (proof && docType && category && year) {
    try {
      const cleanData = [];
      Object.entries(data).forEach(([id, {
        importFrom,
        errors,
        note,
        file,
        ...rest
      }]) => {
        if (!errors) {
          const converted = {
            unit,
            docType,
            category,
            year,
            proof,
            file,
          };
          if (id.indexOf('new') !== 0) converted.id = id;
          converted.search = toASCII(rest?.fullName ?? '').replace(/\s/g, '');
          converted.properties = [];
          Object.entries(rest).forEach(([code, value]) => {
            converted.properties.push({
              code,
              value: `${value}`,
            });
          });
          cleanData.push(converted);
        }
      });
      yield graphQLCaller('records', `
      mutation importRecords($unitID: String!, $proof: String!, $records: [RecordInsert]!) {
        importRecords(unitID: $unitID, proof: $proof, records: $records)
      }
    `, {
        unitID: unit,
        proof,
        records: cleanData,
      });
      yield put({
        type: RECORDS.handlers.get,
        proof,
        year,
        docType,
        properties,
      });
      Notification.success('Cập nhật thành công.', { action: onComplete });
    } catch ({ message }) {
      yield* update();
      Notification.warn(message, { action: () => onComplete(message) });
    }
  } else { yield* update(); }
}

function* updateRecords({ data, onComplete }) {
  const {
    proof,
    docType,
    year,
    properties,
  } = (yield select())?.records ?? {};
  const unit = (yield select())?.user?.unit?.id ?? '';
  if (proof && docType && year) {
    const cleanData = [];
    data.forEach(({
      id,
      file,
      allocFiles,
      note,
      ...rest
    }) => {
      const converted = {
        id,
        file,
        allocFiles,
        note,
      };
      converted.properties = [];
      properties.forEach(({ code }) => {
        converted.properties.push({
          code,
          value: `${rest?.[code] ?? ''}`,
        });
      });
      cleanData.push(converted);
    });
    try {
      yield graphQLCaller('records', `
      mutation updateRecords($unitID: String!, $proof: String!, $records: [RecordInput]!) {
        updateRecords(unitID: $unitID, proof: $proof, records: $records)
      }
    `, {
        unitID: unit,
        proof,
        records: cleanData,
      });
      yield put({
        type: RECORDS.handlers.get,
        proof,
        year,
        docType,
        properties,
      });
      Notification.success('Cập nhật thành công.', { action: onComplete });
    } catch ({ message }) {
      yield* update();
      Notification.warn(message, { action: () => onComplete(message) });
    }
  } else { yield* update(); }
}

function* clearRecords({ ids, onComplete }) {
  const {
    proof,
    docType,
    year,
    properties,
  } = (yield select())?.records ?? {};
  const unitID = (yield select())?.user?.unit?.id ?? '';
  if (proof) {
    try {
      yield graphQLCaller(
        'records',
        `
          mutation clearRecords($unitID: String!, $proof: String!, $ids: [String]!) {
            clearRecords(unitID: $unitID, proof: $proof, ids: $ids)
          }
        `,
        {
          unitID,
          proof,
          ids,
        },
      );
      yield put({
        type: RECORDS.handlers.get,
        proof,
        year,
        docType,
        properties,
      });
      Notification.success('Xoá đối tượng thành công.', { action: onComplete });
    } catch ({ message }) {
      yield* update();
      Notification.warn(message, { action: () => onComplete(message) });
    }
  } else { yield* update(); }
}

function* updateRecord({
  id,
  data,
  upload,
  onComplete,
}) {
  const unitID = (yield select())?.user?.unit?.id ?? '';
  const {
    proof,
  } = (yield select())?.records ?? {};
  const record = cloneDeep((yield select())?.records?.data?.[id] ?? {});
  try {
    let newFiles = [];
    if (upload.length) {
      newFiles = yield uploadFiles(
        'proofs/upload',
        {
          unitID,
          proof,
          record: id,
        },
        upload,
      );
    }
    record.file = data.file;
    record.allocFiles = data?.allocFiles ?? [];
    record.note = data.note || { content: '', files: [] };
    if (newFiles.length) {
      record.note.files = uniq([...(record.note.files || []), ...newFiles]);
    }

    yield graphQLCaller(
      'records',
      `
        mutation updateRecords($unitID: String!, $proof: String!, $records: [RecordInput]!) {
          updateRecords(unitID: $unitID, proof: $proof, records: $records)
        }
      `,
      {
        unitID,
        proof,
        records: [{
          id,
          file: record.file,
          allocFiles: record.allocFiles,
          note: record.note,
        }],
      },
    );

    yield* update({ [id]: record });
    Notification.success('Cập nhật đối tượng thành công.', { action: onComplete });
  } catch ({ message }) {
    yield* update();
    Notification.warn(message, { action: () => onComplete(message) });
  }
}

function* updatePropertiesRecords({ data, onComplete }) {
  const {
    proof,
    docType,
    year,
    properties,
  } = (yield select())?.records ?? {};
  const unit = (yield select())?.user?.unit?.id ?? '';
  if (docType && year) {
    try {
      yield graphQLCaller('records', `
        mutation updatePropertiesRecords($unitID: String!, $proof: String!, $docType: String!, $records: [RecordInput]!) {
         updatePropertiesRecords(unitID: $unitID, proof: $proof, docType: $docType, records: $records)
        }
      `, {
        unitID: unit,
        docType,
        proof,
        records: data,
      });
      yield put({
        type: RECORDS.handlers.get,
        proof,
        year,
        docType,
        properties,
      });
      Notification.success('Cập nhật thành công.', { action: onComplete });
    } catch ({ message }) {
      yield* update();
      Notification.warn(message, { action: () => onComplete(message) });
    }
  } else { yield* update(); }
}

export const handleGetRecords = (
  year,
  docType,
  properties,
  proof,
) => ({
  type: RECORDS.handlers.get,
  year,
  docType,
  properties,
  proof,
});

export const handleClearRecords = (ids = [], onComplete) => ({
  type: RECORDS.handlers.clear,
  ids,
  onComplete,
});

export const handleImportRecords = (data = {}, onComplete) => ({
  type: RECORDS.handlers.import,
  data,
  onComplete,
});

export const handleUpdateRecords = (data = {}, onComplete) => ({
  type: RECORDS.handlers.update,
  data,
  onComplete,
});

export const handleUpdateRecord = (
  id,
  data,
  upload,
  onComplete,
) => ({
  type: RECORDS.handlers.record,
  id,
  data,
  upload,
  onComplete,
});

export const handleUpdatePropertiesRecords = (data = {}, onComplete) => ({
  type: RECORDS.handlers.updateProperties,
  data,
  onComplete,
});

export default function* saga() {
  yield all([
    yield takeLatest(RECORDS.handlers.get, getRecords),
    yield takeLatest(RECORDS.handlers.import, importRecords),
    yield takeLatest(RECORDS.handlers.clear, clearRecords),
    yield takeLatest(RECORDS.handlers.record, updateRecord),
    yield takeLatest(RECORDS.handlers.update, updateRecords),
    yield takeLatest(RECORDS.handlers.updateProperties, updatePropertiesRecords),
  ]);
}
