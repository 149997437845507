/* eslint-disable react/prop-types */
import React, {
  useState,
  useMemo,
  useEffect,
  useCallback,
} from 'react';

import {
  Table,
  Waiting,
  AutoComplete,
  Notification,
} from '@xbotvn/react-ui/components';
import {
  Button,
  Icon,
  Toolbar,
  Box,
  AppBar,
  Chip,
} from '@xbotvn/react-ui/core';
import {
  range,
  uniq,
} from '@xbotvn/utils/collection';
import {
  format,
} from '@xbotvn/utils/date';
import { saveAs } from 'file-saver';
import { useDispatch, useSelector } from 'react-redux';
import XLSX from 'xlsx';

import { DPROPS, PROPSSTYLES } from '../../config';
import { callFileAPI } from '../../libs/backend';
import { handleGetReallocs } from '../../redux/actions/reallocs';

function Alloc() {
  const dispatch = useDispatch();
  const {
    reallocs,
    unitID,
    docTypes,
  } = useSelector(({ reallocs: reallocStore, user, catalogs }) => ({
    reallocs: reallocStore,
    unitID: user?.unit?.id ?? '',
    docTypes: catalogs?.app?.docTypes?.data ?? {},
  }));

  const replaceKeySchool = 'Trường';
  const replaceKeyYear = 'Khóa thi';

  const [waiting, setWaiting] = useState(false);
  const [year, setYear] = useState(new Date().getFullYear());

  useEffect(() => {
    if (year) {
      dispatch(handleGetReallocs(year));
    }
  }, [year]);

  const download = useCallback(({ file, path, name }) => {
    setWaiting(true);
    callFileAPI('reallocs/download', {
      file,
      path,
    }, true).then((data) => {
      setWaiting(false);
      saveAs(new Blob([data]), name);
    }).catch(({ message }) => {
      setWaiting(false);
      Notification.warn(`Tải file không thành công: ${message}`);
    });
  }, []);

  const groupKeys = useMemo(() => {
    const data = reallocs?.[year] ?? [];
    const properties = {};
    if (!data.length) return {};
    const groupDocTypes = uniq(data.map(({ docType }) => docType));
    Object.entries(docTypes).forEach(([key, obj]) => {
      if (groupDocTypes.includes(key)) {
        const keys = (replaceKey) => [
          ...DPROPS,
          ...obj.properties,
        ].reduce((group, prop) => {
          const { code, name } = prop;
          if (name === replaceKey && !group.includes(code)) {
            group.push(code);
          }
          return group;
        }, []);
        properties[key] = {
          keysSchool: keys(replaceKeySchool),
          keysYear: keys(replaceKeyYear),
        };
      }
    });
    return properties;
  }, [docTypes, reallocs, year]);

  const data = useMemo(() => Object.entries(reallocs?.[year] ?? []).map(([index, value]) => {
    const newValue = {};
    Object.entries(value).forEach(([key, val]) => {
      const { docType } = value;
      if (groupKeys[docType]) {
        const { keysSchool, keysYear } = groupKeys[docType];
        if (keysSchool.includes(key)) {
          newValue[replaceKeySchool] = val;
        } else if (keysYear.includes(key)) {
          newValue.year = value[key];
        } else {
          newValue[key] = val;
        }
      }
    });
    return ({
      order: parseFloat(index) + 1,
      ...newValue,
    });
  }), [reallocs, year, groupKeys]);

  const columns = useMemo(() => {
    const newProps = DPROPS.filter(({ code }) => !['gender', 'rate', 'grade', 'conduct'].includes(code)).map(({
      code,
      name,
      type,
    }) => {
      const column = {
        Header: name,
        accessor: name === replaceKeySchool ? replaceKeySchool : code,
        width: PROPSSTYLES?.[code]?.width ?? 250,
        sticky: PROPSSTYLES?.[code]?.sticky,
        type,
      };
      if (code === 'birthDay') {
        return {
          ...column,
          filter: 'dateOnly',
        };
      }
      return column;
    });

    return [
      {
        Header: 'STT',
        accessor: 'order',
        width: 80,
        sticky: 'left',
      },
      ...newProps,
      {
        Header: 'Khóa thi',
        accessor: 'year',
        width: 100,
      },
      {
        Header: 'Ngày cấp',
        accessor: 'issueDate',
        type: 'date',
        filter: 'dateOnly',
        width: 130,
      },
      {
        Header: 'Lý do cấp',
        accessor: 'note',
        width: 250,
      },
      {
        Header: 'Người cấp',
        accessor: 'issuer',
        width: 250,
      },
      {
        Header: 'Số hiệu bản sao',
        accessor: 'issueNo',
        width: 150,
      },
      {
        Header: 'Minh chứng',
        accessor: 'attachment',
        width: 250,
        sticky: 'right',
        Cell: ({ cell: { value }, row: { original } }) => {
          if (!value) return null;
          return (
            <Chip
              color="secondary"
              label={value}
              onClick={() => {
                download({
                  file: original.id,
                  path: `realloc/${unitID}/${year}`,
                  name: value,
                });
              }}
            />
          );
        },
      },
    ];
  }, [unitID, year, groupKeys]);

  return (
    <>
      {(reallocs.handling || waiting) ? <Waiting fullscreen /> : null}

      <AppBar
        position="static"
        style={{
          background: 'white',
          padding: 1,
          boxShadow: 'none',
        }}
      >
        <Toolbar>
          <Box display="flex" width={1}>
            <Box flexGrow={2}>
              <Box maxWidth={150}>
                <AutoComplete
                  fullWidth
                  value={year}
                  options={range(new Date().getFullYear() - 20, new Date().getFullYear() + 1)}
                  getOptionLabel={(val) => `${val}`}
                  onChange={(e, value) => setYear(value)}
                  inputProps={{
                    label: 'Chọn năm',
                    required: true,
                  }}
                />
              </Box>
            </Box>
            <Box height={40} alignSelf="center">
              {(reallocs?.[year] ?? []).length ? (
                <Button
                  color="secondary"
                  startIcon={<Icon>download</Icon>}
                  onClick={() => {
                    setWaiting(true);
                    const results = [columns.map(({ Header }) => Header)];
                    reallocs[year].forEach((element) => {
                      const newElement = [];
                      columns.forEach(({ accessor, type }) => {
                        if (accessor !== 'id') {
                          let val = element?.[accessor] ?? '';
                          if (val && type === 'date') {
                            val = format(parseFloat(val));
                          }
                          newElement.push(val);
                        }
                      });
                      results.push(newElement);
                    });
                    const wb = XLSX.utils.book_new();
                    XLSX.utils.book_append_sheet(
                      wb,
                      XLSX.utils.aoa_to_sheet(results),
                      'Danh sách',
                    );
                    XLSX.writeFile(wb, `Danh sách quản lý cấp phát bản sao năm ${year}.xlsx`);
                    setWaiting(false);
                  }}
                >
                  Tải danh sách
                </Button>
              ) : null}
            </Box>
          </Box>
        </Toolbar>
      </AppBar>
      <Box m={2} mb={8}>
        <Table
          getRowId={(row) => row.id}
          columns={columns}
          data={data}
          disableGroupBy
          disableGlobalFilter
          rowHeight={42}
          usePagination={false}
          height={window.innerHeight - 240}
        />
      </Box>
    </>
  );
}

export default Alloc;
