import React, { useState, useMemo, useEffect } from 'react';

import { AutoComplete, Waiting, Table } from '@xbotvn/react-ui/components';
import {
  AppBar,
  Toolbar,
  Box,
  TextField,
  Button,
  Icon,
  IconButton,
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  Typography,
} from '@xbotvn/react-ui/core';
import { unset } from '@xbotvn/utils/collection';
import { format } from '@xbotvn/utils/date';
import { useSelector, useDispatch } from 'react-redux';
import { useHistory, useParams } from 'react-router-dom';
import ReactToolTip from 'react-tooltip';

import { handleGetProofs, handleUpdateProof, handleGetProof } from '../../redux/actions/proofs';
import Proof from './Proof';
import ProofModal from './ProofModal';

function Proofs() {
  const params = useParams();
  const history = useHistory();
  const dispatch = useDispatch();
  const {
    year: initYear,
    docType: initDocType,
    proofs,
    docTypes,
    categories,
    isAdmin,
    groupsCatalog,
    unitId,
    handling,
  } = useSelector(({ proofs: proofsStore, catalogs, user }) => ({
    year: proofsStore?.year ?? null,
    proofs: proofsStore?.data ?? {},
    docType: proofsStore?.docType ?? '',
    docTypes: catalogs?.app?.docTypes?.data ?? {},
    categories: catalogs?.system?.categories?.data ?? [],
    handling: proofsStore?.handling ?? false,
    groupsCatalog: user?.unit?.groups ?? {},
    unitId: user?.unit?.id ?? '',
    isAdmin: (user?.unit?.admins ?? []).includes(user.email) || user?.account?.xbot?.support,
  }));

  const proofID = params?.id ?? '';

  const [year, setYear] = useState(initYear);
  const [docType, setDocType] = useState(initDocType);
  const [proof, setProof] = useState();
  const [copyProof, setCopyProof] = useState();

  useEffect(() => {
    if (year && docType) {
      dispatch(handleGetProofs(parseFloat(year), docType));
    }
  }, [year, docType]);

  useEffect(() => {
    if (unitId && proofID && !proofs?.[proofID]) {
      dispatch(handleGetProof(proofID));
    }
  }, [unitId, proofID, proofs]);

  const columns = useMemo(() => [
    {
      Header: 'Tên bộ minh chứng',
      accessor: 'name',
      width: 250,
    },
    {
      Header: 'Nhóm',
      accessor: 'groups',
      width: 300,
    },
    {
      Header: 'Đậu/Tổng số (Tỷ lệ đậu)',
      accessor: 'approved',
      width: 260,
      // eslint-disable-next-line react/prop-types
      Cell: ({ row: { original } }) => {
        // eslint-disable-next-line react/prop-types
        const total = original?.approved?.total ?? 0;
        // eslint-disable-next-line react/prop-types
        const passed = original?.approved?.passed ?? 0;
        if (total) {
          return (
            <strong>
              {`${passed}/${total} (${Math.round((passed * 10000) / total) / 100}%)`}
            </strong>
          );
        }
        return null;
      },
    },
    {
      Header: 'Ngày cập nhật',
      accessor: 'latest',
      width: 200,
      // eslint-disable-next-line react/prop-types
      Cell: ({ cell: { value }, row: { original: id } }) => {
        const { user, time } = value || {};
        if (user && time) {
          return (
            <>
              <Typography data-tip data-for={`${id}_latest`}>{format(parseFloat(time))}</Typography>
              <ReactToolTip id={`${id}_latest`} place="bottom">
                <Typography>{user}</Typography>
              </ReactToolTip>
            </>
          );
        }
        return null;
      },
    },
    {
      Header: 'Ghi chú',
      accessor: 'note',
      width: 260,
    },
    {
      Header: ' ',
      accessor: 'id',
      // eslint-disable-next-line react/prop-types
      Cell: ({ cell: { value } }) => (
        <>
          <IconButton color="primary" onClick={() => history.push(`/proofs/${value}`)}>
            <Icon>folder_open</Icon>
          </IconButton>
          {isAdmin ? (
            <IconButton color="primary" onClick={() => setProof(value)}>
              <Icon>edit</Icon>
            </IconButton>
          ) : null}
        </>
      ),
      sticky: 'right',
      width: 100,
      action: true,
    },
  ], [isAdmin]);

  const data = useMemo(() => Object.entries(proofs).map(([id, {
    name,
    note,
    groups,
    approved,
    latest,
  }]) => ({
    id,
    name,
    note,
    groups: (groups || []).map(({ id: gid }) => groupsCatalog?.[gid]?.name ?? gid).join(', '),
    approved,
    latest,
  })), [proofs]);

  return (
    <div>
      {handling ? <Waiting fullscreen /> : null}
      {(proof !== undefined) ? (
        <ProofModal
          onClose={() => setProof()}
          id={proof}
        />
      ) : null}
      {copyProof ? (
        <Dialog
          open
          fullWidth
          maxWidth="xs"
          onClose={() => setCopyProof()}
        >
          <DialogTitle
            title="Sao chép danh sách bộ minh chứng"
            onClose={() => setCopyProof()}
          />
          <DialogContent dividers>
            <TextField
              required
              fullWidth
              type="number"
              label="Năm nhận danh sách BMC"
              value={copyProof}
              onChange={(e) => setCopyProof(e.target.value || 1)}
              inputProps={{
                thousandSeparator: false,
              }}
            />
          </DialogContent>
          <DialogActions>
            <Button
              color="primary"
              onClick={() => {
                if (!copyProof) {
                  Notification.warn('Năm không được trống hoặc khác 0.');
                  return;
                }
                if (copyProof === year) {
                  Notification.warn('Phải khác năm hiện tại.');
                  return;
                }

                dispatch(handleUpdateProof(
                  Object.entries(proofs).map(
                    ([id, values]) => {
                      const info = values;
                      unset(info, 'unit');
                      unset(info, 'allocFiles');
                      unset(info, 'approved');
                      unset(info, 'files');
                      return ({ id, data: info, newYear: copyProof });
                    },
                    proofs,
                  ),
                  () => setCopyProof(),
                ));
              }}
            >
              Sao chép
            </Button>
          </DialogActions>
        </Dialog>
      ) : null}
      {proofs?.[proofID]
        ? (
          <Proof id={proofID} />
        ) : (
          <>
            <AppBar
              position="static"
              style={{
                background: 'white',
                padding: 1,
                boxShadow: 'none',
              }}
            >
              <Toolbar>
                <Box display="flex" width={1}>
                  <Box display="flex" flexGrow={1}>
                    <Box maxWidth={100}>
                      <TextField
                        label="Năm"
                        required
                        fullWidth
                        type="number"
                        inputProps={{
                          thousandSeparator: false,
                          width: 100,
                        }}
                        value={year}
                        onChange={(e) => setYear(e.target.value)}
                      />
                    </Box>
                    <Box ml={1} minWidth={350}>
                      <AutoComplete
                        value={docType}
                        fullWidth
                        onChange={(e, value) => setDocType(value)}
                        options={Object.keys(docTypes)}
                        groupBy={(opt) => categories.find(({ value }) => docTypes?.[opt]?.category === value)?.text ?? ''}
                        getOptionLabel={(value) => docTypes?.[value]?.name ?? value}
                        inputProps={{
                          label: 'Loại văn bản',
                          required: true,
                        }}
                      />
                    </Box>
                  </Box>
                  {isAdmin ? (
                    <Box display="flex" height={40} alignSelf="center">
                      {data.length ? (
                        <Button
                          color="secondary"
                          onClick={() => setCopyProof(year + 1)}
                          style={{ marginRight: 7 }}
                        >
                          Sao chép danh sách BMC
                        </Button>
                      ) : null}
                      {(year && docType) ? (
                        <Button
                          color="secondary"
                          onClick={() => setProof('')}
                        >
                          Thêm bộ minh chứng
                        </Button>
                      ) : null}
                    </Box>
                  ) : null}
                </Box>
              </Toolbar>
            </AppBar>
            {(year && docType) ? (
              <Box m={1} mb={7}>
                <Table
                  columns={columns}
                  data={data}
                  disableGlobalFilter
                  disableGroupBy
                  disableFilters
                  usePagination={false}
                  rowHeight={40}
                  height={window.innerHeight - 250}
                />
              </Box>
            ) : null}
          </>
        )}
    </div>
  );
}

export default Proofs;
