import { SEARCH, SIGN_OUT } from '../actions/constants';

const initialState = {
  handling: false,
};

export default function search(state = initialState, action) {
  const {
    year,
    category,
    query,
    records,
    settings,
  } = action;

  switch (action.type) {
    case SEARCH.handlers.search:
    case SEARCH.handlers.searchByList:
      return {
        ...state,
        year,
        category,
        query,
        handling: true,
      };
    case SEARCH.fetch:
      return {
        ...state,
        records,
        handling: false,
      };
    case SEARCH.handlers.settings:
      return {
        ...state,
        settings,
      };
    case SEARCH.done:
      return {
        ...state,
        handling: false,
      };
    case SEARCH.handlers.clear:
    case SIGN_OUT:
      return initialState;
    default:
      return state;
  }
}
