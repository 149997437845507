import React, { useState, useMemo } from 'react';

import {
  Confirmation,
  AutoComplete,
  Notification,
} from '@xbotvn/react-ui/components';
import {
  Dialog,
  DialogContent,
  DialogActions,
  DialogTitle,
  List,
  ListItem,
  ListItemText,
  Divider,
  Typography,
  Button,
  Grid,
  Icon,
  TextField,
  Box,
} from '@xbotvn/react-ui/core';
import {
  uniq,
  cloneDeep,
  unset,
} from '@xbotvn/utils/collection';
import PropTypes from 'prop-types';

import * as Styles from './styles';

function AutoConfig({
  onClose,
  perform,
  targets,
  rows,
  properties,
}) {
  const [target, setTarget] = useState(targets.length === 1 ? 'allocFiles' : 'docNo');
  const [start, setStart] = useState(1);
  const [prefix, setPrefix] = useState('');
  const [from, setFrom] = useState('');
  const [to, setTo] = useState('');
  const [suffix, setSuffix] = useState('');
  const [count, setCount] = useState();
  const [filters, setFilters] = useState({});
  const [confirm, setConfirm] = useState();

  const listProps = useMemo(() => properties.filter(
    ({ type }) => type === 'list',
  ), [properties]);

  const cachedFilters = useMemo(() => {
    const results = {};
    listProps.forEach(({ code }) => {
      const options = rows.map((obj) => obj?.[code] ?? '');
      results[code] = uniq(options.filter((opt) => opt));
    });
    return results;
  }, [rows, listProps]);

  const {
    name,
  } = useMemo(() => targets.find(({ code }) => code === target) ?? {}, [targets, target]);

  const filteredRows = useMemo(() => {
    if (count && !from && !to) {
      return rows.filter(
        (row) => row.stt >= start && row.stt < (count + start) && Object.entries(filters).every(
          ([code, options]) => options.includes(row?.[code]),
        ),
      );
    }
    return rows.filter(
      (row) => row.stt >= start && Object.entries(filters).every(
        ([code, options]) => options.includes(row?.[code]),
      ),
    );
  }, [
    rows,
    start,
    count,
    from,
    to,
    filters,
    target,
  ]);

  const affectedRows = useMemo(() => {
    const fixedLength = Math.max(...[from.length, to.length]);
    const affected = [];
    let dynamic = start;
    filteredRows.forEach((row, stt) => {
      const suitable = Math.floor((stt + count) / count);
      if (!to || suitable <= parseFloat(to)) {
        let auto = '';
        if (from) {
          dynamic = parseFloat(from) + suitable;
          auto = `${dynamic - 1}`.padStart(fixedLength, '0');
        }
        const value = `${prefix}${auto}${suffix}`;
        affected.push({
          ...row,
          [target]: value,
        });
      }
    });
    return affected;
  }, [
    filteredRows,
    from,
    to,
    count,
    prefix,
    suffix,
    start,
    target,
  ]);

  return (
    <Dialog
      open
      fullWidth
      maxWidth="lg"
      onClose={onClose}
    >
      <DialogTitle
        title="Cấu hình"
        onClose={onClose}
        icon={<Icon>format_list_numbered</Icon>}
      />
      <DialogContent dividers>
        {confirm ? (
          <Confirmation
            title="Xác nhận"
            description="Thực hiện điền thông tin tự động theo cấu hình trên."
            severity="warning"
            onClose={() => setConfirm()}
            primaryAction={() => {
              perform(affectedRows);
            }}
          />
        ) : null}
        <Grid container spacing={2}>
          <Grid item xs={4}>
            <AutoComplete
              value={target}
              fullWidth
              onChange={(e, value) => setTarget(value)}
              options={targets.map(({ code }) => code)}
              getOptionLabel={(opt) => targets.find(({ code }) => code === opt)?.name ?? opt}
              inputProps={{
                label: 'Thông tin',
                required: true,
              }}
            />
          </Grid>
          <Grid item xs={4}>
            <TextField
              label={`STT bắt đầu (1 - ${rows.length})`}
              fullWidth
              variant="outlined"
              value={start}
              onChange={(e) => (e.target.value ? setStart(e.target.value) : setStart(1))}
              type="number"
            />
          </Grid>
          <Grid item xs={4}>
            <TextField
              required
              label={`Số đối tượng/${name || 'Thông tin'}: `}
              fullWidth
              variant="outlined"
              placeholder="Tối thiểu phải là 1"
              value={count}
              onChange={(e) => setCount(e.target.value)}
              type="number"
            />
          </Grid>
          <Grid item xs={3}>
            <TextField
              label="Tiền tố"
              fullWidth
              variant="outlined"
              value={prefix}
              onChange={(e) => setPrefix(e.target.value)}
            />
          </Grid>
          <Grid item xs={3}>
            <TextField
              label="Bắt đầu"
              fullWidth
              variant="outlined"
              value={from}
              onChange={(e) => setFrom(e.target.value)}
            />
          </Grid>
          <Grid item xs={3}>
            <TextField
              label="Kết thúc"
              fullWidth
              variant="outlined"
              value={to}
              onChange={(e) => setTo(e.target.value)}
            />
          </Grid>
          <Grid item xs={3}>
            <TextField
              label="Hậu tố"
              fullWidth
              variant="outlined"
              value={suffix}
              onChange={(e) => setSuffix(e.target.value)}
            />
          </Grid>
          <Grid item xs={Object.keys(filters).length ? 5 : 12}>
            <AutoComplete
              value={Object.keys(filters)}
              multiple
              fullWidth
              onChange={(e, value) => setFilters((prevFilters) => {
                const cloned = cloneDeep(prevFilters);
                value.forEach((c) => {
                  if (!cloned[c]) {
                    cloned[c] = [];
                  }
                });
                Object.keys(prevFilters).forEach((c) => {
                  if (!value.includes(c)) {
                    unset(cloned, c);
                  }
                });
                return cloned;
              })}
              options={listProps.map(({ code }) => code)}
              getOptionLabel={(opt) => listProps.find(({ code }) => code === opt)?.name ?? opt}
              inputProps={{
                label: 'Lọc',
              }}
            />
            {Object.keys(filters).length ? (
              <Box display="flex" flexDirection="column">
                {Object.entries(filters).map(([code, selected]) => (
                  <Box>
                    <AutoComplete
                      value={selected}
                      multiple
                      fullWidth
                      onChange={(e, value) => setFilters((prevFilters) => {
                        const cloned = cloneDeep(prevFilters);
                        cloned[code] = value;
                        return cloned;
                      })}
                      options={cachedFilters?.[code] ?? []}
                      inputProps={{
                        label: listProps.find(({ code: pcode }) => pcode === code)?.name ?? code,
                      }}
                    />
                    <Divider />
                  </Box>
                ))}
              </Box>
            ) : null}
          </Grid>
          <Grid item xs={Object.keys(filters).length ? 7 : 12}>
            <Typography>Kết quả</Typography>
            <List>
              <ListItem>
                <ListItemText>STT</ListItemText>
                <ListItemText>Họ và tên</ListItemText>
                <ListItemText>{name || 'Thông tin'}</ListItemText>
              </ListItem>
              {affectedRows?.[0] ? (
                <ListItem>
                  <Styles.ItemText width={27}>{affectedRows?.[0]?.stt ?? ''}</Styles.ItemText>
                  <Styles.ItemText width={30}>{affectedRows?.[0]?.fullName ?? ''}</Styles.ItemText>
                  <Styles.ItemText width={30}>{affectedRows?.[0]?.[target] ?? ''}</Styles.ItemText>
                </ListItem>
              ) : null}
              {affectedRows.length ? (
                <ListItem>
                  <ListItemText>...</ListItemText>
                </ListItem>
              ) : null}
              {(affectedRows.length > 1) ? (
                <ListItem>
                  <Styles.ItemText width={27}>{affectedRows?.[affectedRows.length - 1]?.stt ?? ''}</Styles.ItemText>
                  <Styles.ItemText width={30}>{affectedRows?.[affectedRows.length - 1]?.fullName ?? ''}</Styles.ItemText>
                  <Styles.ItemText width={30}>{affectedRows?.[affectedRows.length - 1]?.[target] ?? ''}</Styles.ItemText>
                </ListItem>
              ) : null}
            </List>
          </Grid>
        </Grid>
      </DialogContent>
      <DialogActions>
        <Button
          color="primary"
          onClick={() => {
            if (!count) {
              Notification.warn('Tối thiểu phải là 1.');
              return;
            }
            if (from && to && from > to) {
              Notification.warn('Bắt đầu phải nhỏ hơn Kết thúc.');
              return;
            }
            setConfirm(true);
          }}
        >
          Thực hiện
        </Button>
      </DialogActions>
    </Dialog>
  );
}

AutoConfig.propTypes = {
  onClose: PropTypes.func.isRequired,
  perform: PropTypes.func.isRequired,
  targets: PropTypes.arrayOf(PropTypes.shape({
    code: PropTypes.string.isRequired,
    name: PropTypes.string.isRequired,
    isProperty: PropTypes.bool,
  })).isRequired,
  rows: PropTypes.arrayOf(PropTypes.object).isRequired,
  properties: PropTypes.arrayOf(PropTypes.object).isRequired,
};

export default AutoConfig;
