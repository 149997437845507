import { Notification } from '@xbotvn/react-ui/components';
import { unset, clone } from '@xbotvn/utils/collection';
import axios from 'axios';
import {
  put,
  call,
  takeEvery,
  all,
  select,
} from 'redux-saga/effects';

import { BACKEND } from '../../config';
import { graphQLCaller, callAPI } from '../../libs/backend';
import { CATALOGS } from './constants';

function* querySystem(id) {
  yield put({
    type: CATALOGS.handlers.system,
    id,
  });
}

function* updateSystem(id, data = {}) {
  yield put({
    type: CATALOGS.system,
    id,
    data,
  });
}

function* queryApp(id) {
  yield put({
    type: CATALOGS.handlers.app,
    id,
  });
}

function* updateApp(id, data = {}) {
  yield put({
    type: CATALOGS.app,
    id,
    data,
  });
}

export function* queryCategories(unitID) {
  try {
    yield* querySystem('categories');
    const categories = yield call(() => new Promise((resolve, reject) => {
      axios.post(`${BACKEND}/api/categories`, { unitID }).then((response) => {
        const { status, data } = response;
        if (status === 200 && data) resolve(data);
        else reject(new Error(status));
      }).catch((err) => reject(err));
    }));
    yield* updateSystem('categories', Object.entries(categories).map(([value, text]) => ({ value, text }), categories));
  } catch ({ message }) {
    yield* updateSystem('categories');
    Notification.error(message);
  }
}

export function* queryCities() {
  try {
    yield* querySystem('cities');
    const cities = yield callAPI('api/cities');
    yield* updateSystem('cities', cities || {});
  } catch ({ message }) {
    yield* updateSystem('cities');
    Notification.error(message);
  }

  try {
    yield* querySystem('product');
    const product = yield callAPI('api/product');
    yield* updateSystem('product', product || {});
  } catch ({ message }) {
    Notification.error(message);
    yield* updateSystem('product', {});
  }
}

export function* queryCatalogs(unitID) {
  yield* queryApp('docTypes');
  try {
    const { docTypes } = yield graphQLCaller('catalogs', `{
      docTypes(unitID: "${unitID}") {
        id
        category
        name
        properties {
          code
          name
          type
          required
          public
          order
          importColumn
        }
        importRow
        importColumn
      }
    }`);
    const obj = {};
    (docTypes || []).forEach((docType) => {
      const { id, ...rest } = docType;
      obj[id] = rest;
    });
    yield* updateApp('docTypes', obj);
  } catch ({ message }) {
    yield* updateApp('docTypes');
    Notification.error(message);
  }
}

export function* updateDocType({ id, data, onComplete }) {
  const { id: unitID } = (yield select())?.user?.unit ?? {};
  const doctypes = clone((yield select())?.catalogs?.app?.docTypes?.data ?? {});

  if (unitID) {
    let actualID = id;
    try {
      if (id === '') {
        const { insertDocType } = yield graphQLCaller(
          'catalogs',
          `
            mutation insertDocType($unitID: String!, $docType: DocTypeInput!) {
              insertDocType(unitID: $unitID, docType: $docType) {
                id
              }
            }
          `,
          {
            unitID,
            docType: data,
          },
        );
        actualID = insertDocType?.id ?? '';
      } else {
        yield graphQLCaller(
          'catalogs',
          `
            mutation updateDocType($unitID: String!, $log: String!, $id: ID!, $docType: DocTypeInput) {
              updateDocType(unitID: $unitID, log: $log, id: $id, docType: $docType)
            }
          `,
          {
            unitID,
            log: doctypes?.[id]?.name ?? id,
            id,
            docType: data,
          },
        );
      }
      if (data) doctypes[actualID] = data;
      else unset(doctypes, actualID);
      yield* updateApp('docTypes', doctypes);
      Notification.success('Cập nhật cấu hình thành công.', { action: onComplete });
    } catch ({ message }) {
      yield* updateApp('docTypes', doctypes);
      Notification.error(message, { action: () => onComplete(message) });
    }
  } else {
    yield* updateApp('docTypes', doctypes);
  }
}

export const handleUpdateDocType = (id, data, onComplete) => ({
  type: CATALOGS.handlers.doctype,
  id,
  data,
  onComplete,
});

export default function* saga() {
  yield all([
    yield takeEvery(CATALOGS.handlers.doctype, updateDocType),
  ]);
}
