import React, { useState } from 'react';

import { Waiting, AutoComplete, Notification } from '@xbotvn/react-ui/components';
import {
  Dialog,
  DialogContent,
  DialogTitle,
  ListItemText,
  TextField,
  Grid,
  FormControl,
  FormLabel,
  List,
  ListItem,
  Button,
  Checkbox,
  ListItemIcon,
  DialogActions,
} from '@xbotvn/react-ui/core';
import { uniq } from '@xbotvn/utils/collection';
import PropTypes from 'prop-types';

import { UNITTYPES } from '../../../config';
import { graphQLCaller } from '../../../libs/backend';
import Address from './Address';

function Unit({ data, onClose }) {
  const [type, setType] = useState(data.type);
  const [cities, setCities] = useState({
    province: data.province || '',
    district: data.district || '',
  });
  const [name, setName] = useState(data.name);
  const [address, setAddress] = useState(data?.address ?? '');
  const [staffs, setStaffs] = useState((data?.staffs ?? []).join(', '));
  const [admins, setAdmins] = useState(data?.admins ?? []);
  const [waiting, setWaiting] = useState(false);

  const extractEmails = (str = '') => uniq(str.split(',').map((e) => e.replace(/ /g, ''))).filter((e) => e);

  return (
    <Dialog
      open
      maxWidth="sm"
      fullWidth
      onClose={onClose}
    >
      <DialogTitle
        title="Thông tin đơn vị"
        onClose={onClose}
      />
      <DialogContent dividers>
        {waiting ? <Waiting fullscreen /> : null}
        <Grid container spacing={2}>
          <Grid item xs={6}>
            <TextField
              label="Tên đơn vị"
              required
              value={name}
              fullWidth
              variant="outlined"
              onChange={(e) => setName(e.target.value)}
            />
          </Grid>
          <Grid item xs={6}>
            <AutoComplete
              value={type || null}
              options={Object.keys(UNITTYPES)}
              getOptionLabel={(value) => UNITTYPES?.[value].name}
              onChange={(e, value) => setType(value)}
              inputProps={{
                label: 'Loại Đơn Vị',
                placeholder: 'Chọn Loại Đơn Vị',
              }}
            />
          </Grid>
          {type ? (
            <Grid item xs={12}>
              <Address
                level={UNITTYPES?.[type]?.level ?? ''}
                value={cities}
                onChange={(val) => setCities(val)}
              />
            </Grid>
          ) : null}
          <Grid item xs={12}>
            <TextField
              label="Địa chỉ"
              required
              value={address}
              fullWidth
              variant="outlined"
              onChange={(e) => setAddress(e.target.value)}
            />
          </Grid>
          <Grid item xs={12}>
            <TextField
              label="Nhân sự (sách nhau bởi dưới ',')"
              required
              value={staffs}
              fullWidth
              multiline
              variant="outlined"
              onChange={(e) => setStaffs(e.target.value || '')}
            />
          </Grid>
          <Grid item xs={12}>
            <FormControl>
              <FormLabel>Quyền quản trị</FormLabel>
              <List>
                {extractEmails(staffs).map((e) => (
                  <ListItem key={e}>
                    <ListItemIcon>
                      <Checkbox
                        checked={admins.includes(e)}
                        onChange={() => setAdmins((prevAdmins) => {
                          if (prevAdmins.includes(e)) {
                            return prevAdmins.filter((email) => email !== e);
                          }
                          return [
                            ...prevAdmins,
                            e,
                          ];
                        })}
                      />
                    </ListItemIcon>
                    <ListItemText>{e}</ListItemText>
                  </ListItem>
                ))}
              </List>
            </FormControl>
          </Grid>
        </Grid>
      </DialogContent>
      <DialogActions>
        <Button
          color="primary"
          onClick={async () => {
            if (!name) {
              Notification.warn('Tên đơn vị không được bỏ trống.');
              return;
            }
            setWaiting(true);
            const newStaffs = extractEmails(staffs);
            try {
              await graphQLCaller(
                'units',
                `
                  mutation updateUnitInformation($id: ID!, $information: UnitInput!) {
                    updateUnitInformation(id: $id, information: $information)
                  }
                `,
                {
                  id: data.id,
                  information: {
                    admins: admins.filter((e) => newStaffs.includes(e)),
                    staffs: newStaffs,
                    type,
                    ...cities,
                    address,
                    name,
                  },
                },
              );
              setWaiting(false);
              onClose(true);
            } catch ({ message }) {
              setWaiting(false);
              Notification.warn(message);
            }
          }}
        >
          Cập nhật
        </Button>
      </DialogActions>
    </Dialog>
  );
}

Unit.propTypes = {
  onClose: PropTypes.func.isRequired,
  data: PropTypes.shape({
    id: PropTypes.string.isRequired,
    name: PropTypes.string.isRequired,
    address: PropTypes.string,
    type: PropTypes.string,
    province: PropTypes.string,
    district: PropTypes.string,
    staffs: PropTypes.arrayOf(PropTypes.string),
    admins: PropTypes.arrayOf(PropTypes.string),
  }),
};

Unit.defaultProps = {
  data: {},
};

export default Unit;
