import React from 'react';

import PropTypes from 'prop-types';

import EsoftSearch from './EsoftSearch';
import SteduSearch from './SteduSearch';
import XbotSearch from './XbotSearch';

function Search({
  company,
  props,
}) {
  switch (company) {
    case 'STEDU':
      return <SteduSearch {...props} />;
    case 'ESOFT':
      return <EsoftSearch {...props} />;
    default:
      return <XbotSearch {...props} />;
  }
}

Search.propTypes = {
  company: PropTypes.string.isRequired,
  props: PropTypes.object.isRequired,
};

export default Search;
