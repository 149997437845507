import React, { useState } from 'react';

import {
  Waiting,
  DatePickerInput,
  Notification,
} from '@xbotvn/react-ui/components';
import {
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  Grid,
  Button,
  Icon,
  Chip,
  TextField,
  Typography,
} from '@xbotvn/react-ui/core';
import {
  unset,
} from '@xbotvn/utils/collection';
import {
  format,
} from '@xbotvn/utils/date';
import PropTypes from 'prop-types';
import Dropzone from 'react-dropzone';
import { useSelector, useDispatch } from 'react-redux';

import { handleInsertRealloc } from '../../redux/actions/reallocs';

function Realloc({
  record,
  onClose,
  id,
}) {
  const dispatch = useDispatch();
  const {
    email,
    year,
    docType,
    recordInitial,
    handling,
  } = useSelector(({
    user,
    proofs,
    records,
    search,
    reallocs,
  }) => ({
    email: user.email,
    year: search.year || proofs.year,
    recordInitial: records?.data?.[id] ?? {},
    docType: records.docType,
    handling: reallocs.handling ?? false,
  }));

  const [data, setData] = useState({
    record: id,
    issuer: email,
  });
  const [attachment, setAttachment] = useState();

  const info = record || recordInitial;

  const checkType = (value) => {
    if (!value) return '';
    if (typeof value === 'number') return format(value);
    return value;
  };

  return (
    <>
      {handling ? <Waiting fullscreen /> : null}
      <Dialog
        onClose={onClose}
        open
        maxWidth="md"
        fullWidth
      >
        <DialogTitle
          title="Cấp phát bản sao"
          onClose={onClose}
        />
        <DialogContent dividers>
          <Grid container spacing={2}>
            <Grid item xs={6}>
              <TextField
                disabled
                fullWidth
                value={info?.fullName ?? ''}
                variant="outlined"
                label="Họ tên"
              />
            </Grid>
            <Grid item xs={6}>
              <TextField
                disabled
                fullWidth
                value={checkType(info.birthDay)}
                variant="outlined"
                label="Ngày sinh"
              />
            </Grid>
            <Grid item xs={6}>
              <TextField
                disabled
                fullWidth
                value={year}
                variant="outlined"
                label="Khoá thi"
              />
            </Grid>
            <Grid item xs={6}>
              <TextField
                disabled
                fullWidth
                value={info?.school ?? ''}
                variant="outlined"
                label="Học sinh trường"
              />
            </Grid>
            <Grid item xs={6}>
              <TextField
                disabled
                fullWidth
                value={info?.examBoard ?? ''}
                variant="outlined"
                label="Hội đồng thi"
              />
            </Grid>
            <Grid item xs={6}>
              <TextField
                disabled
                fullWidth
                value={info?.docNo ?? ''}
                variant="outlined"
                label="Số hiệu văn bằng"
              />
            </Grid>
            <Grid item xs={6}>
              <TextField
                disabled
                fullWidth
                value={info?.inputNo ?? ''}
                variant="outlined"
                label="Số vào sổ"
              />
            </Grid>
            <Grid item xs={6}>
              <DatePickerInput
                placeholder=""
                inputProps={{
                  required: true,
                  fullWidth: true,
                  variant: 'outlined',
                  label: 'Ngày cấp bản sao',
                }}
                style={{ width: '100%' }}
                value={format(data.issueDate)}
                onDayChange={(value) => {
                  if (value) {
                    setData((prev) => ({
                      ...prev,
                      issueDate: new Date(value).getTime(),
                    }));
                  }
                }}
              />
            </Grid>
            <Grid item xs={6}>
              <TextField
                fullWidth
                multiline
                variant="outlined"
                label="Lý do"
                value={data?.note ?? ''}
                onChange={(value) => setData((prev) => ({
                  ...prev,
                  note: value.target.value,
                }))}
              />
            </Grid>
            <Grid item xs={6}>
              <TextField
                required
                fullWidth
                variant="outlined"
                label="Số hiệu bản sao"
                value={data?.issueNo ?? ''}
                onChange={(value) => setData((prev) => ({
                  ...prev,
                  issueNo: value.target.value,
                }))}
              />
            </Grid>
            <Grid item xs={6} />
            <Grid item xs={6}>
              <Typography>Tải minh chứng yêu cầu bản sao</Typography>
              {attachment ? (
                <Chip
                  clickable
                  label={data?.attachment ?? ''}
                  onDelete={() => {
                    setAttachment();
                    setData((prev) => ({
                      ...prev,
                      attachment: '',
                    }));
                  }}
                />
              ) : null}
            </Grid>
          </Grid>
        </DialogContent>
        <DialogActions>
          <Dropzone
            multiple
            onDrop={(selected) => {
              if (selected.length) {
                setAttachment(selected);
                setData((prev) => ({
                  ...prev,
                  attachment: selected[0].name,
                }));
              }
            }}
          >
            {({ getRootProps, getInputProps }) => (
              <div {...getRootProps()}>
                <input {...getInputProps()} />
                <Button
                  color="secondary"
                  startIcon={<Icon>cloud_upload</Icon>}
                  onClick={() => { }}
                >
                  Tải file
                </Button>
              </div>
            )}
          </Dropzone>
          <Button
            color="primary"
            onClick={() => {
              if (!data?.issueDate) {
                Notification.warn('Ngày cấp bản sao không được trống.');
                return;
              }
              if (!data?.issueNo) {
                Notification.warn('Số hiệu bản sao không được trống.');
                return;
              }
              data.docType = info?.docType ?? docType;
              unset(info, 'search');
              unset(info, 'docType');
              data.properties = [];
              data.properties.push({
                code: 'year',
                value: `${year}`,
              });
              Object.entries(info).forEach(([code, value]) => {
                data.properties.push({
                  code,
                  value: `${value}`,
                });
              });
              dispatch(handleInsertRealloc(data, attachment, (err) => {
                if (!err) onClose();
              }));
            }}
          >
            Lưu
          </Button>
        </DialogActions>
      </Dialog>
    </>
  );
}

Realloc.propTypes = {
  onClose: PropTypes.func.isRequired,
  id: PropTypes.string.isRequired,
  record: PropTypes.object.isRequired,
};

export default Realloc;
