import React, { useCallback, useState } from 'react';

import { Waiting, Notification } from '@xbotvn/react-ui/components';
import {
  Chip,
} from '@xbotvn/react-ui/core';
import { saveAs } from 'file-saver';
import PropTypes from 'prop-types';
import { useSelector } from 'react-redux';

import { callFileAPI } from '../../libs/backend';

function ReportTag({
  onRemove,
  name,
  template,
}) {
  const unitID = useSelector(({ user }) => user?.unit?.id ?? '');

  const [downloading, setDownloading] = useState(false);
  const download = useCallback(() => {
    if (template) {
      setDownloading(true);
      callFileAPI('reports/download', {
        unitID,
        template,
      }, true).then((data) => {
        setDownloading(false);
        saveAs(new Blob([data]), template);
      }).catch(({ message }) => {
        setDownloading(false);
        Notification.warn(`Tải file không thành công: ${message}`);
      });
    }
  }, [template]);

  return (
    <>
      {downloading ? <Waiting fullscreen /> : null}
      <Chip
        style={{ marginRight: 5 }}
        color="secondary"
        key={name}
        label={name}
        onDelete={onRemove}
        clickable
        onClick={download}
      />
    </>
  );
}

ReportTag.propTypes = {
  onRemove: PropTypes.func,
  name: PropTypes.string.isRequired,
  template: PropTypes.string,
};

ReportTag.defaultProps = {
  onRemove: undefined,
  template: '',
};

export default ReportTag;
