import { Notification } from '@xbotvn/react-ui/components';
import { range } from '@xbotvn/utils/collection';
import { format } from '@xbotvn/utils/date';
import { toASCII } from '@xbotvn/utils/string';
import {
  put,
  takeLatest,
  all,
  select,
} from 'redux-saga/effects';
import * as XLSX from 'xlsx';

import { DPROPS } from '../../config';
import { graphQLCaller } from '../../libs/backend';
import { stringToValue } from '../../libs/utils';
import { SEARCH } from './constants';

function* update(records = {}) {
  yield put({
    type: SEARCH.fetch,
    records,
  });
}

const arrToCollection = (records = [], docTypes) => {
  const result = {};
  records.forEach(({
    id,
    properties,
    docType,
    ...rest
  }) => {
    result[id] = { docType, ...rest };
    if (properties) {
      (docTypes?.[docType]?.properties ?? DPROPS).forEach(({ code, type, public: public_ }) => {
        const value = properties.find(({ code: pcode }) => pcode === code)?.value ?? '';
        if (value && public_) {
          result[id][code] = stringToValue(value, type);
        }
      });
    }
  });
  return result;
};

function* queryRecords({
  year,
  category,
  query,
  isRangeYears,
  school,
}) {
  const { id } = (yield select())?.user?.unit ?? {};
  const docTypes = (yield select())?.catalogs?.app?.docTypes?.data ?? {};
  if (id && year && category && query) {
    const pYear = parseFloat(year);
    let years = [pYear];
    let newRecords = [];
    if (isRangeYears) {
      years = [...range(pYear - 3, pYear), ...range(pYear, pYear + 4)];
    }
    try {
      yield* years.map(
        function* queryRecord(value) {
          const { query: records } = yield graphQLCaller('records', `{
            query(unitID: "${id}", year: ${value}, category: "${category}", query: "${toASCII(query)}", school: "${school.trim()}") {
              id
              search
              docType
              properties {
                code
                value
              }
              file
              proof
              note {
                content
                files
              }
              allocFiles
            }
          }`);
          newRecords = [...newRecords, ...records];
        },
      );
      yield* update(arrToCollection(
        newRecords.filter(({ docType }) => Object.keys(docTypes).includes(docType)),
        docTypes,
      ));
    } catch ({ message }) {
      Notification.error(message);
      yield* update();
    }
  } else yield update();
}

function* queryRecordsByList({ list, category }) {
  const { id } = (yield select())?.user?.unit ?? {};
  const visibleProps = DPROPS.filter(({ code, public: show }) => show && (code !== 'docNo'));
  const results = [[
    'Năm',
    'Số bằng',
    ...visibleProps.map(({ name }) => name),
  ]];
  try {
    yield* list.map(function* getRecord(condition) {
      const { 0: year, 1: docNo } = condition;
      const { query: records } = yield graphQLCaller('records', `{
        query(unitID: "${id}", year: ${year}, category: "${category}", query: "${toASCII((docNo.replace(/\s/g, '')))}") {
          id
          search
          docType
          properties {
            code
            value
          }
          file
          proof
          note {
            content
            files
          }
          allocFiles
        }
      }`);
      records.forEach((rc) => {
        const record = [
          year,
          docNo,
          ...visibleProps.map(({ code, type }) => {
            const value = (rc?.properties ?? []).find(({ code: pcode }) => pcode === code)?.value ?? '';
            if (type === 'date') {
              return format(parseFloat(value));
            }
            return stringToValue(value, type);
          }),
        ];
        results.push(record);
      });
    });
    const wb = XLSX.utils.book_new();
    XLSX.utils.book_append_sheet(
      wb,
      XLSX.utils.aoa_to_sheet(results),
      'Danh sách',
    );
    yield put({ type: SEARCH.done });
    XLSX.writeFile(wb, 'Kết quả tra cứu.xlsx');
  } catch ({ message }) {
    Notification.error(message);
    yield* update();
  }
}

export const handleQuery = (
  year,
  category,
  query,
  isRangeYears,
  school,
) => ({
  type: SEARCH.handlers.search,
  year,
  category,
  query,
  isRangeYears,
  school,
});

export const handleListQuery = (
  list,
  category,
) => ({
  type: SEARCH.handlers.searchByList,
  list,
  category,
});

export const handleClearSearch = () => ({
  type: SEARCH.handlers.clear,
});

export default function* saga() {
  yield all([
    yield takeLatest(SEARCH.handlers.search, queryRecords),
    yield takeLatest(SEARCH.handlers.searchByList, queryRecordsByList),
  ]);
}
