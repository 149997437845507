import { RECORDS, SIGN_OUT } from '../actions/constants';

const initialState = {};

export default function records(state = initialState, action) {
  const {
    type,
    year,
    docType,
    proof,
    properties,
    data,
  } = action;

  switch (type) {
    case RECORDS.handlers.get:
      return {
        year,
        docType,
        proof,
        properties,
        data: {},
        handling: true,
      };
    case RECORDS.handlers.import:
    case RECORDS.handlers.update:
    case RECORDS.handlers.clear:
    case RECORDS.handlers.record:
      return {
        ...state,
        handling: true,
      };
    case RECORDS.update:
      return {
        ...state,
        data: {
          ...(state?.data ?? {}),
          ...data,
        },
        handling: false,
      };
    case SIGN_OUT:
      return initialState;
    default:
      return state;
  }
}
