import { getUniqueKey, cloneDeep } from '@xbotvn/utils/collection';
import { isMatch, parse, format as formatDate } from '@xbotvn/utils/date';
import { excelColToInt, intToExcelCol } from 'excel-column-name';
import XLSX from 'xlsx';

import { convertName } from '../../libs/utils';

const getRecords = (
  fileID,
  binaryData,
  properties,
  startRow = 1,
  colFile,
) => {
  const wb = XLSX.read(binaryData, { type: 'binary', cellDates: true, cellText: false });
  const headers = {};
  const types = {};
  const requireds = {};
  const names = {};
  properties.forEach(({
    code,
    importColumn,
    type,
    required,
    name,
  }) => {
    if (importColumn) { headers[code] = excelColToInt(importColumn); }
    types[code] = type;
    if (required) { requireds[code] = true; }
    names[code] = name;
  });
  if (colFile) {
    headers.file = excelColToInt(colFile);
    types.file = 'string';
    names.file = 'Minh Chứng';
  }
  const data = XLSX.utils.sheet_to_json(
    wb.Sheets[wb.SheetNames[0]],
    {
      header: 'A',
      skipHeader: true,
      range: startRow - 1,
      raw: false,
      dateNF: 'dd/mm/yyyy',
    },
  );
  const records = {};
  data.forEach((record) => {
    const id = getUniqueKey(Object.keys(records), 'new');
    records[id] = {
      importFrom: fileID,
    };
    const errors = [];
    Object.entries(headers).forEach(([code, col]) => {
      const columnLetter = intToExcelCol(col);
      const value = record?.[columnLetter] ?? '';
      let prettyValue = code.toLowerCase().indexOf('name') !== -1 ? convertName(value) : value;
      if (prettyValue) {
        let valid = true;
        switch (types[code]) {
          case 'date':
            if (isMatch(prettyValue)) prettyValue = parse(prettyValue).getTime();
            else valid = false;
            break;
          case 'number':
            if (isNaN(prettyValue)) valid = false;
            else prettyValue = parseFloat(prettyValue);
            break;
          case 'bool':
            prettyValue = prettyValue !== '';
            break;
          default:
        }
        if (!valid) errors.push(`${names[code]} sai định dạng`);
      }
      if (code === 'file' && !value) {
        prettyValue = value;
      }
      if (requireds[code] && prettyValue === '') { errors.push(`${names[code]} là thông tin bắt buộc`); }
      if (errors) records[id].errors = errors.join('; ');
      records[id][code] = prettyValue;
    });
  });
  return records;
};

const formatValue = ({ records, format }) => {
  const newRecords = {};
  const errors = [];
  Object.entries(records).forEach(([id, record]) => {
    const newRecord = cloneDeep(record);
    switch (format.typeTo) {
      case 'date':
        if (isMatch(newRecord?.[format.code] ?? '')) {
          newRecords[id] = {
            code: format.code,
            value: `${parse(newRecord?.[format.code] ?? '').getTime()}`,
          };
        } else {
          errors.push(newRecord);
        }
        break;
      case 'string':
        newRecords[id] = {
          code: format.code,
          value: formatDate(parseFloat(newRecord?.[format.code] ?? '')),
        };
        break;
      default:
    }
  });
  return {
    newRecords: Object.entries(newRecords).map(
      ([id, prop]) => ({ id, properties: [prop] }),
    ),
    errors,
  };
};

export {
  getRecords,
  formatValue,
};
