import React, {
  useMemo,
  useState,
} from 'react';

import {
  AutoComplete,
  DatePickerInput,
  Notification,
} from '@xbotvn/react-ui/components';
import {
  Dialog,
  DialogContent,
  DialogTitle,
  DialogActions,
  Button,
  TextField,
  Grid,
} from '@xbotvn/react-ui/core';
import {
  format,
} from '@xbotvn/utils/date';
import PropTypes from 'prop-types';
import { useSelector } from 'react-redux';

import * as Styles from './styles';

function Information({
  onSubmit,
  onClose,
  data,
}) {
  const {
    unit,
    pgds,
  } = useSelector(({ user }) => ({
    pgds: user?.unit?.pgds ?? [],
    unit: user?.unit ?? {},
  }));

  const [certificates, setCertificates] = useState(data);

  const options = useMemo(() => {
    switch (certificates?.schoolLevel) {
      case 'thpt': return [unit];
      case 'thcs': return pgds;
      default: return [];
    }
  }, [certificates]);

  return (
    <Dialog
      open
      fullWidth
      maxWidth="md"
      onClose={onClose}
    >
      <DialogTitle
        onClose={onClose}
        title={`${data?.id ? 'Chỉnh sửa thông tin' : 'Thêm'} phôi bằng`}
      />
      <DialogContent dividers>
        <Grid container spacing={4}>
          <Grid item xs={3}>
            <TextField
              required
              disabled
              fullWidth
              label="Năm:"
              value={data.year}
            />
          </Grid>
          <Grid item xs={3}>
            <AutoComplete
              fullWidth
              value={certificates?.schoolLevel ?? ''}
              options={['thcs', 'thpt']}
              getOptionLabel={(val) => `${val}`}
              onChange={(e, value) => setCertificates((prev) => ({
                ...prev,
                schoolLevel: value,
                unit: value === 'thpt' ? unit.id : '',
              }))}
              inputProps={{
                label: 'Bậc học:',
                required: true,
              }}
            />
          </Grid>
          <Grid item xs={3}>
            <AutoComplete
              fullWidth
              disabled={!certificates.schoolLevel}
              value={certificates?.unit ?? ''}
              options={options.map(({ id }) => id)}
              getOptionLabel={(val) => [unit, ...pgds].find(({ id }) => id === val)?.name ?? val}
              onChange={(e, value) => setCertificates((prev) => ({
                ...prev,
                unit: value,
              }))}
              inputProps={{
                label: 'Đơn vị:',
                required: true,
              }}
            />
          </Grid>
          <Styles.GroupDatePicker item xs={3} position="fixed">
            <DatePickerInput
              value={format(certificates?.createDate)}
              onDayChange={(value) => {
                if (value) {
                  setCertificates((prev) => ({
                    ...prev,
                    createDate: value.getTime(),
                  }));
                }
              }}
              inputProps={{
                required: true,
                fullWidth: true,
                label: 'Ngày cấp:',
              }}
            />
          </Styles.GroupDatePicker>
        </Grid>
        <Grid container spacing={4}>
          <Grid item xs={3}>
            <TextField
              fullWidth
              label="Tiền tố:"
              value={certificates?.prefix ?? ''}
              onChange={(e) => setCertificates((prev) => ({
                ...prev,
                prefix: e.target.value,
              }))}
            />
          </Grid>
          <Grid item xs={3}>
            <TextField
              required
              fullWidth
              type="number"
              label="Bắt đầu:"
              value={certificates?.from ?? 0}
              inputProps={{
                allowNegative: false,
                decimalSeparator: false,
              }}
              onChange={(e) => setCertificates((prev) => ({
                ...prev,
                from: e.target.value,
              }))}
            />
          </Grid>
          <Grid item xs={3}>
            <TextField
              required
              fullWidth
              type="number"
              label="Kết thúc:"
              value={certificates?.to ?? 0}
              inputProps={{
                allowNegative: false,
                decimalSeparator: false,
              }}
              onChange={(e) => setCertificates((prev) => ({
                ...prev,
                to: e.target.value,
              }))}
            />
          </Grid>
          <Grid item xs={3}>
            <TextField
              fullWidth
              label="Hậu tố:"
              value={certificates?.suffix ?? ''}
              onChange={(e) => setCertificates((prev) => ({
                ...prev,
                suffix: e.target.value,
              }))}
            />
          </Grid>
        </Grid>
      </DialogContent>
      <DialogActions>
        <Button
          color="primary"
          onClick={() => {
            const {
              schoolLevel,
              unit: cUnit,
              createDate,
              from,
              to,
              year,
              cancels,
              suffix,
              prefix,
            } = certificates;
            if (!schoolLevel) {
              Notification.warn('Bậc học không được trống.');
              return;
            }
            if (!cUnit) {
              Notification.warn('Đơn vị không được trống.');
              return;
            }
            if (!createDate) {
              Notification.warn('Ngày cấp không được trống.');
              return;
            }
            if (new Date(createDate).getFullYear() !== year) {
              Notification.warn('Ngày cấp phải thuộc năm được chọn.');
              return;
            }
            if (!from) {
              Notification.warn('Bắt đầu phải lớn hơn 0.');
              return;
            }
            if (!to) {
              Notification.warn('Kết thúc phải lớn hơn hoặc bằng bắt đầu.');
              return;
            }
            if (from > to) {
              Notification.warn('Bắt đầu phải nhỏ hơn Kết thúc.');
              return;
            }
            const find = (cancels || []).find(({ serial }) => serial >= to);
            if (find) {
              Notification.warn(`Tồn tại phôi huỷ có số hiệu lớn hơn số kết thúc - ${prefix || ''}${find.serial}${suffix || ''}!`);
              return;
            }
            const msg = `${certificates.id ? 'Cập nhật' : 'Thêm mới'} bộ phôi bằng thành công.`;
            onSubmit(certificates, msg);
          }}
        >
          {data?.id ? 'Lưu' : 'Thêm'}
        </Button>
        <Button
          color="secondary"
          onClick={onClose}
        >
          Đóng
        </Button>
      </DialogActions>
    </Dialog>
  );
}

Information.propTypes = {
  onClose: PropTypes.func.isRequired,
  onSubmit: PropTypes.func.isRequired,
  data: PropTypes.object,
};

Information.defaultProps = {
  data: {},
};

export default Information;
