import { combineReducers } from 'redux';

import catalogs from './catalogs';
import proofs from './proofs';
import reallocs from './reallocs';
import records from './records';
import search from './search';
import settings from './settings';
import user from './user';

const rootReducer = combineReducers({
  user,
  catalogs,
  settings,
  search,
  records,
  proofs,
  reallocs,
});

export default rootReducer;
