import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { Route } from 'react-router-dom';


export default class RefreshRouter extends Component {
  render() {
    const { path } = this.props;
    return (
      <Route
        path={path}
        component={({ history, location, match }) => {
          history.replace({
            ...location,
            pathname: location.pathname.substring(match.path.length),
          });
          return null;
        }}
      />
    );
  }
}

RefreshRouter.propTypes = {
  path: PropTypes.string.isRequired,
};
