import {
  Box,
  Typography,
  Grid,
  Button,
} from '@xbotvn/react-ui/core';
import { colors } from '@xbotvn/react-ui/styles';
import styled from 'styled-components';

export const Title = styled(Typography).attrs({
  variant: 'h5',
})`
  width: 650px;
  background: #fff;
  font-weight: bold !important;
  margin-top: -17px !important;
  margin-left: 50px !important;
`;

export const RangeYear = styled(Grid)`
  height: fit-content;
  margin-left: 15px;
  margin-top: 28px;
`;

export const Actions = styled(Button).attrs({
  size: 'small',
})`
  margin-right: 10px !important;
`;

export const GroupInput = styled(Grid).attrs({
  item: true,
  xs: 12,
})`
  display: flex;
`;

export const GroupSearch = styled(Box).attrs({
  mt: 3,
  ml: 10,
  mr: 10,
  mb: 3,
})`
  border: ${({ border }) => (border || 'solid 1px #0000003b')};
  border-radius: ${({ border }) => (border || '15px')};

  .MuiButton-containedPrimary {
    background: ${({ border }) => `${(border ? colors.green[500] : colors.orange[500])} !important`};
  }
  .MuiButton-containedPrimary:hover {
    background: ${({ border }) => `${(border ? colors.green[700] : colors.orange[700])} !important`};
  }
  .MuiButton-containedSecondary {
    background: ${({ border }) => `${(border ? colors.green[200] : colors.orange[200])} !important`};
    color: ${({ border }) => `${(border ? colors.green[800] : colors.orange[800])} !important`};
  }
  .MuiButton-containedSecondary:hover {
    background: ${({ border }) => `${(border ? colors.green[300] : colors.orange[300])} !important`};
  }
`;
