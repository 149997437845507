import React, { useCallback, useState } from 'react';

import { Waiting, Notification } from '@xbotvn/react-ui/components';
import {
  Chip,
  Typography,
} from '@xbotvn/react-ui/core';
import { saveAs } from 'file-saver';
import PropTypes from 'prop-types';
import ReactToolTip from 'react-tooltip';

import { callFileAPI } from '../../libs/backend';

function FileTag({
  onRemove,
  name,
  path,
  note,
}) {
  const [downloading, setDownloading] = useState(false);
  const download = useCallback(() => {
    if (path) {
      setDownloading(true);
      callFileAPI('proofs/download', {
        file: name,
        path,
      }, true).then((data) => {
        setDownloading(false);
        saveAs(new Blob([data]), name);
      }).catch(({ message }) => {
        setDownloading(false);
        Notification.warn(`Tải file không thành công: ${message}`);
      });
    }
  }, [path]);

  return (
    <>
      {downloading ? <Waiting fullscreen /> : null}
      <Chip
        data-tip
        data-for={name}
        style={{ marginLeft: 5 }}
        key={name}
        label={name}
        onDelete={onRemove}
        clickable
        onClick={download}
      />
      {note ? (
        <ReactToolTip id={name}>
          <Typography>{note}</Typography>
        </ReactToolTip>
      ) : null}
    </>
  );
}

FileTag.propTypes = {
  onRemove: PropTypes.func,
  name: PropTypes.string.isRequired,
  path: PropTypes.string,
  note: PropTypes.string,
};

FileTag.defaultProps = {
  onRemove: undefined,
  path: '',
  note: '',
};

export default FileTag;
