import React, {
  useEffect,
  useMemo,
  useState,
} from 'react';

import {
  Waiting,
  Panel,
} from '@xbotvn/react-ui/components';
import {
  Box,
  Button,
  Icon,
  List,
  ListItem,
  ListItemText,
  Typography,
} from '@xbotvn/react-ui/core';
import PropTypes from 'prop-types';
import Dropzone from 'react-dropzone';
import { useSelector, useDispatch } from 'react-redux';
import { useHistory } from 'react-router-dom';

import {
  FileTag,
} from '../../components';
import { handleRemoveFile, handleUploadFiles } from '../../redux/actions/proofs';
import { handleGetRecords } from '../../redux/actions/records';
import Records from './Records';
import * as Styles from './styles';

function Proof({ id }) {
  const dispatch = useDispatch();
  const history = useHistory();
  const {
    records,
    data,
    year,
    docType,
    properties,
    isStaff,
    proof,
    groups,
    handling,
    email,
  } = useSelector(({
    proofs,
    records: recordsStore,
    catalogs,
    user,
  }) => ({
    email: user.email,
    year: proofs.year,
    docType: proofs.docType,
    properties: catalogs?.app?.docTypes?.data?.[proofs.docType]?.properties ?? [],
    data: proofs.data?.[id] ?? {},
    records: recordsStore.data ?? {},
    isStaff: (user?.unit?.staffs || []).includes(user.email) || user?.account?.xbot?.support,
    groups: user?.unit?.groups ?? {},
    proof: proofs?.data?.[recordsStore?.proof ?? '']?.groups ?? [],
    handling: proofs.handling ?? false,
  }));

  const [activeTab, setActiveTab] = useState();

  const allowEdit = useMemo(() => {
    if (isStaff) return true;
    return proof.filter((g) => g.permissions.includes('import')).some(({ id: proofID }) => (groups?.[proofID]?.staffs ?? []).includes(email));
  }, [proof, isStaff, groups]);

  useEffect(() => {
    dispatch(handleGetRecords(
      year,
      docType,
      properties,
      id,
    ));
  }, [id]);

  const files = useMemo(() => (data?.files ?? []).map(({ name, note }) => (
    <span key={name} style={{ marginRight: 5 }}>
      <FileTag
        name={name}
        note={note}
        path={['proofs', id].join('/')}
        onRemove={isStaff ? (event) => {
          dispatch(handleRemoveFile(id, name));
          event.stopPropagation();
        } : undefined}
      />
      <strong>
        {` : ${Object.values(records).filter(({ file }) => file === name).length}`}
      </strong>
    </span>
  )), [data, records]);

  const allocationFiles = useMemo(() => (data?.allocFiles ?? []).map((name) => (
    <span key={name} style={{ marginRight: 5 }}>
      <FileTag
        name={name}
        path={['proofs', id, 'allocation'].join('/')}
        onRemove={allowEdit ? (event) => {
          dispatch(handleRemoveFile(id, name, true));
          event.stopPropagation();
        } : undefined}
      />
      <strong>
        {` : ${Object.values(records).filter(({ allocFiles }) => allocFiles.includes(name)).length}`}
      </strong>
    </span>
  )), [data, records]);

  const content = useMemo(() => {
    let items = [];
    switch (activeTab) {
      case 'proofFiles':
        items = files;
        break;
      case 'allocFiles':
        items = allocationFiles;
        break;
      default:
        break;
    }
    return (
      <List style={{ minWidth: 200 }}>
        {items.map((file, index) => (
          <ListItem key={index}>
            <ListItemText>
              {file}
            </ListItemText>
          </ListItem>
        ))}
      </List>
    );
  }, [
    activeTab,
    files,
    allocationFiles,
  ]);

  const actions = useMemo(() => {
    switch (activeTab) {
      case 'proofFiles':
        return isStaff ? (
          <Dropzone
            multiple
            onDrop={(selected) => {
              if (selected.length === 0) return;
              dispatch(handleUploadFiles(id, selected));
            }}
          >
            {({ getRootProps, getInputProps }) => (
              <div {...getRootProps()} style={{ width: '100%', padding: 10 }}>
                <input {...getInputProps()} />
                <Button
                  color="secondary"
                  fullWidth
                  startIcon={<Icon>cloud_upload</Icon>}
                >
                  Tải lên
                </Button>
              </div>
            )}
          </Dropzone>
        ) : null;
      case 'allocFiles':
        return (
          <Dropzone
            multiple
            onDrop={(selected) => {
              if (selected.length === 0) return;
              dispatch(handleUploadFiles(id, selected, true));
            }}
          >
            {({ getRootProps, getInputProps }) => (
              <div {...getRootProps()} style={{ width: '100%', padding: 10 }}>
                <input {...getInputProps()} />
                <Button fullWidth color="secondary" startIcon={<Icon>cloud_upload</Icon>}>
                  Tải lên
                </Button>
              </div>
            )}
          </Dropzone>
        );
      default:
        return null;
    }
  }, [activeTab, isStaff]);

  return (
    <>
      <Panel
        open={activeTab}
        onClose={() => setActiveTab()}
        title={activeTab === 'proofFiles' ? 'File minh chứng' : 'Đã nhận bằng'}
        actions={actions}
      >
        <Box pr={15}>
          {content}
        </Box>
      </Panel>
      <Box pl={2} pr={2} pt={1}>
        {handling ? <Waiting fullscreen /> : null}
        <Styles.Paper>
          <Box display="flex">
            <Box flexGrow={1} display="flex">
              <Styles.BtnBack
                startIcon={<Icon>arrow_back_ios</Icon>}
                onClick={() => history.push('/proofs')}
              >
                Danh sách bộ minh chứng
              </Styles.BtnBack>
            </Box>
            <Box flexGrow={1} display="flex">
              <Typography
                align="center"
                variant="caption"
                style={{ fontWeight: 'bold', fontSize: 16 }}
              >
                {data?.name ?? ''}
              </Typography>
            </Box>
            <Styles.UploadButton
              onClick={() => setActiveTab('proofFiles')}
            >
              File minh chứng
            </Styles.UploadButton>
            <Styles.UploadButton
              onClick={() => setActiveTab('allocFiles')}
            >
              Đã nhận bằng
            </Styles.UploadButton>
          </Box>
        </Styles.Paper>
        <Box overflow="hidden">
          <Records />
        </Box>
      </Box>
    </>
  );
}

Proof.propTypes = {
  id: PropTypes.string.isRequired,
};

export default Proof;
