import React from 'react';

import { Box } from '@xbotvn/react-ui/core';

import Records from './Records';
import Search from './Search';

function Home() {
  return (
    <>
      <Search />
      <Box m={1}>
        <Records />
      </Box>
    </>
  );
}

export default Home;
