import React, { useState, useEffect, useMemo } from 'react';

import { Waiting, Notification, Table } from '@xbotvn/react-ui/components';
import {
  Dialog,
  DialogTitle,
  DialogContent,
  IconButton,
  Chip,
  Icon,
} from '@xbotvn/react-ui/core';
import PropTypes from 'prop-types';

import { graphQLCaller } from '../../../libs/backend';
import Unit from './Unit';

function Units({ onClose }) {
  const [units, setUnits] = useState([]);
  const [unit, setUnit] = useState();
  const [handling, setHandling] = useState(true);

  const doReload = async () => {
    setHandling(true);
    try {
      const data = await graphQLCaller('units', `{
        units {
          id
          name
          type
          province
          district
          address
          admins
          staffs
        }
      }`);
      setUnits(data?.units ?? []);
      setHandling(false);
    } catch ({ message }) {
      setHandling(false);
      Notification.warn(`Kết nối CSDL không thành công: ${message}`);
    }
  };

  useEffect(() => {
    doReload();
  }, []);

  const columns = useMemo(() => [
    {
      Header: 'Mã đơn vị',
      accessor: 'id',
    },
    {
      Header: 'Tên đơn vị',
      accessor: 'name',
      width: 200,
    },
    {
      Header: 'Địa chỉ',
      accessor: 'address',
      width: 200,
    },
    {
      Header: 'Nhân sự',
      accessor: 'staffs',
      // eslint-disable-next-line react/prop-types
      Cell: ({ cell: { value } }) => (
        <Chip
          color="secondary"
          label={(value || []).length}
        />
      ),
    },
    {
      Header: ' ',
      action: true,
      accessor: 'action',
      sticky: 'right',
      width: 50,
      // eslint-disable-next-line react/prop-types
      Cell: ({ row: { original } }) => (
        <IconButton
          onClick={() => setUnit(original)}
        >
          <Icon>edit</Icon>
        </IconButton>
      ),
    },
  ], []);

  return (
    <Dialog
      open
      fullWidth
      maxWidth="md"
      onClose={onClose}
    >
      <DialogTitle
        title="Danh sách đơn vị"
        onClose={onClose}
      />
      <DialogContent dividers>
        {handling ? <Waiting fullscreen /> : null}
        {unit ? (
          <Unit
            onClose={(reload) => {
              setUnit();
              if (reload) doReload();
            }}
            data={unit}
          />
        ) : null}
        <Table
          disableGroupBy
          disableFilters
          columns={columns}
          data={units}
          height={window.innerHeight - 500}
        />
      </DialogContent>
    </Dialog>
  );
}

Units.propTypes = {
  onClose: PropTypes.func.isRequired,
};

export default Units;
