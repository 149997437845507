import { Notification } from '@xbotvn/react-ui/components';
import {
  takeLatest,
  takeEvery,
  put,
  all,
  select,
} from 'redux-saga/effects';

import { HOSTED } from '../../config';
import { auth, signedOut, graphQLCaller } from '../../libs/backend';
import { app } from '../../libs/firebase';
import {
  queryCities,
  queryCategories,
  queryCatalogs,
} from './catalogs';
import { USER, SIGN_OUT } from './constants';

export function* update(data = {}) {
  yield put({
    type: USER.update,
    ...data,
  });
}

export function* loadLiveSupport(user, unit) {
  const cities = (yield select())?.catalogs?.system?.cities?.data ?? {};
  window.fcWidget.init({
    token: 'a1e32696-ff15-47e3-a3ec-44c01ddf8f0c',
    host: 'https://wchat.freshchat.com',
    locale: 'vi',
    siteId: 'EBOT',
    tags: ['ebot'],
    firstName: 'Thầy/Cô',
    lastName: user.displayName,
    email: user.email,
    faqTags: {
      tags: ['ebot'],
      filterType: 'category',
    },
    config: {
      showFAQOnOpen: false,
      headerProperty: {
        hideChatButton: true,
        appName: 'EBOT',
      },
      content: {
        placeholders: {
          search_field: 'Tìm kiếm',
          reply_field: 'Trả lời',
          csat_reply: 'Nhập nội dung đánh giá...',
        },
        actions: {
          csat_yes: 'Có',
          csat_no: 'Không',
          push_notify_yes: 'Có',
          push_notify_no: 'Không',
          tab_faq: 'Câu hỏi thường gặp',
          tab_chat: 'Hỗ trợ',
          csat_submit: 'Gửi',
        },
        headers: {
          chat: 'Hỗ trợ trực tuyến',
          chat_help: 'Xin cám ơn thầy/cô đã sử dụng hệ thống FBOT',
          faq: 'Các câu hỏi thường gặp',
          faq_help: 'Thầy/Cô có thể tìm kiếm theo nội dung',
          faq_not_available: 'Không tìm thấy',
          faq_search_not_available: 'Không tìm thấy tài liệu nào có nội dung {{query}}',
          faq_useful: 'Tài liệu này có giúp ích gì cho thầy/cô không?',
          faq_thankyou: 'Cám ơn phản hồi của thầy/cô',
          faq_message_us: 'Góp ý',
          push_notification: 'Thầy/Cô có đồng ý hiện thông báo khi có tin nhắn đến?',
          csat_question: 'Câu hỏi của thầy/cô có được trả lời không??',
          csat_yes_question: 'Thầy/cô đánh giá như thế nào về thái độ chăm sóc?',
          csat_no_question: 'Công ty có thể cải thiện gì để hỗ trợ tốt thêm không?',
          csat_thankyou: 'Cám ơn đánh giá của thầy/cô',
          csat_rate_here: 'Gửi đánh giá',
          channel_response: {
            offline: 'Hiện không có nhân viên nào đang trực tổng đài. Thầy/Cô vui lòng để lại tin nhắn tại đây.',
            online: {
              minutes: {
                one: 'Thầy/Cô vui lòng đợi khoảng {!time!} phút',
                more: 'Thông thường sẽ mất khoảng {!time!} phút',
              },
              hours: {
                one: 'Thầy/Cô vui lòng đợi khoảng 1 tiếng',
                more: 'Thông thường sẽ mất khoảng {!time!} tiếng',
              },
            },
          },
        },
      },
    },
  });

  const gender = (user.gender === 'M') ? 'Thầy' : 'Cô';

  const province = cities?.unit?.province ?? {};
  const district = province?.districts?.[unit.district] ?? {};
  const ward = district?.wards?.[unit.ward] ?? {};

  window.fcWidget.user.setProperties({
    firstName: gender,
    lastName: user.displayName,
    email: user.email,
    unit_name: unit?.name ?? '',
    unit_id: unit?.id ?? '',
    address: unit?.type ?? '',
    provinceName: province?.name ?? 'Không tìm thấy',
    districtName: district?.name ?? 'Không tìm thấy',
    wardName: ward?.name ?? 'Không tìm thấy',
    user_uid: user.uid,
    log_server: 'Unkown',
  });
}

function* userSignedIn(user) {
  const domains = window.location.hostname.split('.');
  const activeUnit = HOSTED[domains?.[0]]?.unit ?? '';
  if (activeUnit) {
    const { email, uid } = user;
    yield* queryCatalogs(activeUnit);
    yield* queryCategories(activeUnit);
    if (email) {
      try {
        const {
          account,
          information,
          unit,
        } = yield auth(email, uid, activeUnit);
        yield* queryCities();
        yield* update({
          uid,
          email,
          displayName: user?.displayName ?? email,
          photoURL: user?.photoURL ?? '',
          ...information,
          account,
          unit,
          authorized: true,
        });
        yield* loadLiveSupport(user, unit);
      } catch ({ message }) {
        yield* update({ email, uid, authorized: true });
        Notification.error(message);
      }
    } else {
      try {
        const { unit } = yield graphQLCaller(
          'units',
          `{
            unit(id: "${activeUnit}") {
              name
              address
            }
          }`,
        );
        yield* update({
          authorized: true,
          unit: {
            id: activeUnit,
            ...unit,
          },
        });
      } catch ({ message }) {
        yield* update({ email, uid, authorized: true });
        Notification.error(message);
      }
    }
  } else {
    yield* update({ authorized: true });
    Notification.error('Unkown unit');
  }
}

function* userSignedOut() {
  yield app.auth().signOut();
  yield signedOut();
}

function* updateInformation({
  displayName,
  phoneNumber,
  male,
  photoURL,
}) {
  const { email, uid } = (yield select())?.user ?? {};
  try {
    yield graphQLCaller('users', `
      mutation updateUserInformation($uid: ID!, $information: UserInput!) {
        updateUserInformation(uid: $uid, information: $information)
      }
    `, {
      uid,
      information: {
        email,
        displayName,
        phoneNumber,
        male,
        photoURL,
      },
    });
    yield* update({
      displayName,
      phoneNumber,
      male,
      photoURL,
    });
    Notification.success('Cập nhật thông tin tài khoản thành công.');
  } catch ({ message }) {
    yield* update();
    Notification.error(message);
  }
}

function* updateUnit({
  admins,
  staffs,
  groups,
  other,
  onComplete,
}) {
  const { unit } = (yield select())?.user ?? {};
  if (!unit || !unit.id) yield* update();
  try {
    const convertedGroups = Object.entries(groups).map(([id, group]) => ({
      id,
      ...group,
    }));
    yield graphQLCaller('units', `
      mutation updateUnitInformation($id: ID!, $information: UnitInput!) {
        updateUnitInformation(id: $id, information: $information)
      }
    `, {
      id: unit.id,
      information: {
        admins,
        staffs,
        groups: convertedGroups,
        ...other,
      },
    });

    yield put({
      type: USER.unit,
      admins,
      staffs,
      groups,
    });
    Notification.success('Cập nhật thành công.', { action: onComplete });
  } catch ({ message }) {
    Notification.error(message);
    yield* update();
  }
}

export const handleUserUpdate = (user) => ({
  type: USER.handlers.update,
  ...user,
});

export const handleUserSignedIn = (user) => ({
  type: USER.handlers.login,
  ...user,
});

export const handleUserSignOut = () => ({
  type: SIGN_OUT,
});

export const handleUpdate = (data) => ({
  type: USER.update,
  ...data,
});

export const handleUpdateUnit = (
  staffs,
  admins,
  groups,
  others = {},
  onComplete,
) => ({
  type: USER.handlers.unit,
  staffs,
  admins,
  groups,
  others,
  onComplete,
});

export default function* saga() {
  yield all([
    yield takeLatest(USER.handlers.login, userSignedIn),
    yield takeLatest(USER.handlers.update, updateInformation),
    yield takeEvery(SIGN_OUT, userSignedOut),
    yield takeLatest(USER.handlers.unit, updateUnit),
  ]);
}
