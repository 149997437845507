import React, { useState } from 'react';

import { Notification } from '@xbotvn/react-ui/components';
import {
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  Grid,
  TextField,
  Button,
} from '@xbotvn/react-ui/core';
import PropTypes from 'prop-types';

function Group({
  onClose,
  id,
  name: initName,
  members: initMembers,
}) {
  const [name, setName] = useState(initName);
  const [members, setMembers] = useState(initMembers);

  return (
    <Dialog fullWidth open onClose={() => onClose()}>
      <DialogTitle
        onClose={() => onClose()}
        title={id ? initName : 'Tạo Nhóm'}
      />
      <DialogContent dividers>
        <Grid container spacing={3}>
          <Grid item xs={12}>
            <TextField
              label="Tên Nhóm"
              variant="outlined"
              required
              fullWidth
              value={name}
              onChange={(e) => setName(e.target.value)}
              placeholder="Nhập tên nhóm"
            />
          </Grid>
          <Grid item xs={12}>
            <TextField
              label="Nhân Sự (cách nhau bằng dấu ,)"
              variant="outlined"
              required
              fullWidth
              value={members}
              onChange={(e) => setMembers(e.target.value)}
              placeholder="Các emails cách nhau bằng dấu ,"
            />
          </Grid>
        </Grid>
      </DialogContent>
      <DialogActions>
        <Button
          color="primary"
          onClick={() => {
            if (!name) {
              Notification.warn('Tên nhóm không được bỏ trống.');
              return;
            }
            onClose(name, members);
          }}
        >
          {id ? 'Áp Dụng' : 'Thêm Nhóm'}
        </Button>
      </DialogActions>
    </Dialog>
  );
}

Group.propTypes = {
  onClose: PropTypes.func.isRequired,
  id: PropTypes.string,
  name: PropTypes.string,
  members: PropTypes.string,
};

Group.defaultProps = {
  id: '',
  name: '',
  members: '',
};

export default Group;
