import React, { useEffect, useMemo, useState } from 'react';

import { Notification, Waiting } from '@xbotvn/react-ui/components';
import {
  Box,
  Typography,
  Button,
  Icon,
  Dialog,
  DialogTitle,
  DialogContent,
} from '@xbotvn/react-ui/core';
import { ThemeProvider, Themes } from '@xbotvn/react-ui/styles';
import {
  cloneDeep,
  compact,
  set,
  unset,
} from '@xbotvn/utils/collection';
import axios from 'axios';
import Parser from 'html-react-parser';
import { useDispatch, useSelector } from 'react-redux';
import {
  BrowserRouter,
  Route,
  Switch as RouterSwitch,
} from 'react-router-dom';

import packageJson from '../package.json';
import AuthorizedRoute from './AuthorizedRoute';
import { AppBar } from './components';
import { RESOURCES, NOTIFICATIONS } from './config';
import { app } from './libs/firebase';
import PrivateRoute from './PrivateRoute';
import { handleUpdateSettings } from './redux/actions/settings';
import { handleUserSignedIn } from './redux/actions/user';
import RefreshRoute from './RefreshRoute';
import {
  Home,
  NotFound,
  Proofs,
  Realloc,
  StatisticAlloc,
  Certificates,
} from './screens';

export default function App() {
  const dispatch = useDispatch();
  const {
    settings: { darkMode },
    authorized,
    email,
    unit,
    account,
    activeCompany,
    product,
  } = useSelector(({
    settings,
    user,
    catalogs,
  }) => ({
    settings,
    authorized: user?.authorized ?? false,
    activeCompany: user.company,
    email: user.email ?? '',
    unit: user.unit ?? {},
    account: user.account ?? {},
    product: catalogs?.system?.product?.data ?? {},
  }));

  const [notifications, setNotifications] = useState([]);

  useEffect(() => {
    if (process.env.REACT_APP_STAGE !== 'development') {
      axios.post(NOTIFICATIONS, { product: 'fbot' }).then((results) => {
        setNotifications(results?.data ?? []);
      }).catch();
    }
  }, []);

  useEffect(() => {
    const ico = document.getElementById('favicon');
    ico.href = `${RESOURCES}/${activeCompany.toLowerCase()}.ico`;
    const cached = localStorage.getItem('settings');
    if (cached) {
      const parsed = JSON.parse(cached);
      dispatch(handleUpdateSettings(parsed));
      if (parsed.dark) { document.body.style.backgroundColor = '#30404d'; }
    }
    return app.auth().onAuthStateChanged((user) => {
      dispatch(handleUserSignedIn(user));
    });
  }, []);

  const {
    isSupport,
    isStaff,
    isGroup,
  } = useMemo(() => ({
    isSupport: account?.xbot?.support ?? false,
    isStaff: (unit?.staffs ?? []).includes(email),
    isGroup: Object.values(unit?.groups ?? {}).some(({ staffs }) => staffs.includes(email)),
  }), [email, unit, account]);

  const newThemes = useMemo(() => {
    const cloned = cloneDeep(Themes);
    if (activeCompany !== 'XBOT') {
      const path = ['default', darkMode ? 'dark' : 'light', 'typography'];
      const variants = compact(
        Object.entries(cloned?.default?.light?.typography ?? {})
          .map(([variant, { fontFamily }]) => {
            if (fontFamily) return variant;
            return '';
          }),
      );
      if (activeCompany === 'STEDU') {
        unset(cloned, [...path, 'fontFamily']);
      } else {
        set(cloned, [...path, 'fontFamily'], 'Tahoma');
      }
      variants.forEach((variant) => {
        if (activeCompany === 'STEDU') {
          unset(cloned, [...path, variant, 'fontFamily']);
        } else {
          set(cloned, [...path, variant, 'fontFamily'], 'Tahoma');
        }
      });
    }
    return cloned;
  }, [darkMode, activeCompany]);

  return authorized ? (
    <ThemeProvider theme={newThemes.default[darkMode ? 'dark' : 'light']}>
      {((isSupport || isStaff || isGroup) && notifications.length) ? (
        <Dialog
          onClose={() => setNotifications((prev) => prev.shift())}
          open
          maxWidth="md"
          fullWidth
        >
          <DialogTitle
            title={notifications[0].title}
            onClose={() => setNotifications((prev) => prev.shift())}
          />
          <DialogContent>{Parser(notifications[0].content)}</DialogContent>
        </Dialog>
      ) : null}
      <BrowserRouter>
        <AppBar />
        <RouterSwitch>
          <Route exact path="/"><Home /></Route>
          <Route exact path="/search"><Home /></Route>
          <AuthorizedRoute exact path="/proofs/:id?" component={Proofs} />
          <AuthorizedRoute exact path="/realloc" component={Realloc} />
          <PrivateRoute exact path="/statisticAlloc/:id?" component={StatisticAlloc} />
          <PrivateRoute exact path="/certificates" component={Certificates} />
          <RefreshRoute path="/refresh" />
          <Route component={() => <NotFound />} />
        </RouterSwitch>
      </BrowserRouter>
      <Notification.Container />
      <Box
        zIndex={998}
        height={50}
        bgcolor="rgb(255, 255, 255)"
        p={1}
        position="fixed"
        right={0}
        bottom={0}
        left={0}
        display="flex"
        alignItems="center"
        justifyContent="space-between"
      >
        <Typography variant="caption">
          {`EBOT v${packageJson.version} - © 2020, ${activeCompany} Technology JSC. All Rights Reserved.`}
        </Typography>
        {(isSupport || isStaff || isGroup) ? (
          <Box display="flex">
            <Button
              size="small"
              variant="text"
              style={{ marginRight: 20 }}
              startIcon={<Icon>computer</Icon>}
            >
              Hỗ trợ từ xa
            </Button>
            {product.guide ? (
              <Button
                variant="text"
                onClick={() => window.open(product.guide, 'Hướng dẫn sử dụng', 'location:no')}
                style={{ marginRight: 20 }}
                startIcon={<Icon>menu_book</Icon>}
                size="small"
              >
                Sách hướng dẫn
              </Button>
            ) : null}
            {window.fcWidget ? (
              <Button
                size="small"
                endIcon={<Icon>support_agent</Icon>}
                onClick={() => window.fcWidget.open()}
                variant="outlined"
                color="primary"
              >
                Chăm sóc
              </Button>
            ) : null}
          </Box>
        ) : null}
      </Box>
    </ThemeProvider>
  ) : <Waiting fullscreen />;
}
