/* eslint-disable react/prop-types */
import React, {
  useMemo,
  useState,
  useEffect,
} from 'react';

import {
  AutoComplete,
  Waiting,
  Table,
  Notification,
} from '@xbotvn/react-ui/components';
import {
  AppBar,
  Toolbar,
  Box,
} from '@xbotvn/react-ui/core';
import {
  flattenDeep,
  flatten,
  orderBy,
  range,
} from '@xbotvn/utils/collection';
import { useSelector } from 'react-redux';

import { graphQLCaller } from '../../libs/backend';

function StatisticAlloc() {
  const {
    pgds,
    handling,
    unit,
  } = useSelector(({ user, proofs: proofsStore }) => ({
    pgds: user?.unit?.pgds ?? [],
    handling: proofsStore?.handling ?? false,
    unit: user?.unit ?? {},
  }));

  const [year, setYear] = useState();
  const [proofs, setProofs] = useState([]);
  const [docTypes, setDocTypes] = useState([]);
  const [waiting, setWaiting] = useState(false);

  useEffect(async () => {
    setWaiting(true);
    const getDocTypes = flattenDeep(await Promise.all(pgds.map(async ({
      id,
    }) => {
      const { docTypes: records } = await graphQLCaller('catalogs', `{
        docTypes(unitID: "${id}") {
          id
          category
          name
          properties {
            code
            name
            type
            required
            public
            order
            importColumn
          }
          importRow
          importColumn
        }
      }`);
      return records;
    })));

    setDocTypes(getDocTypes);
    setWaiting(false);
  }, []);

  useEffect(async () => {
    if (unit?.type === 'sgd') {
      if (!year) {
        setProofs(pgds.map(({ id, name: pgd }) => ({
          id,
          pgd,
        })));
      } else {
        const queue = [];
        docTypes.forEach((docType) => {
          queue.push(pgds.map(({ id: unitID, name: pgd }) => ({
            pgd,
            unitID,
            docType: docType.id,
          })));
        });

        setWaiting(true);
        try {
          const results = await Promise.all(flatten(queue).map(async ({
            unitID,
            pgd,
            docType,
          }) => {
            const data = await graphQLCaller(
              'proofs',
              `
            query proofs($unitID: String!, $docType: String, $year: Int!) {
              proofs(unitID: $unitID, docType: $docType, year: $year) {
                id
                name
                note
                files {
                  name
                  note
                }
                allocFiles
                groups {
                  id
                  permissions
                }
                approved {
                  received
                  total
                  passed
                }
                latest {
                  user
                  time
                }
              }
            }
          `,
              {
                unitID,
                year,
              },
            );
            return (data?.proofs ?? []).map((values) => ({ pgd, docType, ...values }));
          }));
          if (results.length) setProofs(flatten(results));
          else {
            setProofs(pgds.map(({ id, name: pgd }) => ({
              id,
              pgd,
            })));
          }
          setWaiting(false);
        } catch ({ message }) {
          Notification.error(message);
          setWaiting(false);
        }
      }
    }
  }, [year, docTypes]);

  const data = useMemo(() => orderBy(proofs.map(({
    id: proofId,
    pgd,
    name,
    approved,
  }) => ({
    id: proofId,
    pgd,
    name,
    passed: approved?.passed ?? 0,
    received: approved?.received ?? 0,
  })), ['unit', 'name'], []), [proofs]);

  const columns = useMemo(() => [
    {
      Header: 'Phòng giáo dục',
      accessor: 'pgd',
      sticky: 'left',
      width: 200,
    },
    {
      Header: 'Trường',
      accessor: 'name',
      sticky: 'left',
      width: 250,
    },
    {
      Header: 'Số lượng học sinh tốt nghiệp',
      accessor: 'passed',
      type: 'number',
      width: 280,
      aggregate: 'sum',
      Cell: ({ cell: { value }, row: { original } }) => {
        if (original) return value;
        return <strong>{value}</strong>;
      },
    },
    {
      Header: 'Số lượng học sinh đã nhận bằng',
      accessor: 'received',
      type: 'number',
      width: 300,
      aggregate: 'sum',
      Cell: ({ cell: { value }, row: { original } }) => {
        if (original) return value;
        return <strong>{value}</strong>;
      },
    },
  ], []);

  return (
    <>
      {(waiting || handling) ? <Waiting fullscreen /> : null}
      <AppBar
        position="static"
        style={{
          background: 'white',
          padding: 1,
          boxShadow: 'none',
        }}
      >
        <Toolbar>
          <Box display="flex" width={1}>
            <Box display="flex" flexGrow={2}>
              <Box ml={1} width={140}>
                <AutoComplete
                  value={year}
                  options={range(new Date().getFullYear() - 30, new Date().getFullYear() + 1)}
                  getOptionLabel={(val) => `${val}`}
                  onChange={(e, value) => setYear(value)}
                  inputProps={{
                    label: 'Năm tốt nghiệp',
                    required: true,
                  }}
                  fullWidth
                />
              </Box>
            </Box>
          </Box>
        </Toolbar>
      </AppBar>
      <Box m={2}>
        <Table
          columns={columns}
          data={data}
          initialState={{
            groupBy: ['pgd'],
          }}
          disableGlobalFilter
          rowHeight={42}
          usePagination={false}
          height={window.innerHeight - 320}
        />
      </Box>
    </>
  );
}

export default StatisticAlloc;
