import React, {
  useState, useMemo,
} from 'react';

import {
  AutoComplete,
  Confirmation,
  Table,
  Notification,
  Waiting,
} from '@xbotvn/react-ui/components';
import {
  Dialog,
  DialogContent,
  DialogTitle,
  DialogActions,
  Button,
  TextField,
  Grid,
  Icon,
  FormControl,
  FormLabel,
  IconButton,
  MenuItem,
  Box,
  Divider,
} from '@xbotvn/react-ui/core';
import { uniqBy } from '@xbotvn/utils/collection';
import PropTypes from 'prop-types';
import Dropzone from 'react-dropzone';
import { useSelector, useDispatch } from 'react-redux';

import { ReportTag } from '../../components';
import { PERMISSIONS } from '../../config';
import { uploadFiles } from '../../libs/backend';
import { handleUpdateProof } from '../../redux/actions/proofs';
import { handleClearRecords } from '../../redux/actions/records';

function ProofModal({
  onClose,
  id,
}) {
  const dispatch = useDispatch();
  const {
    unitID,
    proof,
    groups: groupsCatalog,
    handling,
  } = useSelector(({ proofs: proofsStore, user }) => ({
    unitID: user?.unit?.id ?? '',
    proof: proofsStore?.data?.[id] ?? {},
    groups: user?.unit?.groups ?? {},
    handling: proofsStore?.handling ?? false,
  }));

  const [name, setName] = useState(proof?.name ?? '');
  const [note, setNote] = useState(proof?.note ?? '');
  const [remove, setRemove] = useState();
  const [groups, setGroups] = useState(proof?.groups ?? []);
  const [pendingGroups, setPendingGroups] = useState([]);
  const [pendingPermission, setPendingPermission] = useState('view');
  const [addGroup, setAddGroup] = useState();
  const [uploading, setUploading] = useState(false);

  const columns = useMemo(() => [
    {
      Header: 'Nhóm Người Dùng',
      accessor: 'group',
      disableEdit: true,
      width: 300,
    },
    {
      Header: 'Phân quyền',
      accessor: 'permissions',
      disableEdit: true,
      width: 150,
    },
    {
      Header: ' ',
      accessor: 'id',
      // eslint-disable-next-line react/prop-types
      Cell: ({ cell: { value } }) => (
        <IconButton
          size="small"
          onClick={() => setGroups(
            (prevGroups) => prevGroups.filter(({ id: gid }) => gid !== value),
          )}
        >
          <Icon>delete</Icon>
        </IconButton>
      ),
      action: true,
      width: 50,
    },
  ], []);

  const rows = useMemo(() => groups.map(({ id: gid, permissions: allowance }) => ({
    group: groupsCatalog?.[gid]?.name ?? gid,
    id: gid,
    permissions: allowance.map((p) => PERMISSIONS?.[p] ?? '').join(', '),
  })), [groups]);

  return (
    <Dialog
      open
      fullWidth
      maxWidth="md"
      onClose={onClose}
    >
      <DialogTitle
        onClose={onClose}
        title={proof?.name ?? 'Bộ minh chứng'}
      />
      <DialogContent dividers>
        {(handling || uploading) ? <Waiting fullscreen /> : null}
        {remove ? (
          <Confirmation
            onClose={() => setRemove(false)}
            title="Xóa bộ minh chứng"
            severity="warning"
            description="Sau khi xoá thì sẽ không thể khôi phục lại được. Những thông tin đã số hoá với bộ minh chứng này sẽ không thể tra cứu được nữa."
            primaryAction={() => {
              dispatch(handleUpdateProof(
                [{
                  id,
                  data: undefined,
                }],
                () => {
                  dispatch(handleClearRecords([]));
                  onClose();
                },
              ));
            }}
          />
        ) : null}
        {addGroup ? (
          <Dialog
            open
            fullWidth
            maxWidth="sm"
            onClose={() => setAddGroup(false)}
          >
            <DialogTitle
              title="Thêm nhóm người dùng"
              onClose={() => setAddGroup(false)}
            />
            <DialogContent dividers>
              <Grid container spacing={2}>
                <Grid item xs={12}>
                  <AutoComplete
                    fullWidth
                    value={pendingGroups}
                    options={Object.keys(groupsCatalog)}
                    getOptionLabel={(val) => groupsCatalog?.[val]?.name ?? val}
                    multiple
                    inputProps={{
                      label: 'Nhóm Người Dùng',
                    }}
                    onChange={(e, values) => setPendingGroups(values)}
                    disableCloseOnSelect
                  />
                </Grid>
                <Grid item xs={12}>
                  <TextField
                    label="Phân Quyền"
                    value={pendingPermission}
                    fullWidth
                    onChange={(e) => setPendingPermission(e.target.value)}
                    select
                    SelectProps={{}}
                    variant="outlined"
                  >
                    {Object.entries(PERMISSIONS).map(([value, text]) => (
                      <MenuItem key={value} value={value}>{text}</MenuItem>
                    ))}
                  </TextField>
                </Grid>
              </Grid>
            </DialogContent>
            <DialogActions>
              <Button
                color="primary"
                onClick={() => {
                  if (pendingGroups.length) {
                    const append = pendingGroups.map((gid) => ({
                      id: gid,
                      permissions: [pendingPermission],
                    }));
                    setGroups((prevPermissions) => uniqBy([
                      ...append,
                      ...prevPermissions,
                    ], 'id'));
                    setPendingGroups([]);
                  }
                  setAddGroup(false);
                }}
                startIcon={<Icon>add</Icon>}
              >
                Thêm nhóm
              </Button>
            </DialogActions>
          </Dialog>
        ) : null}
        <Grid container spacing={2} style={{ paddingBottom: 10 }}>
          <Grid item xs={12}>
            <TextField
              label="Tên bộ minh chứng"
              required
              fullWidth
              value={name}
              onChange={(e) => setName(e.target.value)}
              variant="outlined"
            />
          </Grid>
          <Grid item xs={12}>
            <TextField
              label="Ghi chú"
              required
              fullWidth
              multiline
              value={note}
              onChange={(e) => setNote(e.target.value)}
              variant="outlined"
            />
          </Grid>
          <Grid item xs={12}>
            <FormControl>
              <FormLabel>File mẫu</FormLabel>
              <Box display="flex">
                <ReportTag
                  name="Phôi bằng"
                  template="graduation_certificate.xlsx"
                />
              </Box>
            </FormControl>
          </Grid>
        </Grid>
        <Divider />
        <Table
          columns={columns}
          data={rows}
          disableGlobalFilter
          disableFilters
          disableGroupBy
          height={400}
        />
      </DialogContent>
      <DialogActions>
        <Dropzone
          onDrop={async (selected) => {
            if (selected.length === 0) return;
            setUploading(true);
            try {
              await uploadFiles('reports/upload', {
                template: 'graduation_certificate.xlsx',
                unitID,
              }, selected);
              setUploading(false);
            } catch ({ message }) {
              setUploading(false);
              Notification.error(message);
            }
          }}
        >
          {({ getRootProps, getInputProps }) => (
            <div {...getRootProps()}>
              <input {...getInputProps()} />
              <Button
                style={{ float: 'left' }}
                color="secondary"
                startIcon={<Icon>cloud_upload</Icon>}
                onClick={() => { }}
              >
                Thay đổi phôi bằng
              </Button>
            </div>
          )}
        </Dropzone>
        <Button
          color="secondary"
          onClick={() => setAddGroup(true)}
        >
          Thêm nhóm người dùng
        </Button>
        {id ? (
          <Button
            color="secondary"
            onClick={() => setRemove(true)}
          >
            Xoá bộ minh chứng
          </Button>
        ) : null}
        <Button
          color="primary"
          startIcon={<Icon>save</Icon>}
          onClick={() => {
            if (!name) {
              Notification.warn('Tên bộ minh chứng không được bỏ trống.');
              return;
            }
            dispatch(handleUpdateProof(
              [{
                id,
                data: {
                  name,
                  note,
                  groups,
                },
              }],
              onClose,
            ));
          }}
        >
          Cập nhật
        </Button>
      </DialogActions>
    </Dialog>
  );
}

ProofModal.propTypes = {
  onClose: PropTypes.func.isRequired,
  id: PropTypes.string,
};

ProofModal.defaultProps = {
  id: '',
};

export default ProofModal;
