import React, {
  useMemo,
  useState,
} from 'react';

import {
  Waiting,
  Notification,
  DatePickerInput,
  Table,
} from '@xbotvn/react-ui/components';
import {
  Dialog,
  DialogTitle,
  DialogActions,
  DialogContent,
  Button,
  Icon,
  Box,
  Grid,
  TextField,
} from '@xbotvn/react-ui/core';
import { orderBy, uniqBy } from '@xbotvn/utils/collection';
import { format } from '@xbotvn/utils/date';
import axios from 'axios';
import { saveAs } from 'file-saver';
import PropTypes from 'prop-types';
import Dropzone from 'react-dropzone';
import { useSelector } from 'react-redux';

import { BACKEND } from '../../config';
import { callFileAPI } from '../../libs/backend';

const MAPPING = {
  L7: 'fullName',
  L9: 'birthDate',
  L11: 'birthPlace',
  I13: 'gender',
  S13: 'ethnic',
  J15: 'school',
  M17: 'year',
  M19: 'rate',
  AB19: 'category',
  P21: 'province',
  X21: 'printDate',
  AA21: 'printMonth',
  AE21: 'printYear',
  T23: 'district',
  L29: 'docNo',
  T30: 'signer',
};

function Printer({ onClose }) {
  const {
    unit,
    cities,
    records,
  } = useSelector(({
    records: recordsStore,
    catalogs,
    proofs,
    user,
  }) => ({
    unit: user?.unit ?? {},
    cities: catalogs?.system?.cities?.data ?? {},
    records: recordsStore?.data ?? {},
    year: proofs.year,
    docType: catalogs?.app?.docTypes?.data?.[proofs.docType] ?? {},
    proof: recordsStore?.proof ?? '',
  }));

  const [waiting, setWaiting] = useState(false);
  const [signer, setSigner] = useState('');
  const [date, setDate] = useState(new Date());
  const [province, setProvince] = useState(cities?.[unit.province]?.name ?? '');
  const [district, setDistrict] = useState(cities?.[unit.province]?.districts?.[unit.district]?.name ?? '');

  const columns = useMemo(() => [
    {
      Header: 'Họ và tên',
      accessor: 'fullName',
      sticky: 'left',
    },
    {
      Header: 'Ngày sinh',
      accessor: 'birthDay',
    },
    {
      Header: 'Nơi sinh',
      accessor: 'birthPlace',
    },
    {
      Header: 'Giới tính',
      accessor: 'gender',
    },
    {
      Header: 'Dân tộc',
      accessor: 'ethnic',
    },
    {
      Header: 'Trường',
      accessor: 'school',
    },
    {
      Header: 'Xếp loại',
      accessor: 'rate',
    },
    {
      Header: 'Số bằng',
      accessor: 'docNo',
      sticky: 'right',
    },
  ], []);

  const rows = useMemo(() => {
    const filtered = Object.values(records).filter(({ docNo }) => docNo);
    const sorted = orderBy(filtered, ['docNo'], ['asc']);
    return uniqBy(sorted.map((values) => ({
      ...values,
      birthDay: values.birthDay ? format(parseFloat(values.birthDay)) : '',
    })), 'docNo');
  }, [records]);

  return (
    <Dialog
      open
      fullWidth
      maxWidth="lg"
      onClose={onClose}
    >
      <DialogTitle
        title="In bằng tốt nghiệp"
        onClose={onClose}
      />
      <DialogContent dividers>
        {waiting ? <Waiting fullscreen /> : null}
        <Grid container spacing={2}>
          <Grid item xs={3}>
            <Grid container spacing={2}>
              <Grid item xs={12}>
                <DatePickerInput
                  value={date}
                  onChange={(d) => setDate(d)}
                  inputProps={{
                    label: 'Ngày ký',
                    variant: 'outlined',
                    fullWidth: true,
                  }}
                />
              </Grid>
              <Grid item xs={12}>
                <TextField
                  label="Người ký"
                  fullWidth
                  variant="outlined"
                  value={signer}
                  onChange={(e) => setSigner(e.target.value)}
                />
              </Grid>
              <Grid item xs={12}>
                <TextField
                  label="Tỉnh/Thành"
                  fullWidth
                  variant="outlined"
                  value={province}
                  onChange={(e) => setProvince(e.target.value)}
                />
              </Grid>
              <Grid item xs={12}>
                <TextField
                  label="Quận/Huyện"
                  fullWidth
                  variant="outlined"
                  value={district}
                  onChange={(e) => setDistrict(e.target.value)}
                />
              </Grid>
            </Grid>
          </Grid>
          <Grid item xs={9}>
            <Table
              columns={columns}
              data={rows}
              getRowId={(row) => row.docNo}
              disableGlobalFilter
              disableGroupBy
              height={window.innerHeight - 300}
            />
          </Grid>
        </Grid>
      </DialogContent>
      <DialogActions>
        <Box display="flex" width={1}>
          <Box flexGrow={1}>
            <Dropzone
              multiple={false}
              onDrop={async (selected) => {
                if (selected.length === 0) return;
                setWaiting(true);
                const data = new FormData();
                data.append('unitID', unit.id);
                data.append('template', 'graduation_certificate.xlsx');
                selected.forEach((file) => {
                  data.append('files', file);
                });
                try {
                  await axios.post(`${BACKEND}/file/reports/upload`, data, { withCredentials: true });
                  setWaiting(false);
                  Notification.success('Cật nhật mẫu in thành công.');
                } catch ({ message }) {
                  setWaiting(false);
                  Notification.warn(message);
                }
              }}
            >
              {({ getRootProps, getInputProps }) => (
                <div {...getRootProps()}>
                  <input {...getInputProps()} />
                  <Button
                    style={{ marginLeft: 5 }}
                    startIcon={<Icon>upload</Icon>}
                    color="secondary"
                    onClick={() => { }}
                  >
                    Thay đổi mẫu in
                  </Button>
                </div>
              )}
            </Dropzone>
          </Box>
          <Button
            style={{ marginRight: 5 }}
            startIcon={<Icon>cloud_download</Icon>}
            color="primary"
            onClick={async () => {
              setWaiting(true);
              try {
                const data = await callFileAPI('reports/download', { unitID: unit.id, template: 'graduation_certificate.xlsx' }, true);
                setWaiting(false);
                saveAs(new Blob([data]), 'graduation_certificate.xlsx');
              } catch ({ message }) {
                setWaiting(false);
                Notification.warn(`Tải file không thành công: ${message}`);
              }
            }}
          >
            Tải mẫu in
          </Button>
          <Button
            startIcon={<Icon>print</Icon>}
            color="primary"
            onClick={async () => {
              setWaiting(true);
              try {
                const extendedRecords = rows.map((row) => ({
                  ...row,
                  signer,
                  province,
                  district,
                  category: 'Chính quy',
                  printDate: date.getDate(),
                  printMonth: date.getMonth(),
                  printYear: date.getFullYear(),
                }));
                for (let bookmark = 0; bookmark < extendedRecords.length; bookmark += 100) {
                  // eslint-disable-next-line no-await-in-loop
                  const data = await axios.post(`${BACKEND}/report/graduationCertificate`, {
                    unitID: unit.id,
                    records: extendedRecords.slice(bookmark, bookmark + 100),
                    mapping: MAPPING,
                  }, { withCredentials: true, responseType: 'blob' });
                  if (data?.data) {
                    saveAs(new Blob([data.data]), `certificates_${bookmark + 1}.xlsx`);
                  }
                }
                setWaiting(false);
              } catch ({ message }) {
                setWaiting(false);
                Notification.warn(message);
              }
            }}
          >
            In bằng
          </Button>
        </Box>
      </DialogActions>
    </Dialog>
  );
}

Printer.propTypes = {
  onClose: PropTypes.func.isRequired,
};

export default Printer;
