/* eslint-disable react/prop-types */
import React, { useMemo } from 'react';

import { Waiting } from '@xbotvn/react-ui/components';
import {
  Typography,
  Box,
  Alert,
  List,
  ListItem,
  ListItemIcon,
  Icon,
  ListItemText,
  AlertTitle,
  Divider,
} from '@xbotvn/react-ui/core';
import { useSelector } from 'react-redux';

import Record from './Record';

function Records() {
  const {
    unit: {
      name,
      address,
      staffs,
      groups,
      admins,
    },
    email,
    isSupport,
    records,
    year,
    category,
    query,
    handling,
  } = useSelector(({ search, user }) => ({
    email: user.email,
    isSupport: user?.account?.xbot?.support ?? false,
    unit: user?.unit ?? {},
    year: search.year,
    category: search.category,
    query: search.query,
    records: search.records ?? {},
    handling: search?.handling ?? false,
  }));

  const authorized = useMemo(() => {
    if (isSupport) return isSupport;
    let users = [...(staffs || []), ...(admins || [])];
    Object.values((groups || {})).forEach(({ staffs: groupStaffs }) => {
      users = [...users, ...groupStaffs];
    });
    return users.includes(email);
  }, [isSupport, staffs, groups, admins]);

  const content = useMemo(() => {
    if (year && category && query && !Object.keys(records).length) {
      return (
        <Box
          p={1}
          mt={3}
          mb={9}
          width={800}
          ml="auto"
          mr="auto"
        >
          <Alert severity="info">
            <AlertTitle style={{ fontWeight: 'bold' }}>Không tìm thấy kết quả.</AlertTitle>
            <Typography>
              Nếu tra cứu không tìm thấy thông tin bằng tốt nghiệp
              xin gửi bản sao và yêu cầu xác minh đến:
            </Typography>
            <List>
              <ListItem>
                <ListItemIcon><Icon>business</Icon></ListItemIcon>
                <ListItemText>{name}</ListItemText>
              </ListItem>
              <ListItem>
                <ListItemIcon><Icon>location_on</Icon></ListItemIcon>
                <ListItemText>{address || 'Chưa có địa chỉ'}</ListItemText>
              </ListItem>
            </List>
          </Alert>
        </Box>
      );
    }
    if (Object.keys(records).length) {
      return (
        <List>
          <ListItem>
            <ListItemText style={{ width: 200 }}>
              <Typography style={{ fontWeight: 'bold' }}>Thí sinh</Typography>
            </ListItemText>
            <ListItemText style={{ width: 150 }}>
              <Typography style={{ fontWeight: 'bold' }}>Kết quả</Typography>
            </ListItemText>
            <ListItemText style={{ width: 200 }}>
              <Typography style={{ fontWeight: 'bold' }}>Chi tiết</Typography>
            </ListItemText>
            <ListItemText style={{ width: 150 }}>
              <Typography style={{ fontWeight: 'bold' }}>Ghi chú</Typography>
            </ListItemText>
            {authorized ? (
              <>
                <ListItemText style={{ width: 150 }}>
                  <Typography style={{ fontWeight: 'bold' }}>File minh chứng</Typography>
                </ListItemText>
                <ListItemText style={{ width: 150 }}>
                  <Typography style={{ fontWeight: 'bold' }}>Đã nhận bằng</Typography>
                </ListItemText>
              </>
            ) : null}
          </ListItem>
          <Divider />
          <div style={{ marginBottom: 50 }}>
            {Object.entries(records).map(([id, record]) => (
              <>
                <Record
                  key={id}
                  id={id}
                  authorized={authorized}
                  record={record}
                />
                <Divider />
              </>
            ))}
          </div>
        </List>
      );
    }
    return null;
  }, [
    name,
    address,
    year,
    query,
    category,
    records,
    authorized,
  ]);

  return (
    <Box mt={1}>
      {handling ? <Waiting fullscreen /> : null}
      {content}
    </Box>
  );
}

export default Records;
