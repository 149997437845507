import React, { Component } from 'react';

import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { Route, Redirect, withRouter } from 'react-router-dom';

class PrivateRoute extends Component {
  render() {
    const { user, path } = this.props;
    if (user?.account?.xbot?.support || (user?.unit?.admins ?? []).includes(user.email)) {
      return (
        <Route
          exact
          path={path}
          render={(props) => (<this.props.component {...props} />)}
        />
      );
    }
    return (
      <Route
        exact
        path={path}
        render={(props) => (<Redirect to={{ pathname: '/', state: { from: props.location } }} />)}
      />
    );
  }
}

const mapStateToProps = ({ user }) => ({
  user,
});

PrivateRoute.propTypes = {
  user: PropTypes.shape({
    email: PropTypes.string,
    unit: PropTypes.shape({
      admins: PropTypes.arrayOf(PropTypes.string),
    }),
    account: PropTypes.shape({
      xbot: PropTypes.shape({
        support: PropTypes.bool,
      }),
    }),
  }).isRequired,
  path: PropTypes.string.isRequired,
};

export default withRouter(connect(mapStateToProps)(PrivateRoute));
