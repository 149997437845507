import { stringify, parse } from 'querystring';

import React, {
  useState, useEffect, useCallback,
} from 'react';

import { Notification } from '@xbotvn/react-ui/components';
import { saveAs } from 'file-saver';
import { useSelector, useDispatch } from 'react-redux';
import { useHistory, useLocation } from 'react-router-dom';
import XLSX from 'xlsx';

import { callFileAPI } from '../../libs/backend';
import { handleQuery, handleClearSearch, handleListQuery } from '../../redux/actions/search';
import SearchGroup from '../Search';

function Search() {
  const dispatch = useDispatch();
  const {
    activeCompany,
    categories,
    handling,
  } = useSelector(({ catalogs, user }) => ({
    activeCompany: user.company,
    categories: catalogs?.system?.categories?.data ?? [],
    handling: user?.handling ?? true,
  }));

  const history = useHistory();
  const location = useLocation();
  const [year, setYear] = useState(null);
  const [category, setCategory] = useState(null);
  const [query, setQuery] = useState('');
  const [list, setList] = useState();
  const [school, setSchool] = useState();
  const [updated, setUpdated] = useState(false);
  const [rangeYears, setRangeYears] = useState(false);

  const handleSearch = () => {
    if (!category) {
      Notification.warn('Chưa chọn loại văn bằng, chứng chỉ.');
      return;
    }
    if (!year) {
      Notification.warn('Khoá thi không được bỏ trống.');
      return;
    }
    if (!query) {
      Notification.warn('Họ tên/Số bằng không được bỏ trống.');
      return;
    }
    const params = stringify(
      {
        year,
        category,
        query,
        school,
      },
      '&',
      '=',
    );
    history.push(`/search?${params}`);
  };

  const download = useCallback(() => {
    callFileAPI('downloadFileLookup', {}, true).then((data) => {
      saveAs(new Blob([data]), 'Danh sách tra cứu.xlsx');
    }).catch(({ message }) => {
      Notification.warn(`Tải file không thành công: ${message}`);
    });
  }, []);

  const importFile = (selected) => {
    if (selected.length === 0) return;
    if (!category) {
      Notification.warn('Chưa chọn loại văn bằng, chứng chỉ.');
      return;
    }
    selected.forEach((file) => {
      const reader = new FileReader();
      reader.onload = () => {
        const wb = XLSX.read(reader.result, { type: 'binary' });
        const data = Object.entries(XLSX.utils.sheet_to_json(
          wb.Sheets[wb.SheetNames[0]],
          { header: 1 },
        )).filter(([key, array]) => array.length >= 2 && key > 2).map((values) => values[1]);
        setList(data);
      };
      reader.readAsBinaryString(file);
    });
  };

  useEffect(() => {
    if (!handling) {
      const { pathname, search } = location;
      if (pathname.indexOf('/search') !== -1) {
        const cleanSearch = search.replace('?', '');
        const {
          year: pYear,
          category: pCategory,
          query: pQuery,
          school: pSchool,
        } = parse(cleanSearch) || {};
        if (pYear) setYear(parseFloat(pYear));
        if (pCategory) setCategory(pCategory);
        if (pQuery) setQuery(pQuery);
        if (pSchool) setSchool(pSchool);
        if (pYear && pCategory && pQuery) {
          dispatch(handleQuery(pYear, pCategory, pQuery.replace(/\s/g, ''), rangeYears, pSchool));
        } else dispatch(handleClearSearch());
      }
    }
  }, [location, handling]);

  useEffect(() => {
    if (list && category) {
      dispatch(handleListQuery(list, category));
    }
  }, [list]);

  useEffect(() => {
    if (!category && categories.length === 1) setCategory(categories?.[0]?.value);
  }, [categories]);

  useEffect(() => {
    if (updated) {
      handleSearch();
      setUpdated(false);
    }
  }, [updated]);

  return (
    <SearchGroup
      company={activeCompany}
      props={{
        download,
        handleSearch: () => handleSearch(),
        setUpdated: () => setUpdated(true),
        importFile: (selected) => importFile(selected),
        category,
        setCategory: (value) => setCategory(value),
        year,
        setYear: (value) => setYear(value),
        rangeYears,
        setRangeYears: () => setRangeYears(!rangeYears),
        school,
        setSchool: (value) => setSchool(value),
        query,
        setQuery: (value) => setQuery(value),
      }}
    />
  );
}

export default Search;
