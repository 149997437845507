import { REALLOCS } from '../actions/constants';

const initialState = {
  handling: false,
};

export default function reallocs(state = initialState, action) {
  const {
    type,
    data,
    year,
  } = action;

  switch (type) {
    case REALLOCS.handlers.import:
      return {
        ...state,
        handling: true,
      };
    case REALLOCS.handlers.get:
      return {
        ...state,
        [year]: [],
        handling: true,
      };
    case REALLOCS.update:
      return {
        ...state,
        [year]: data,
        handling: false,
      };
    default:
      return state;
  }
}
