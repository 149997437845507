import { all } from 'redux-saga/effects';

import catalogsSaga from './catalogs';
import proofsSaga from './proofs';
import reallocSaga from './reallocs';
import recordsSaga from './records';
import searchsSaga from './search';
import settingsSaga from './settings';
import userSaga from './user';

export default function* rootSaga() {
  yield all([
    userSaga(),
    settingsSaga(),
    searchsSaga(),
    catalogsSaga(),
    recordsSaga(),
    proofsSaga(),
    reallocSaga(),
  ]);
}
