/* eslint-disable react/prop-types */
import React, {
  useMemo,
  useState,
  useEffect,
} from 'react';

import {
  Waiting,
  Table,
  AutoComplete,
  Notification,
} from '@xbotvn/react-ui/components';
import {
  Toolbar,
  Box,
  Icon,
  TextField,
  Typography,
  Number,
  Checkbox,
} from '@xbotvn/react-ui/core';
import {
  range,
  cloneDeep,
  unset,
  flattenDeep,
  intersection,
} from '@xbotvn/utils/collection';
import { useSelector } from 'react-redux';

import { graphQLCaller } from '../../libs/backend';
import Cancels from './Cancels';
import Information from './Information';
import Search from './Search';
import * as Styles from './styles';

function Certificates() {
  const {
    unitActive,
    unitId,
    pgds,
  } = useSelector(({ user }) => ({
    unitId: user?.unit?.id,
    pgds: user?.unit?.pgds ?? [],
    unitActive: user?.unit ?? {},
  }));

  const [waiting, setWaiting] = useState(false);
  const [year, setYear] = useState(new Date().getFullYear());
  const [information, setInformation] = useState();
  const [cancels, setCancels] = useState();
  const [search, setSearch] = useState('');
  const [showSearch, setShowSearch] = useState(false);
  const [advanced, setAdvanced] = useState();
  const [data, setData] = useState([]);

  useEffect(async () => {
    if (year) {
      try {
        setWaiting(true);
        const results = await Promise.all([...pgds, unitActive].map(async ({
          id,
        }) => {
          const { getCertificates } = await graphQLCaller(
            'certificates',
            `{
              getCertificates(
                unitId: "${unitId}",
                year: ${year},
                unit: "${id}",
              ) {
                id
                unit
                year
                schoolLevel
                createDate
                from
                to
                prefix
                suffix
                cancels {
                  date
                  serial
                  note
                  file
                }
              }
            }`,
          );
          return getCertificates;
        }));
        setData(flattenDeep(results));
        setWaiting(false);
      } catch ({ message }) {
        setWaiting(false);
        Notification.warn(message);
      }
    }
  }, [
    year,
  ]);

  const updateCertificates = async (result, msg) => {
    setWaiting(true);
    let cloned = cloneDeep(data);
    const {
      id,
      from,
      to,
    } = result;
    const prefix = result?.prefix ?? '';
    const suffix = result?.suffix ?? '';
    const isExist = cloned.find(({
      id: cId,
      from: cFrom,
      to: cTo,
      prefix: pPrefix,
      suffix: pSuffix,
    }) => id !== cId
    && intersection(range(cFrom, cTo + 1), range(from, to + 1)).length
    && prefix === (pPrefix || '')
      && suffix === (pSuffix || ''));
    if (isExist) {
      let certs = `Bộ ${prefix}${from}${suffix} - ${prefix}${to}${suffix}`;
      if (from === to) {
        certs = `Phôi ${prefix}${from}${suffix}`;
      }
      Notification.error(`${certs} có số hiệu trùng bộ ${prefix}${isExist.from}${suffix} - ${prefix}${isExist.to}${suffix}.`);
      setWaiting(false);
      return;
    }
    try {
      if (id) {
        const newResult = cloneDeep(result);
        unset(newResult, 'serialFrom');
        unset(newResult, 'serialTo');
        unset(newResult, 'total');
        unset(newResult, 'totalCancels');
        unset(newResult, 'unitName');
        await graphQLCaller(
          'certificates',
          `
            mutation updateCertificates($unitId: String!, $data: CertificatesInput!) {
              updateCertificates(unitId: $unitId, data: $data)
            }
          `,
          {
            unitId,
            data: newResult,
          },
        );
        cloned = cloned.map((record) => {
          if (record.id === id) {
            return {
              ...record,
              ...result,
            };
          }
          return record;
        });
      } else {
        const { createCertificates: certsID } = await graphQLCaller(
          'certificates',
          `
            mutation createCertificates($unitId: String!, $data: CertificatesInput!) {
              createCertificates(unitId: $unitId, data: $data)
            }
          `,
          {
            unitId,
            data: result,
          },
        );
        cloned.push({
          id: certsID,
          ...result,
        });
      }
      setData(cloned);
    } catch ({ message }) {
      Notification.warn(message);
    }
    Notification.success(msg);
    setInformation();
    setWaiting(false);
  };

  const rows = useMemo(() => data.map((certs) => {
    const {
      unit,
      from,
      to,
      prefix,
      suffix,
      cancels: cancelCerts,
    } = certs;
    return {
      ...certs,
      unitName: [...pgds, unitActive].find(({ id }) => id === unit)?.name ?? unit,
      serialFrom: `${prefix || ''}${from}${suffix || ''}`,
      serialTo: `${prefix || ''}${to}${suffix || ''}`,
      total: to === from ? 1 : to - from + 1,
      totalCancels: (cancelCerts || []).length,
    };
  }), [data, unitActive, pgds]);

  const columns = useMemo(() => [
    {
      Header: ({ getToggleAllRowsExpandedProps, isAllRowsExpanded }) => {
        const { onClick: allRowsExpanded } = getToggleAllRowsExpandedProps();
        return (
          <span
            onClick={(e) => {
              e.stopPropagation();
              allRowsExpanded();
            }}
          >
            Đơn vị
            {' '}
            <Checkbox style={{ padding: '0px 12px' }} checked={isAllRowsExpanded} />
          </span>
        );
      },
      accessor: 'unitName',
      width: 170,
    },
    {
      Header: 'Bậc học',
      accessor: 'schoolLevel',
      width: 150,
    },
    {
      Header: 'Số hiệu đầu',
      accessor: 'serialFrom',
      width: 150,
    },
    {
      Header: 'Số hiệu cuối',
      accessor: 'serialTo',
      width: 150,
    },
    {
      Header: 'Ngày cấp',
      accessor: 'createDate',
      type: 'date',
      filter: 'dateOnly',
      width: 150,
      Footer: (
        <Typography style={{ fontWeight: 'bold' }}>Tổng cộng:</Typography>
      ),
    },
    {
      Header: 'Số phôi',
      accessor: 'total',
      type: 'number',
      width: 150,
      aggregate: 'sum',
      Cell: ({ cell: { value }, row: { original } }) => {
        if (original) return value;
        return <strong>{value}</strong>;
      },
      Footer: (
        <strong>
          <Number
            thousandSeparator
            value={rows.reduce((sum, { total }) => sum + total, 0)}
          />
        </strong>
      ),
    },
    {
      Header: 'Số phôi huỷ',
      accessor: 'totalCancels',
      type: 'number',
      width: 150,
      aggregate: 'sum',
      Cell: ({ cell: { value }, row: { original } }) => {
        if (original) return value;
        return <strong>{value}</strong>;
      },
      Footer: (
        <strong>
          <Number
            thousandSeparator
            value={rows.reduce(
              (total, { totalCancels }) => total + totalCancels,
              0,
            )}
          />
        </strong>
      ),
    },
    {
      Header: 'Hành động',
      accessor: 'actions',
      disableFilters: true,
      Cell: ({ row: { original } }) => (original ? (
        <>
          <Styles.StyleButton
            variant="text"
            startIcon={<Icon>delete</Icon>}
            onClick={() => setCancels(original)}
          >
            Huỷ
          </Styles.StyleButton>
          <Styles.StyleButton
            variant="text"
            startIcon={<Icon>edit</Icon>}
            onClick={() => setInformation(original)}
          >
            Chỉnh sửa
          </Styles.StyleButton>
        </>
      ) : null),
      width: 210,
    },
  ], [rows]);

  return (
    <>
      {(waiting) ? <Waiting fullscreen /> : null}
      {showSearch ? (
        <Search
          advanced={advanced}
          search={search}
          data={rows}
          onUpdate={(result, msg) => {
            updateCertificates(result, msg);
          }}
          onClose={() => {
            setShowSearch(false);
            setAdvanced();
          }}
        />
      ) : null}
      {information ? (
        <Information
          data={information}
          onClose={() => setInformation()}
          onSubmit={(result, msg) => {
            updateCertificates(result, msg);
          }}
        />
      ) : null}
      {cancels ? (
        <Cancels
          data={cancels}
          onClose={() => setCancels()}
          onSubmit={(result, msg) => {
            updateCertificates(result, msg);
          }}
        />
      ) : null}
      <Styles.Header position="static">
        <Toolbar>
          <Box display="flex" width={1}>
            <Box display="flex" flexGrow={1}>
              <Box ml={1} width={120}>
                <AutoComplete
                  fullWidth
                  value={year}
                  options={range(new Date().getFullYear() - 30, new Date().getFullYear() + 1)}
                  getOptionLabel={(val) => `${val}`}
                  onChange={(e, value) => setYear(value)}
                  inputProps={{
                    label: 'Chọn năm',
                    required: true,
                  }}
                />
              </Box>
              <Box ml={1} width={250}>
                <TextField
                  fullWidth
                  label="Số hiệu phôi bằng"
                  value={search}
                  onChange={(e) => setSearch(e.target.value)}
                  InputProps={{
                    endAdornment: <Styles.SearchIcon color="primary" onClick={() => setShowSearch(true)}>search</Styles.SearchIcon>,
                  }}
                  inputProps={{
                    onKeyPress: (event) => {
                      if (event.key === 'Enter') {
                        setShowSearch(true);
                      }
                    },
                  }}
                />
              </Box>
              <Box ml={1} mt={3} width={200}>
                <Styles.StyleButton
                  variant="outlined"
                  onClick={() => {
                    setShowSearch(true);
                    setAdvanced({ year });
                    setSearch('');
                  }}
                >
                  Tra cứu nâng cao
                </Styles.StyleButton>
              </Box>
            </Box>
            <Box
              mt={3}
              display="flex"
              alignSelf="center"
            >
              <Styles.StyleButton
                variant="outlined"
                startIcon={<Icon>add</Icon>}
                onClick={() => setInformation({
                  year,
                  createDate: new Date().getTime(),
                })}
              >
                Thêm phôi bằng
              </Styles.StyleButton>
            </Box>
          </Box>
        </Toolbar>
      </Styles.Header>
      <Box m={2}>
        <Table
          solidBorder
          disableGlobalFilter
          usePagination
          autoResetExpanded={false}
          columns={columns}
          data={rows}
          getRowId={(row) => row.id}
          height={window.innerHeight - 270}
          initialState={{
            groupBy: ['unitName'],
          }}
        />
      </Box>
    </>
  );
}

export default Certificates;
