import React, {
  useState,
  useMemo,
} from 'react';

import {
  Waiting,
  Confirmation,
  AutoComplete,
} from '@xbotvn/react-ui/components';
import {
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  Grid,
  Button,
  TextField,
  Icon,
  Chip,
} from '@xbotvn/react-ui/core';
import {
  intersection,
  clone,
  uniqBy,
} from '@xbotvn/utils/collection';
import PropTypes from 'prop-types';
import Dropzone from 'react-dropzone';
import { useSelector, useDispatch } from 'react-redux';

import {
  FileTag,
} from '../../components';
import { handleUpdateProof } from '../../redux/actions/proofs';
import { handleClearRecords, handleUpdateRecord } from '../../redux/actions/records';

function Record({
  onClose,
  title,
  id,
  proof: {
    id: proofID,
    files,
    allocFiles: proofsAllocFiles,
    approved,
  },
}) {
  const dispatch = useDispatch();
  const {
    record,
    handling,
  } = useSelector(({ records: recordsStore }) => ({
    record: recordsStore.data?.[id] ?? {},
    handling: recordsStore.handling ?? false,
  }));

  const [file, setFile] = useState(record?.file ?? '');
  const [allocFiles, setAllocFiles] = useState(intersection(
    record?.allocFiles ?? [],
    proofsAllocFiles,
  ));
  const [note, setNote] = useState(record?.note ?? {});
  const [upload, setUpload] = useState([]);
  const [remove, setRemove] = useState(false);

  const filesRendered = useMemo(() => {
    const results = (note?.files ?? []).map((name) => (
      <FileTag
        key={name}
        name={name}
        path={['proofs', proofID, 'records', id].join('/')}
        onRemove={(event) => {
          setNote((prevNote) => ({
            ...prevNote,
            files: (prevNote?.files ?? []).filter((f) => f !== name),
          }));
          event.stopPropagation();
        }}
      />
    ));
    upload.forEach(({ name }) => {
      results.push((
        <Chip
          key={name}
          label={name}
          style={{ marginLeft: 5 }}
          minimal
          onDelete={() => setUpload((prevUpload) => prevUpload.filter((f) => f.name !== name))}
        />
      ));
    });
    return results;
  }, [record, upload]);

  return (
    <Dialog
      onClose={onClose}
      open
      maxWidth="sm"
      fullWidth
    >
      <DialogTitle
        title={title}
        onClose={onClose}
      />
      <DialogContent dividers>
        {handling ? <Waiting fullscreen /> : null}
        {remove ? (
          <Confirmation
            title="Xóa đối tượng"
            onClose={() => setRemove(false)}
            primaryAction={() => dispatch(handleClearRecords([id], onClose))}
            description="Sau khi xoá thì sẽ không thể khôi phục lại được."
          />
        ) : null}
        <Grid container spacing={2}>
          <Grid item xs={12}>
            <AutoComplete
              value={file}
              fullWidth
              onChange={(e, value) => setFile(value)}
              options={(files?.files ?? []).map(({ name }) => name)}
              inputProps={{
                label: 'Minh chứng',
              }}
            />
          </Grid>
          <Grid item xs={12}>
            <AutoComplete
              value={allocFiles}
              fullWidth
              multiple
              onChange={(e, value) => setAllocFiles(value)}
              options={proofsAllocFiles}
              inputProps={{
                label: 'Đã nhận bằng',
              }}
            />
          </Grid>
          <Grid item xs={12}>
            <TextField
              label="Ghi chú"
              fullWidth
              multiline
              variant="outlined"
              value={note?.content ?? ''}
              onChange={(e) => setNote((prevNote) => ({
                ...prevNote,
                content: e.target.value,
              }))}
            />
            {filesRendered}
          </Grid>
        </Grid>
      </DialogContent>
      <DialogActions>
        <Dropzone
          multiple
          onDrop={(selected) => {
            if (selected.length === 0) return;
            setUpload(uniqBy([...upload, ...selected], 'path'));
          }}
        >
          {({ getRootProps, getInputProps }) => (
            <div {...getRootProps()}>
              <input {...getInputProps()} />
              <Button
                color="secondary"
                startIcon={<Icon>cloud_upload</Icon>}
                onClick={() => { }}
              >
                Tải ghi chú
              </Button>
            </div>
          )}
        </Dropzone>
        <Button
          color="secondary"
          onClick={() => setRemove(true)}
        >
          Xóa đối tượng
        </Button>
        <Button
          color="primary"
          startIcon={<Icon>save</Icon>}
          onClick={() => {
            let received = clone(approved?.received ?? 0);
            if (allocFiles.length && !(record?.allocFiles ?? []).length) {
              received += 1;
            } else if (received) {
              received -= 1;
            }
            if (approved?.received !== received) {
              dispatch(handleUpdateProof([{
                id: proofID,
                data: {
                  approved: {
                    received,
                  },
                },
              }]));
            }
            dispatch(handleUpdateRecord(
              id,
              {
                file,
                allocFiles,
                note,
              },
              upload,
              onClose,
            ));
          }}
        >
          Cập nhật
        </Button>
      </DialogActions>
    </Dialog>
  );
}

Record.propTypes = {
  onClose: PropTypes.func.isRequired,
  title: PropTypes.string.isRequired,
  id: PropTypes.string.isRequired,
  proof: PropTypes.shape({
    id: PropTypes.string.isRequired,
    source: PropTypes.string.isRequired,
    files: PropTypes.arrayOf(PropTypes.shape({
      name: PropTypes.string,
    })),
    approved: PropTypes.object.isRequired,
    allocFiles: PropTypes.arrayOf(PropTypes.string),
  }).isRequired,
};

export default Record;
