export const USER = {
  handlers: {
    login: 'USER_LOGIN_HANDLER',
    update: 'USER_UPDATE_HANDLER',
    unit: 'USER_UPDATE_UNIT_HANDLERS',
    logs: 'USER_LOGS_HANDLER',
  },
  update: 'USER_UPDATE',
  unit: 'USER_UPDATE_GROUPS',
};

export const CATALOGS = {
  handlers: {
    system: 'CATALOGS_QUERY_SYSTEM_HANDLER',
    app: 'CATALOGS_QUERY_APP_HANDLER',
    doctype: 'CATALOGS_DOCTYPE_HANDLER',
  },
  system: 'CATALOGS_SYSTEM',
  app: 'CATALOGS_APP',
};

export const RECORDS = {
  handlers: {
    get: 'RECORDS_HANDLER_GET',
    import: 'RECORDS_HANDLER_IMPORT',
    update: 'RECORDS_HANDLER_UPDATE',
    clear: 'RECORDS_HANDLER_CLEAR',
    record: 'RECORD_HANDLE_UPDATE',
    updateProperties: 'RECORD_HANDLE_UPDATE_PROPERTIES',
  },
  update: 'RECORDS_UPDATE',
};

export const PROOFS = {
  handlers: {
    getById: 'PROOFS_GET_BY_ID_HANDLER',
    get: 'PROOFS_GET_HANDLER',
    update: 'PROOFS_UPDATE_HANDLER',
    upload: 'PROOFS_UPLOAD_HANDLER',
    removeFile: 'PROOFS_REMOVE_FILE_HANDLER',
  },
  reload: 'PROOFS_RELOAD',
  update: 'PROOFS_UPDATE',
  delete: 'PROOFS_DELETE',
};

export const SETTINGS = {
  update: 'UPDATE_SETTINGS',
};

export const SEARCH = {
  handlers: {
    search: 'SEARCH_HANDLER',
    clear: 'SEARCH_CLEAR_HANDLER',
    searchByList: 'SEARCH_BY_LIST_HANDLER',
  },
  fetch: 'SEARCH_FETCH',
  done: 'SEARCH_DONE',
};

export const REALLOCS = {
  handlers: {
    get: 'REALLOCS_HANDLER_GET',
    insert: 'REALLOCS_HANDLER_INSERT',
  },
  update: 'REALLOCS_UPDATE',
};

export const SIGN_OUT = 'SIGN_OUT';
