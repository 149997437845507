import React, {
  useState,
  useMemo,
} from 'react';

import {
  Waiting,
  Notification,
  AutoComplete,
  Table,
  Confirmation,
} from '@xbotvn/react-ui/components';
import {
  Button,
  IconButton,
  Icon,
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  Grid,
  Paper,
  Box,
  Divider,
  Typography,
  Chip,
  Checkbox,
  FormControlLabel,
} from '@xbotvn/react-ui/core';
import {
  unset,
  cloneDeep,
  intersection,
  orderBy,
  getUniqueKey,
} from '@xbotvn/utils/collection';
import { format } from '@xbotvn/utils/date';
import Dropzone from 'react-dropzone';
import { useSelector, useDispatch } from 'react-redux';
import ReactToolTip from 'react-tooltip';
import * as XLSX from 'xlsx';

import {
  FileTag,
  UsersLogs,
} from '../../components';
import { PROPSSTYLES } from '../../config';
import { stringToValue } from '../../libs/utils';
import { handleUpdateProof } from '../../redux/actions/proofs';
import {
  handleClearRecords,
  handleImportRecords,
  handleUpdateRecords,
  handleUpdatePropertiesRecords,
} from '../../redux/actions/records';
import AutoApproval from './AutoApproval';
import AutoConfig from './AutoConfig';
import { getRecords, formatValue } from './helpers';
import Printer from './Printer';
import Realloc from './Realloc';
import Record from './Record';
import * as Styles from './styles';

function Records() {
  const dispatch = useDispatch();
  const {
    records: initRecords,
    docType: {
      properties,
      importRow,
      importColumn,
    },
    proof,
    isStaff,
    email,
    groups,
    handling,
    isXbotStaff,
  } = useSelector(({
    records: recordsStore,
    catalogs,
    proofs,
    user,
  }) => ({
    records: recordsStore?.data ?? {},
    docType: catalogs?.app?.docTypes?.data?.[proofs.docType] ?? {},
    proof: {
      id: recordsStore?.proof ?? '',
      files: proofs?.data?.[recordsStore?.proof ?? ''] ?? [],
      approved: proofs?.data?.[recordsStore?.proof ?? '']?.approved ?? {},
      year: proofs?.year ?? '',
      name: proofs?.data?.[recordsStore?.proof ?? '']?.name ?? '',
      properties: proofs?.data?.[recordsStore?.proof ?? '']?.properties ?? [],
      allocFiles: proofs?.data?.[recordsStore?.proof ?? '']?.allocFiles ?? [],
      groups: proofs?.data?.[recordsStore?.proof ?? '']?.groups ?? [],
    },
    isStaff: (user?.unit?.staffs ?? []).includes(user.email) || user?.account?.xbot?.support,
    isXbotStaff: user?.account?.xbot?.support ?? false,
    email: user.email,
    groups: user?.unit?.groups ?? {},
    handling: recordsStore?.handling ?? false,
  }));

  const [importedFiles, setImportedFiles] = useState({});
  const [records, setRecords] = useState({});
  const [showRecord, setShowRecord] = useState();
  const [showOptions, setShowOptions] = useState();
  const [fixed, setFixed] = useState(['docNo', 'file']);
  const [configAuto, setConfigAuto] = useState();
  const [openApprovalModal, setOpenApprovalModal] = useState(false);
  const [openFormatDataModal, setOpenFormatDataModal] = useState(false);
  const [selectedProp, setSelectedProp] = useState({});
  const [showPrinter, setShowPrinter] = useState(false);
  const [showLogs, setShowLogs] = useState();
  const [removeAll, setRemoveAll] = useState();
  const [confirmImport, setConfirmImport] = useState();
  const [showRealloc, setShowRealloc] = useState();

  const allowEdit = useMemo(() => {
    if (isStaff) return true;
    return proof.groups.filter((g) => g.permissions.includes('import')).some(({ id }) => (groups?.[id]?.staffs ?? []).includes(email));
  }, [proof, isStaff, groups]);

  const isImportView = useMemo(() => Object.keys(importedFiles).length, [importedFiles]);

  const columns = useMemo(() => {
    const leftCols = [{
      Header: 'STT',
      accessor: 'stt',
      width: 70,
      sticky: 'left',
    }];
    if (isImportView) {
      leftCols.push({
        Header: 'Lỗi',
        accessor: 'errors',
        width: 200,
        sticky: 'left',
      });
    }

    const propToCols = (dynamic = false) => orderBy(
      (properties || []).filter(
        ({ code }) => dynamic !== fixed.includes(code),
      ),
      ['order'],
      ['asc'],
    ).map(({
      code,
      name,
      required,
      type,
    }) => {
      const col = {
        accessor: code,
        Header: required ? `${name}*` : name,
        type: 'text',
        width: PROPSSTYLES?.[code]?.width ?? 150,
        sticky: PROPSSTYLES?.[code]?.sticky || (dynamic ? undefined : 'right'),
      };
      switch (type) {
        case 'bool':
          col.type = 'checkbox';
          break;
        case 'date':
          col.type = 'date';
          col.filter = 'dateOnly';
          break;
        default:
      }
      return col;
    });

    const dynamicCols = propToCols(true);
    const rightCols = propToCols();

    const fileCol = {
      Header: 'Minh chứng',
      accessor: 'file',
      sticky: fixed.includes('file') ? 'right' : undefined,
      // eslint-disable-next-line react/prop-types
      Cell: ({ cell: { value } }) => {
        if (!value) return '';
        return (
          <FileTag
            name={value}
            key={value}
          />
        );
      },
      width: 180,
    };
    const allocationCol = {
      Header: 'Đã nhận bằng',
      accessor: 'allocFiles',
      sticky: fixed.includes('allocFiles') ? 'right' : undefined,
      // eslint-disable-next-line react/prop-types
      Cell: ({ cell: { value } }) => {
        if (!value) return '';
        return intersection(value, proof?.allocFiles ?? []).map(
          (tag) => (
            <FileTag
              name={tag}
              key={tag}
              source={proof.source}
            />
          ),
        );
      },
      width: 200,
    };
    const noteCol = {
      Header: 'Ghi chú',
      accessor: 'note',
      sticky: fixed.includes('note') ? 'right' : undefined,
      // eslint-disable-next-line react/prop-types
      Cell: ({ cell: { value }, row: { original: { id } } }) => {
        if (!value) return '';
        return (
          <Box display="flex" flexDirection="column">
            <Box display="flex">
              {
                // eslint-disable-next-line react/prop-types
                (value?.files ?? []).map((name) => (
                  <FileTag
                    name={name}
                    key={name}
                    path={`proofs/${proof.id}/records/${id}`}
                  />
                ))
              }
            </Box>
            <Box>
              {
                // eslint-disable-next-line react/prop-types
                value?.content ?? ''
              }
            </Box>
          </Box>
        );
      },
      width: 200,
    };
    if (fixed.includes('file')) {
      rightCols.push(fileCol);
    } else {
      dynamicCols.push(fileCol);
    }
    if (fixed.includes('allocFiles')) {
      rightCols.push(allocationCol);
    } else {
      dynamicCols.push(allocationCol);
    }
    if (fixed.includes('note')) {
      rightCols.push(noteCol);
    } else {
      dynamicCols.push(noteCol);
    }

    if (allowEdit && !isImportView) {
      rightCols.push({
        accessor: 'realloc',
        Header: ' ',
        sticky: 'right',
        disableFilters: true,
        width: 140,
        // eslint-disable-next-line react/prop-types
        Cell: ({ cell: { value } }) => (
          <>
            <div>
              <IconButton data-tip data-for={value} onClick={() => setShowRealloc(value)}>
                <Icon>recent_actors</Icon>
              </IconButton>
              <ReactToolTip id={value} place="left">
                <Typography>Cấp bản sao</Typography>
              </ReactToolTip>
            </div>
            <IconButton onClick={() => setShowRecord(value)}>
              <Icon>edit</Icon>
            </IconButton>
          </>
        ),
      });
    }

    return [...leftCols, ...dynamicCols, ...rightCols];
  }, [
    properties,
    importedFiles,
    fixed,
    proof,
    isImportView,
    allowEdit,
  ]);

  const rows = useMemo(() => {
    const flat = orderBy(
      Object.entries(Object.keys(records).length ? records : initRecords).map(
        ([id, record]) => ({
          id,
          realloc: id,
          ...record,
        }),
      ),
      ['id'],
      ['asc'],
    );
    return flat.map((values, index) => ({
      ...values,
      stt: index + 1,
    }));
  }, [records, initRecords]);

  const optionsModal = useMemo(() => {
    if (!showOptions) return null;
    const { importedRecords, criteria } = showOptions;
    return (
      <Dialog
        open
        fullWidth
        maxWidth="sm"
        onClose={() => setShowOptions()}
      >
        <DialogTitle
          onClose={() => setShowOptions()}
          title="Nhập dữ liệu"
        />
        <DialogContent dividers>
          {properties.map(({ code, name }) => (
            <FormControlLabel
              key={code}
              label={name}
              control={(
                <Checkbox
                  inline
                  checked={criteria.includes(code)}
                  disabled={!Object.keys(initRecords).length}
                  onChange={() => {
                    setShowOptions((prevOptions) => {
                      let newCriteria = prevOptions?.criteria ?? [];
                      newCriteria = newCriteria.includes(code)
                        ? newCriteria.filter((value) => value !== code)
                        : [...newCriteria, code];
                      return {
                        importedRecords,
                        criteria: newCriteria,
                      };
                    });
                  }}
                />
              )}
            />
          ))}
        </DialogContent>
        <DialogActions>
          <Button
            color="secondary"
            disabled={!Object.keys(initRecords).length}
            onClick={() => {
              setRecords(Object.assign(importedRecords, initRecords));
              setShowOptions();
            }}
          >
            Thêm tiếp
          </Button>
          <Button
            color="primary"
            disabled={!criteria.length}
            onClick={() => {
              const listKey = properties.map((prop) => prop?.code ?? '', properties);
              const cloneData = cloneDeep(initRecords);
              Object.entries(cloneData).forEach(([id, init]) => {
                const newData = Object.values(importedRecords).find(
                  (rec) => (criteria || []).every((crit) => rec[crit] === init[crit]),
                );
                if (newData) {
                  cloneData[id].errors = newData?.errors ?? '';
                  listKey.forEach((key) => {
                    cloneData[id][key] = newData?.[key] ?? '';
                  });
                }
              });
              setRecords(cloneData);
              setShowOptions();
            }}
          >
            Tìm và cập nhật
          </Button>
        </DialogActions>
      </Dialog>
    );
  }, [showOptions, records, initRecords]);

  const recordModal = useMemo(() => {
    if (showRecord) {
      const { fullName } = initRecords?.[showRecord] ?? {};
      return (
        <Record
          onClose={() => setShowRecord()}
          title={fullName || showRecord}
          id={showRecord}
          proof={proof}
        />
      );
    }
    return null;
  }, [showRecord]);

  const configAutoModal = useMemo(() => {
    if (configAuto) {
      let targets = [{ code: 'allocFiles', name: 'Đã nhận bằng', isProperty: false }];
      if (isStaff) {
        targets = [
          ...targets,
          { code: 'docNo', name: 'Số bằng', isProperty: true },
          { code: 'inputNo', name: 'Số vào sổ', isProperty: true },
          { code: 'file', name: 'Minh chứng', isProperty: false },
        ];
      }
      return (
        <AutoConfig
          onClose={() => setConfigAuto()}
          perform={(data) => {
            dispatch(handleUpdateRecords(data));
            setConfigAuto();
          }}
          targets={targets}
          rows={rows}
          properties={properties}
        />
      );
    }
    return null;
  }, [configAuto]);

  const approvalModal = useMemo(() => {
    if (openApprovalModal) {
      return (
        <AutoApproval
          onClose={() => setOpenApprovalModal(false)}
          properties={properties}
          rows={rows}
          perform={(data) => {
            const newGroups = proof.groups.map(
              (group) => {
                const newPermissions = group;
                newPermissions.permissions = [];
                return newPermissions;
              },
            );
            dispatch(handleUpdateProof([{
              id: proof.id,
              data: {
                name: proof.name,
                groups: newGroups,
                approved: {
                  received: rows.filter(({ allocFiles }) => (allocFiles || []).length).length,
                  passed: data.length,
                  total: rows.length,
                },
              },
            }], () => setOpenApprovalModal(false)));
          }}
        />
      );
    }
    return null;
  }, [openApprovalModal]);

  const ReallocModal = useMemo(() => {
    if (!showRealloc) return null;
    return <Realloc id={showRealloc} onClose={() => setShowRealloc()} />;
  }, [showRealloc]);

  const fixSettings = useMemo(() => [
    { value: 'note', text: 'Ghi chú' },
    { value: 'allocFiles', text: 'Đã nhận bằng' },
    { value: 'file', text: 'Minh chứng' },
    { value: 'inputNo', text: 'Số vào sổ' },
    { value: 'docNo', text: 'Số bằng' },
    { value: 'rate', text: 'Xếp loại' },
  ].map(({ value, text }) => (
    <Chip
      size="small"
      key={value}
      label={text}
      color={fixed.includes(value) ? 'primary' : 'secondary'}
      clickable
      onClick={() => setFixed((prevFixed) => {
        if (prevFixed.includes(value)) return prevFixed.filter((f) => f !== value);
        return [...prevFixed, value];
      })}
    />
  )), [fixed]);

  const formatDataModal = useMemo(() => {
    if (!openFormatDataModal) return null;
    return (
      <Dialog
        open
        fullWidth
        maxWidth="sm"
        onClose={() => setOpenFormatDataModal(false)}
      >
        <DialogTitle
          onClose={() => setOpenFormatDataModal(false)}
          title="Định dạng dữ liệu"
        />
        <DialogContent dividers>
          <Grid container spacing={2}>
            <Grid item xs={12}>
              <AutoComplete
                value={selectedProp?.code ?? ''}
                fullWidth
                onChange={(e, val) => setSelectedProp((prev) => ({
                  ...prev,
                  code: val,
                }))}
                options={properties.map(({ code }) => code)}
                getOptionLabel={(opt) => properties.find(({ code }) => code === opt)?.name ?? opt}
                inputProps={{
                  label: 'Thuộc tính',
                  required: true,
                }}
              />
            </Grid>
            <Grid item xs={12}>
              <AutoComplete
                value={selectedProp?.typeTo ?? ''}
                fullWidth
                onChange={(e, val) => setSelectedProp((prev) => ({
                  ...prev,
                  typeTo: val,
                }))}
                options={['date', 'string']}
                getOptionLabel={(opt) => ({ date: 'Ngày/Tháng/Năm', string: 'Chuỗi' })[opt] ?? opt}
                inputProps={{
                  label: 'Kiểu dữ liệu',
                  required: true,
                }}
              />
            </Grid>
          </Grid>
        </DialogContent>
        <DialogActions>
          <Button
            color="primary"
            onClick={() => {
              if (!selectedProp?.code) {
                Notification.warn('Chưa chọn thuộc tính.');
                return;
              }
              if (!selectedProp?.typeTo) {
                Notification.warn('Chưa chọn kiểu dữ liệu chuyển đổi.');
                return;
              }
              const { newRecords, errors } = formatValue({
                records: initRecords,
                format: selectedProp,
              });
              if (errors.length) {
                const getNames = errors.map(({ fullName }) => fullName);
                Notification.warn(`Lỗi định dạng: ${getNames.join('; ')}.`);
              } else {
                dispatch(handleUpdatePropertiesRecords(newRecords));
              }
              setOpenFormatDataModal(false);
            }}
          >
            Thực hiện
          </Button>
        </DialogActions>
      </Dialog>
    );
  }, [openFormatDataModal, selectedProp]);

  return (
    <Styles.GroupRecords>
      {handling ? <Waiting fullscreen /> : null}
      {showPrinter ? <Printer onClose={() => setShowPrinter(false)} /> : null}
      {showLogs ? <UsersLogs onClose={() => setShowLogs()} type="proofs" id={proof.id} /> : null}
      {removeAll ? (
        <Confirmation
          onClose={() => setRemoveAll()}
          title="Xóa toàn bộ"
          description="Những dữ liệu đã số hoá dưới đây sẽ không còn tra cứu được nữa. Sau khi xoá thì sẽ không thể khôi phục lại được."
          severity="warning"
          primaryAction={() => {
            setRemoveAll();
            dispatch(handleClearRecords([], () => {
              setImportedFiles({});
              setRecords({});
            }));
          }}
        />
      ) : null}
      {confirmImport ? (
        <Confirmation
          onClose={() => setConfirmImport()}
          title="Cập nhật"
          description="Những dữ liệu có lỗi sẽ không được cập nhật."
          severity="warning"
          primaryAction={() => {
            setConfirmImport();
            dispatch(handleImportRecords(
              records,
              () => {
                setImportedFiles({});
                setRecords({});
              },
            ));
          }}
        />
      ) : null}
      <Grid container spacing={1}>
        <Grid item xs={12}>
          <Paper elevation={2}>
            <Box
              display="flex"
              height={50}
              p={1}
              alignItems="center"
            >
              <Box flexGrow={1} display="flex">
                <Typography variant="body2" style={{ fontWeight: 'bold' }}>{`Đã lưu: ${Object.keys(initRecords).length}`}</Typography>
                <Divider orientation="vertical" style={{ margin: 10 }} />
                {Object.entries(importedFiles).map(([id, { name, status }]) => (
                  <Chip
                    size="small"
                    color={(status === 'done') ? 'primary' : 'secondary'}
                    key={id}
                    style={{ marginRight: 5 }}
                    label={(
                      <Typography>
                        {name}
                        <sup>
                          <strong>
                            {rows.filter(({ importFrom }) => importFrom === id).length}
                          </strong>
                        </sup>
                      </Typography>
                    )}
                    icon={<Icon>description</Icon>}
                    onDelete={() => setRecords((prevRecords) => {
                      const newRecords = {};
                      Object.entries(prevRecords).forEach(([recID, record]) => {
                        const { importFrom, updated } = record;
                        if (importFrom !== id) {
                          newRecords[recID] = record;
                        } else if (updated === true) {
                          newRecords[recID] = initRecords?.[recID] ?? {};
                        }
                      });
                      setImportedFiles((prevImported) => {
                        const cloned = cloneDeep(prevImported);
                        unset(cloned, id);
                        return cloned;
                      });
                      setRecords(newRecords);
                    })}
                  />
                ))}
              </Box>
              {isXbotStaff ? (
                <Button
                  size="small"
                  style={{ marginLeft: 5 }}
                  startIcon={<Icon>transform</Icon>}
                  onClick={() => setOpenFormatDataModal(true)}
                >
                  Chuyển đổi dữ liệu
                </Button>
              ) : null}
              {
                // eslint-disable-next-line max-len
                (Object.keys(initRecords).length && !Object.keys(importedFiles).length && allowEdit) ? (
                  <Button
                    style={{ marginLeft: 5 }}
                    size="small"
                    color="primary"
                    startIcon={<Icon>done</Icon>}
                    onClick={() => setOpenApprovalModal(true)}
                  >
                    Duyệt
                  </Button>
                ) : null
              }
              {
                // eslint-disable-next-line max-len
                (Object.keys(initRecords).length && !Object.keys(importedFiles).length && allowEdit) ? (
                  <Styles.RemoveBtn
                    size="small"
                    startIcon={<Icon>delete</Icon>}
                    onClick={() => setRemoveAll(true)}
                  >
                    Xoá toàn bộ
                  </Styles.RemoveBtn>
                ) : null
              }
              {Object.keys(importedFiles).length ? (
                <Button
                  style={{ marginLeft: 5 }}
                  size="small"
                  color="primary"
                  onClick={() => setConfirmImport(true)}
                >
                  Cập nhật
                </Button>
              )
                : (
                  <Button
                    style={{ marginLeft: 5 }}
                    size="small"
                    color="primary"
                    icon={<Icon>format_list_numbered</Icon>}
                    onClick={() => setConfigAuto(true)}
                  >
                    Tự động
                  </Button>
                )}
              {allowEdit ? (
                <Dropzone
                  multiple={false}
                  onDrop={(files) => {
                    if (files.length === 0) return;
                    const file = files[0];
                    const id = getUniqueKey(Object.keys(importedFiles));
                    setImportedFiles((prevImported) => ({
                      ...prevImported,
                      [id]: {
                        name: file.name,
                        status: 'processing',
                      },
                    }));
                    const reader = new FileReader();
                    reader.onabort = () => {
                      setImportedFiles((prevImported) => ({
                        ...prevImported,
                        [id]: {
                          ...prevImported[id],
                          status: 'aborted',
                        },
                      }));
                    };
                    const errorHandler = ({ message }) => {
                      Notification.warn(`${file.name}: ${message}`);
                      setImportedFiles((prevImported) => ({
                        ...prevImported,
                        [id]: {
                          ...prevImported[id],
                          status: message,
                        },
                      }));
                    };
                    reader.onerror = errorHandler;

                    reader.onload = () => {
                      const importedRecords = getRecords(
                        id,
                        reader.result,
                        properties,
                        importRow,
                        importColumn,
                      );
                      setImportedFiles((prevImported) => ({
                        ...prevImported,
                        [id]: {
                          ...prevImported[id],
                          status: 'done',
                        },
                      }));
                      if (Object.keys(initRecords).length) {
                        setShowOptions({
                          importedRecords,
                          criteria: [],
                        });
                      } else setRecords(importedRecords);
                    };

                    reader.readAsBinaryString(file);
                  }}
                >
                  {({ getRootProps, getInputProps }) => (
                    <div {...getRootProps()}>
                      <input {...getInputProps()} />
                      <Button
                        style={{ marginLeft: 5 }}
                        startIcon={<Icon>upload</Icon>}
                        size="small"
                        color="primary"
                        onClick={() => { }}
                      >
                        Nhập từ Excel
                      </Button>
                    </div>
                  )}
                </Dropzone>
              ) : null}
              {rows.length ? (
                <>
                  <Button
                    style={{ marginLeft: 5 }}
                    startIcon={<Icon>download</Icon>}
                    size="small"
                    onClick={() => {
                      const sortedProperties = orderBy(properties, ['order'], ['asc']);
                      const results = [[
                        'Năm',
                        ...sortedProperties.map(({ name }) => name),
                        'Minh chứng',
                        'Ghi chú',
                      ]];
                      rows.forEach((obj) => {
                        const record = [
                          proof.year,
                          ...sortedProperties.map(({ code, type }) => {
                            const value = obj?.[code] ?? '';
                            if (type === 'date') {
                              return format(parseFloat(value));
                            }
                            return stringToValue(value, type);
                          }),
                          obj?.file ?? '',
                          obj?.note?.content ?? '',
                        ];
                        results.push(record);
                      });
                      const wb = XLSX.utils.book_new();
                      XLSX.utils.book_append_sheet(
                        wb,
                        XLSX.utils.aoa_to_sheet(results),
                        'Danh sách',
                      );
                      XLSX.writeFile(wb, `Danh sách ${proof.name}.xlsx`);
                    }}
                  >
                    Xuất file danh sách
                  </Button>
                  {allowEdit ? (
                    <Button
                      style={{ marginLeft: 5 }}
                      size="small"
                      startIcon={<Icon>print</Icon>}
                      onClick={() => setShowPrinter(true)}
                    >
                      In bằng
                    </Button>
                  ) : null}
                </>
              ) : null}
              <IconButton size="small" onClick={() => setShowLogs(true)}>
                <Icon>history</Icon>
              </IconButton>
            </Box>
          </Paper>
        </Grid>
      </Grid>
      {optionsModal}
      {recordModal}
      {configAutoModal}
      {approvalModal}
      {formatDataModal}
      {ReallocModal}
      <Box
        mt={1}
        position="absolute"
        right={15}
        display="flex"
      >
        {fixSettings}
      </Box>
      <Box mt={5} mb={8}>
        <Table
          columns={columns}
          data={rows}
          getRowId={(row) => row.id}
          disableGlobalFilter
          disableGroupBy
          rowHeight={42}
          height={window.innerHeight - 350}
        />
      </Box>
    </Styles.GroupRecords>
  );
}

export default Records;
