import { words, camelCase, capitalize } from '@xbotvn/utils/string';
import filenamify from 'filenamify';

export const extractFileName = (fileName) => {
  const ext = fileName.split('.').pop();
  const name = fileName.replace(ext, '');
  return {
    name: camelCase(filenamify(name, { replacement: '_' })),
    ext,
  };
};

export const convertName = (name) => words(name).map((word) => capitalize(word)).join(' ');

export const stringToValue = (str, type) => {
  try {
    switch (type) {
      case 'date':
      case 'number':
        if (str) return parseFloat(str);
        return null;
      case 'bool':
        return str === 'true';
      default:
        return str;
    }
  } catch {
    return str;
  }
};

export const valueToString = (value, type) => {
  switch (type) {
    case 'bool':
      return value ? 'true' : 'false';
    default:
      return `${value}`;
  }
};

export const flattenProperties = (properties, propertiesSettings = []) => {
  const result = {};
  propertiesSettings.forEach(({ code, type }) => {
    const value = properties.find(({ code: pcode }) => pcode === code)?.value;
    if (value !== undefined) {
      result[code] = stringToValue(value, type);
    }
  });
  return result;
};

export const arrToCollection = (records = [], propertiesSettings = []) => {
  const result = {};
  records.forEach(({ id, properties, ...rest }) => {
    result[id] = {
      ...rest,
      ...flattenProperties(properties || [], propertiesSettings),
    };
  });
  return result;
};
